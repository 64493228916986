import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { StandardPageLayout } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

// tslint:disable:max-line-length

export class EuropeanPersonalDataPageInternal extends React.PureComponent<{}, {}> {

    public render() {
        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                headerFeature={
                    <div>
                    <h1 className='legal-header-title'>
                        <WrappedMessage message={messages.pageTitle}/></h1>
                    <p className='legal-header-sub-title'>
                        <WrappedMessage message={messages.pageSubTitle}/></p>
                    </div>
                }
            >
                <div className='legal-page'>
                    <div className='legal-content'>
<p>If you are located in the European Economic Area ( “<strong>EEA</strong>”), the European Union’s General Data Protection Regulation (“<strong>GDPR</strong>”) gives you certain rights respecting your Personal Data (as defined in the GDPR) and imposes certain obligations on organizations like LabXchange (“<strong>LXC</strong>”) that process this data, if and to the extent their processing is within the scope of the GDPR.  This Disclosure describes how you can exercise your rights under the GDPR if you are located in the EEA and how we comply with the GDPR, insofar as our processing of your Personal Data is within the scope of the GDPR.</p>

<p>The information in this Disclosure supplements the information contained in our <NavLink to={ROUTES.General.PRIVACY_POLICY}>Privacy Notice</NavLink> and <NavLink to={ROUTES.General.TOS}>Terms of Service</NavLink>, with respect to Personal Data processing that is subject to the GDPR.  Capitalized terms used and not defined in this document have the meanings provided or referred to in our Privacy Notice.</p>

<h2>Legal Basis of Processing</h2>

<p>We process your Personal Data in the ways, and for the purposes, that are described in our Privacy Notice with respect to “Personally Identifiable Information.”   We process your Personal Data to enable you to register for our Services, if you request to be a registered user, and to provide the Services in accordance with our contract with you (i.e., our Terms of Service); on the basis of our legitimate interests (as described below); or on the basis of you consent, when and if we explicitly seek your consent.  In addition, in appropriate circumstances we may process your Personal Data to protect your vital interests or those of another person, or to comply with our legal obligations (for example, responding to a subpoena).</p>

<p>We rely on various legitimate interests recognized by the GDPR as a basis for processing your Personal Data.  These include:</p>
<ul>
<li>Providing, improving and customizing the educational Resources and Services described in our Terms of Service;</li>
<li>Communicating with you regarding the Services;</li>
<li>Administering our operations;</li>
<li>Furthering research;</li>
<li>Understanding how our Services are being used;</li>
<li>Exploring ways to develop and grow our operations; </li>
<li>Informing you about events, materials, resources or offerings that may be of interest to you;</li>
<li>Cybersecurity; </li>
<li>Enhancing protection against fraud, spam, harassment, intellectual property infringement, crime and security risks; and</li>
<li>Meeting our obligations and enforcing our legal rights.</li>
</ul>

<h2>Certain Rights</h2>

<p>Under the GDPR you have a variety of rights with respect to Personal Data that is subject to the GDPR.  You should understand that your exercise of some of these rights might limit or preclude your use of our Services.  Subject in each case to limitations and conditions found in the GDPR, where our processing of your Personal Data is subject to the GDPR, you may have a right to: </p>
<ul>
<li>Obtain copies of your Personal Data in our possession.</li>
<li>Have incorrect or incomplete Personal Data corrected. We encourage our participants to keep their information up to date. If any of your information changes, please update it in our system or let us know.</li>
<li>Have your Personal Data erased.</li>
<li>Restrict certain processing of your Personal Data.</li>
<li>Have your Personal Data transmitted to another data controller.</li>
<li>Object to certain processing of your Personal Data. </li>
<li>Not be subject to decisions based solely on automated processing, including profiling.  We may, for example, employ automated profiling techniques to recommend content that may interest you.  If you wish to obtain human intervention into the recommendation process, you can contact us as shown under the heading “<strong>Contact Us</strong>” in the Terms of Service. </li>
</ul>

<p>To exercise your rights, please send an email message to <a href='mailto:EEADataSubjectRequest@harvard.edu'>EEADataSubjectRequest@harvard.edu</a>.  Because we want to avoid taking action regarding your Personal Data at the direction of someone other than you, we will ask you for information verifying your identity.  We will respond to your request within a reasonable timeframe.  </p>

<p>If any of our processing of your Personal Data is within the scope of the GDPR and based solely on your consent, you also may have the right to withdraw your consent, subject to certain limitations.  In some cases, you can do this by discontinuing use of the services involving that processing or by deleting your account using the procedures described in  our Privacy Notice.  If you withdraw your consent to the use or sharing of your Personal Data for the purposes set out in our Privacy Notice or this Disclosure, you may not have access to all (or any) of our Services, and we might not be able to provide you all (or any) of the Services.  </p>

<p>Please note that, in certain cases, we may continue to process your Personal Data after you have exercised rights under the GDPR, if we have a legal basis to do so.  For example, we may retain certain data if we need to do so to comply with an independent legal obligation, if we still need the data for the lawful purposes for which we obtained the data, or if it is necessary to do so to pursue our legitimate interest in keeping our services and operations safe and secure.</p>

<p>If you have any complaints regarding our compliance with GDPR, you have the right to make a complaint with your national data protection authority (i.e., supervisory authority).</p>

<h2>Retention of Information</h2>

<p>We will retain your Personal Data for as long as is necessary for the purposes set out in our Privacy Notice unless a longer period is required under applicable law or is needed to resolve disputes or protect our legal rights or otherwise to comply with legal obligations.</p>
<p>Where we are processing Personal Data based on our legitimate interests, we generally will retain the data for a reasonable period of time based on the particular interest, taking into account the fundamental interests and the rights and freedoms of the data subjects.</p>
<p>Where we are processing Personal Data based on contract, we generally will retain the information for the duration of the contract plus some additional limited period of time that is necessary to comply with law or that represents the statute of limitations for legal claims that could arise from the contractual relationship.</p>
<p>Where we are processing Personal Data based on your consent, we generally will retain the information for the period of time necessary to carry out the processing activities to which you consented.</p>

<h2>Processing Outside of the EEA</h2>

<p>Your use of our Services will require the transfer and processing of information including your Personal Data outside of the EEA to the United States and possibly other countries whose privacy laws provide less protection than that provided by the GDPR. Additional information about processing outside the EEA is provided in our Privacy Notice.</p>

<h2>Representative</h2>

<p>We have designated Harvard Global UK as our representative in the European Union for purposes of the GDPR. Our representative may be contacted as follows:</p>
<ul>
<li>Email: <a href='mailto:GDPRrepresentative@harvard.edu'>GDPRrepresentative@harvard.edu</a></li>
<li>Address: Harvard Global UK<br/>
Attention: GDPR Representative <br/>
Vernon House <br/>
23 Sicilian Avenue <br/>
London, WC1A2QS, United Kingdom</li>
</ul>

<h2>Contact Us</h2>

<p>If you have any questions, comments, or concerns about this Disclosure or other privacy-related matters arising under the GDPR, you may contact us at <a href='mailto:privacy@labxchange.org'>privacy@labxchange.org</a>.  However, please direct any request to exercise any of your GDPR rights as a data subject to <a href='mailto:EEADataSubjectRequest@harvard.edu'>EEADataSubjectRequest@harvard.edu</a>, <strong>as described above in the “Certain Rights” section.</strong> </p>

<h2>Updating this Disclosure</h2>

<p>We may update this Disclosure from time to time without prior notice by posting revised Disclosures to this site.  You can determine when this Disclosure was last revised by checking the Last Updated date in this Disclosure.</p>

<p>*    *    *</p>

<p>The disclosures in this document and our Privacy Notice are in lieu of Harvard’s Additional EEA Privacy Disclosures, which do not apply to these LXC data processing activities.</p>
                    </div>
                </div>
            </StandardPageLayout>
        );
    }
}

export const EuropeanPersonalDataPage = connect<{}, RootState>(
    (state: RootState) => ({}),
)(EuropeanPersonalDataPageInternal);
