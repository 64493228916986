import * as React from 'react';
import { MessageDescriptor } from 'react-intl';

import { StandardPageLayout } from 'ui/components';

import { CareerHeader } from '../CareerHeader';
import { CareerExplorerLogoType } from '../../utils';

export interface CareerStandardLayoutProps {
  mainClassName?: string;
  headerClassName?: string;
  navClassName?: string;
  pageTitle?: MessageDescriptor;
  heroSection?: React.ReactNode;
  children?: React.ReactNode;
  hideSearchBar?: boolean;
  logoType?: CareerExplorerLogoType;
}

export const CareerStandardLayout: React.FC<CareerStandardLayoutProps>  = (
  {
    mainClassName, pageTitle, heroSection, headerClassName, navClassName, children,
    hideSearchBar, logoType
  }
) => {
  return (
    <StandardPageLayout
      mainClassName={mainClassName}
      pageTitle={pageTitle}
      headerComponent={
        <CareerHeader
          headerClassName={headerClassName}
          navClassName={navClassName}
          heroSection={heroSection}
          hideSearchBar={hideSearchBar}
          logoType={logoType}
        />
      }
      footerComponent={<></>}
    >
      {children}
    </StandardPageLayout>
  );
};

export default CareerStandardLayout;
