import bind from 'bind-decorator';
import * as React from 'react';

import { Spinner, Icon } from 'ui/components';
import messages from 'library/displayMessages';
import xblock from 'assets/scss/xblock-styles.scss?url';

import { WrappedMessage } from 'utils';
import { SanitizeConfigOptions, sanitizeUnsafeHTML } from 'elements/utils/sanitization';

export enum EditorStyle {
    Default,
    KeyPoints,
    Lite,
    Medium,
    Full,
    Simple,
    OnlyScripts,
    AssesmentEditor,
}

interface State {
    // Reference to the tinymce editor instance. Some things we need to do
    // require direct access. The content editor is within an iframe, and some
    // settings changes would otherwise require re-initing the editor which is
    // undesirable because we lose focus and potentially other issues because
    // this isn't set up as a controlled component.
    editor?: any;
    content: string;
    EditorComponent?: any;
}

interface Props {
    /** The label for this text input. */
    label: React.ReactNode;
    hideLabel?: boolean;
    /** Editor will take full height (height: 100%)
     */
    fullHeight?: boolean;
    /**
     * Initial height, in order of preference:
     * - height
     * - fullHeight
     * - editorStyle.height
     */
    height?: number;
    /**
     * A grey description that sits below the textarea, offering a more detailed explanation
     * of what the input does. Linked to the <textarea> via "aria-describedby"
     */
    description?: React.ReactNode;
    // No children for this component
    children?: never;
    // TODO: Make this a proper controlled component with Editor.value and props.value.
    /** Default textarea value */
    defaultValue?: string;
    placeholder?: string;
    editorStyle?: EditorStyle;
    /** Extra class name; use for writing end to end tests etc., not for styling. */
    extraClassName?: string;
    required: boolean;
    showErrors: boolean;
    onChange(data: string): void;
    options?: { [index: string]: any };
    labelJustify?: 'space-between' | 'flex-start' | 'flex-end';
    /** Other */
    hideAccessiblityLink?: boolean;
    auto_focus?: boolean;

}

export class HtmlTextBox extends React.PureComponent<Props, State> {
    public static EditorStyle = EditorStyle;

    public static defaultProps = {
        required: false,
        showErrors: false,
    };

    private readonly id: string;

    constructor(props: Props) {
        super(props);
        this.id = uniqueId();

        this.state = {
            content: this.props.defaultValue || '',
            editor: undefined,
            EditorComponent: undefined,
        };
    }

    public async componentDidMount() {
        // TinyMCE is a large library, so we can save space in the main bundle by dynamically importing here.
        const EditorComponent = (await import(/* viteChunkName: "tinymce-shim" */ './tinymce-shim')).default;
        this.setState({
            EditorComponent,
        });
    }

    public componentDidUpdate(prevProps: Props, prevState: State) {

        // Update anything that needs updating that wouldn't otherwise update.
        // Some settings only take effect when first initializing the
        // Editor, so we need to manually update using the direct editor reference.
        const editor = this.state.editor;
        if (editor !== undefined &&
            (
                prevProps.required !== this.props.required
                || Boolean(prevState.content) !== Boolean(this.state.content)
                || prevProps.showErrors !== this.props.showErrors
            )
        ) {
            this.updateErrorState(editor);
        }
    }

    private paste_preprocess(plugin: any, args: any) {
        args.content = sanitizeUnsafeHTML(
            args.content,
            SanitizeConfigOptions.UnsafeHTMLAllowedStyles,
        );
    }

    public render() {
        let styleClassName;
        let editorOptions: Record<string, any>;
        let height;
        if (this.props.fullHeight) {
            height = '100%';
        }
        if (this.props.height) {
            height = this.props.height;
        }
        const baseEditorOptions = {
            convert_urls: false,
            content_css: xblock,
            image_caption: true,
            images_dataimg_filter: () => false,
            // We want to disable setting width or height on images because then they do not remain responsive.
            image_dimensions: false, // Hides dimensions fields in image picker popup.
            object_resizing: true, // Enables image resizing in editor.
            file_picker_types: 'image',
            file_picker_callback: this.filePickerCallback,
            paste_as_text: false, // To allow paste rich text
            paste_preprocess: this.paste_preprocess, /// We sanitize the pasted rich text
            menubar: false,
            directionality: 'auto',
            block_formats: 'Paragraph=p;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;Preformatted=pre',
            target_list: false,
            auto_focus : this.props.auto_focus? `${this.id}TextArea` : '',
            ...this.props.options,
        };
        const tableDefaultStyles = {
            height: '100%',
            width: '100%',
            'border-color': '#999999',
            'background-color': '#ffffff',
        };
        const tableDefaultAttributes = {
            border: '1',
            cellspacing: '8',
            cellpadding: '0',
        };

        switch (this.props.editorStyle || EditorStyle.Default) {
            case EditorStyle.Default: {
                styleClassName = 'default-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 300,
                    plugins: ['code', 'link', 'image', 'lists advlist', 'paste', 'directionality', 'table'],
                    toolbar: 'formatselect table | bold italic superscript subscript removeformat | bullist numlist code blockquote link image | undo redo',
                    table_default_styles: tableDefaultStyles,
                    table_default_attributes: tableDefaultAttributes,
                };
                break;
            }
            case EditorStyle.Lite: {
                styleClassName = 'lite-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 95,
                    plugins: ['link', 'paste', 'directionality'],
                    toolbar: 'bold italic | link | ltr rtl | undo redo ',
                };
                break;
            }
            case EditorStyle.Medium: {
                styleClassName = 'lite-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 95,
                    plugins: ['link', 'paste', 'directionality'],
                    toolbar: 'bold italic | link | ltr rtl | superscript subscript | undo redo ',
                };
                break;
            }
            case EditorStyle.KeyPoints: {
                styleClassName = 'keypoints-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 95,
                    plugins: ['code', 'link', 'image', 'lists', 'paste'],
                    toolbar: 'bold italic | bullist link | ltr rtl | undo redo',
                };
                break;
            }
            case EditorStyle.AssesmentEditor: {
                styleClassName = 'lite-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 45,
                    plugins: ['code'],
                    toolbar: 'bold italic | superscript subscript | code',
                };
                break;
            }
            case EditorStyle.Simple: {
                styleClassName = 'lite-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 45,
                    plugins: ['link', 'lists', 'paste'],
                    toolbar: 'bold italic | superscript subscript | bullist numlist | link',
                };
                break;
            }
            case EditorStyle.OnlyScripts: {
                styleClassName = 'lite-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 45,
                    plugins: ['paste'],
                    toolbar: 'superscript subscript',
                };
                break;
            }
            case EditorStyle.Full: {
                styleClassName = 'default-mce-style';
                editorOptions = {
                    ...baseEditorOptions,
                    height: height ? height : 300,
                    menubar: 'edit insert format table tools cleanupButton',
                    menu: {
                        edit: {
                            title: 'Edit',
                            items: 'undo redo | cut copy paste pastetext | selectall searchreplace',
                        },
                        insert: {
                            title: 'Insert',
                            items: 'image link anchor inserttable | charmap emoticons hr',
                        },
                        format: {
                            title: 'Format',
                            items: 'bold italic underline strikethrough superscript subscript blockformats'
                                + ' | removeformat',
                        },
                        table: {
                            title: 'Table',
                            items: 'inserttable | cell row column | tableprops deletetable',
                        },
                        tools: {
                            title: 'Tools',
                            items: 'code | visualblocks visualchars | print',
                        },
                    },
                    plugins: [
                        'anchor',
                        'autolink',
                        'autosave',
                        'charmap',
                        'code',
                        'codesample',
                        'cleanup',
                        'directionality',
                        'emoticons',
                        'help',
                        'hr',
                        'image',
                        'legacyoutput',
                        'link',
                        'lists advlist',
                        'paste',
                        'print',
                        'searchreplace',
                        'table',
                        'textpattern',
                        'visualblocks',
                        'visualchars',
                    ],
                    browser_spellcheck: true,
                    contextmenu: true,
                    toolbar: 'formatselect bold italic superscript subscript' +
                        ' | alignleft aligncenter alignright ' +
                        ' | bullist numlist codesample blockquote link image' +
                        ' | ltr rtl' +
                        ' | undo redo' +
                        ' | cleanupButton  code',
                    codesample_content_css: '/assets/tinymce/prism.css',
                    table_default_styles: tableDefaultStyles,
                    table_default_attributes: tableDefaultAttributes,
                };
                break;
            }
        }

        editorOptions.setup = this.onEditorSetup;
        const editorId = `${this.id}TextArea`;
        const classes = ['lx-textbox', 'lx-htmltextbox', styleClassName];
        const labelJustifyStyle = this.props.labelJustify ?? 'space-between';
        if (this.props.fullHeight) { classes.push('lx-htmltextbox-full-height'); }
        if (this.props.extraClassName) { classes.push(this.props.extraClassName); }
        if (this.showErrors()) { classes.push('lx-htmltextbox-error'); }

        return (
            <div
                id={this.id}
                className={classes.join(' ')}>
                <div className={`lx-htmltextbox-label-wrapper lx-htmltextbox-label-wrapper-justify-${labelJustifyStyle}`}>
                    <label
                        htmlFor={editorId}
                        className={`lx-textbox-label lx-htmltextbox-label ${this.props.hideLabel ? 'sr-only' : ''}`}>
                        {this.props.label}{this.props.required ? '*' : null}
                    </label>
                    {!this.props.hideAccessiblityLink && <a href='https://www.tiny.cloud/docs/advanced/accessibility/' target='_blank' rel='noopener noreferrer'>
                        <WrappedMessage message={messages.accessibilityLink} />
                        <Icon zoom='14' name='link-external' />
                    </a>}
                </div>
                {this.state.EditorComponent ?
                    <this.state.EditorComponent
                        id={editorId}
                        key={this.props.editorStyle /* Set so that editor style changes force a re-init of TinyMCE */}
                        value={this.props.defaultValue}
                        init={editorOptions}
                        onEditorChange={this.onChange}
                    />
                    : <Spinner />
                }
                {
                    this.props.description &&
                    <p
                        id={`${this.id}Description`}
                        className='lx-textbox-description lx-htmltextbox-description'
                    >
                        {this.props.description}
                    </p>
                }
            </div>
        );
    }

    private showErrors() {
        return (this.props.required && !this.state.content && this.props.showErrors);
    }

    @bind private async onChange() {
        // TODO: make this a fully controlled component, and use content value
        // from props.
        const content = await this.getEditorContent();
        this.setState({ content });
        this.props.onChange(content);
    }

    @bind private filePickerCallback(callback: (uri: string) => void) {
        const input: HTMLInputElement = document.createElement('input');
        input.setAttribute('type', 'file');
        // TODO: Support SVG images when https://github.com/edx/blockstore/issues/63 is fixed.
        input.setAttribute('accept', 'image/jpeg,image/png,image/gif');

        input.onchange = () => {
            const file = input.files && input.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const id = Date.now().toString();
                    const blobCache = this.state.editor.editorUpload.blobCache;
                    const base64 = (reader.result as string).split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    callback(blobInfo.blobUri());
                };
                reader.readAsDataURL(file);
            }
        };

        input.click();
    }

    @bind private onEditorSetup(editor: any) {
        // Set a reference to the tinymce editor instance so we can control it
        // directly later.
        this.setState({ editor });
    }

    // Scan for any local image that was added via the file picker and store the file's name
    // as a data property on the <img> element to be able to upload the file under a recognizable name
    // when the content gets saved before returning the editor content.
    // This is not strictly necessary, but it helps keeping names of uploaded files clean.
    private async getEditorContent() {
        const editor = this.state.editor;
        const results = await editor.editorUpload.scanForImages();
        results.forEach((result: any) => {
            const blobId = result.blobInfo.id();
            const originalFilename = result.blobInfo.blob().name;
            const filenameParts = originalFilename.split('.');
            const extension = filenameParts.pop();
            const uploadFilename = `${filenameParts.join('.')}.${blobId}.${extension}`;
            result.image.dataset.lxUploadFilename = uploadFilename;
        });
        return editor.getContent();
    }

    /**
     * Applies styles, etc. where required to be set directly on the tinymce
     * editor instance, depending on whether it should be displaying in error state.
     */
    private updateErrorState(editor: any) {
        if (this.showErrors()) {
            editor.iframeElement.contentDocument.body.style.backgroundColor = '#fdebea';
        } else {
            editor.iframeElement.contentDocument.body.style.backgroundColor = '';
        }
    }
}

let lastId = 0;
/** Helper function to generate a unique HTML ID. */
function uniqueId(): string { return `HtmlTextBox${++lastId}`; }
