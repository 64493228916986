import * as React from 'react';
import classNames from 'classnames';
import { intl } from 'i18n';
import { useHistory } from 'react-router-dom';

import { Career, CareerList, CareerCategoryColorGroupEnum, RelatedCareers } from 'labxchange-client';
import { isKeyboardEnterEvent } from 'global/utils';
import { ROUTES } from 'global/constants';

import messages from '../../displayMessages';

interface CareerCardProps {
  career: Career | CareerList | RelatedCareers;
}

export const CareerCard: React.FC<CareerCardProps>  = ({ career }) => {
  const history = useHistory();
  const colorGroup = career.categories?.[0]?.colorGroup || CareerCategoryColorGroupEnum.One;

  const onClickCard = () => {
    if (career.isPublished) {
      history.push(ROUTES.CareerExplorer.CAREER_DETAILS_SLUG(career.slug));
    }
  };

  const careerInfo = career.isPublished ? (
    <div className='career-info'>
      <span className='ce-heading-xs'>{career.title}</span>
      <span className='ce-label-small'>
        {intl.formatMessage(messages.careerCardMinQualification)}
        <span className='font-weight-700'>{career.minimumQualification}</span>
      </span>
      <span className='ce-label-small'>
        {intl.formatMessage(messages.careerCardSalaryRange)}
        <span className='font-weight-700'>{`$${career.minSalary} - $${career.maxSalary}`}</span>
      </span>
    </div>
  ) : (
    <div className='career-info'>
      <span className='ce-heading-xs'>{career.title}</span>
      <span className='ce-label-small coming-soon-text'>
        {intl.formatMessage(messages.careerCardComingSoonText)}
      </span>
    </div>
  );

  return (
    <div
      tabIndex={0}
      role='button'
      className={classNames('career-card', colorGroup, {'disabled': !career.isPublished})}
      onClick={onClickCard}
      onKeyDown={(e) => isKeyboardEnterEvent(e) && onClickCard()}
    >
      <img
        className='career-icon'
        alt='Career Icon'
        src={career.icon || '/assets/images/career-explorer/career-card-placeholder.svg'}
      />
      {careerInfo}
    </div>
  );
};

export default CareerCard;
