import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleCookies',
        defaultMessage: 'Cookies and Similar Technologies',
        description: 'HTML title for the cookies page.',
    },
    pageSubTitle: {
        id:'pageSubTitleCookies',
        defaultMessage: 'Dated: September 19, 2019',
        description: 'Publish date for the cookies page.',
    },
});

export default messages;
