import { APIPermissions, ItemMetadata, ItemUserAttributes } from 'labxchange-client';
import { FavoriteButton } from 'ui/components/FavoriteButton';
import messages from 'library/displayMessages';
import { default as assignMessages } from 'library/components/ItemAssign/displayMessages';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import * as url from 'url';
import { Button, Icon, KebabMenu, KebabMenuItem, showLoginRequiredMessage } from 'ui/components';
import { ModalType } from '../ItemAssign';
import AssignToClassroomModal from '../ItemAssign/AssignToClassroomModal';
import AssignToMentorshipModal from '../ItemAssign/AssignToMentorshipModal';
import AssignToPathwayModal from '../ItemAssign/AssignToPathwayModal';
import { WrappedMessage } from 'utils';
import { UI_IS_XS } from 'ui/breakpoints';
import bind from 'bind-decorator';
import { analyticsInstance } from '../../../tracking';
import { EVENT_NAMES } from '../../../tracking/constants';

/**
 * The ItemMetadataTable component displays actions (as icons) which can be taken
 * on an item, such as favorite or share.
 *
 * Used on the ItemPage and elsewhere.
 */

interface ItemActionsProps {
    itemMetadata: ItemMetadata;
    userAttributes: ItemUserAttributes;
    userPermissions?: APIPermissions;
    loggedInUsername?: string;
    isUserLoggedIn?: boolean;
    forceDisplayMarkAsIncomplete: boolean;
    onClone?(itemId: string): void;
    onCreateTranslation?(itemId: string): void;
    markAsIncomplete?(): void;
    onShare?(): void;
}

interface ItemActionsState {
    keepKebabExpanded: boolean;
    modalType: ModalType;
    isMobileView: boolean;
    assignDropdownExpanded: boolean;
}

export class ItemActions extends React.PureComponent<ItemActionsProps, ItemActionsState> {

    private mediaQuery = UI_IS_XS;  // This matches Bootstrap's definition of small (sm) breakpoint.

    constructor(props: ItemActionsProps) {
        super(props);
        this.state = {
            isMobileView: false,
            keepKebabExpanded: false,
            modalType: ModalType.None,
            assignDropdownExpanded: false,
        };
    }

    @bind private setIsMobileView(): void {
        this.setState({ isMobileView: this.mediaQuery.matches });
    }

    public async componentDidMount() {
        this.mediaQuery.addListener(this.setIsMobileView);
        this.setIsMobileView();
    }

    public componentWillUnmount() {
        this.mediaQuery.removeListener(this.setIsMobileView);
    }

    public render() {
        let editUrl;
        const originalId = this.props.itemMetadata.id;
        if (this.props.userAttributes?.canEditObject && originalId) {
            const detailUrl = detailUrlForEntity({
                id: originalId,
                type: this.props.itemMetadata.type,
            });
            if (detailUrl) {
                editUrl = url.parse(detailUrl).pathname + '/edit';
            }
        }

        return (
            <div className='item-actions'>
                    <>
                        {(!this.state.isMobileView && isPathway(this.props.itemMetadata) && this.props.onClone) &&
                          <Button
                            className='action-button-link-design'
                            btnStyle='unstyled'
                            icon='pencil'
                            label={messages.copyAndEdit}
                            onClick={() => {
                                this.props.onClone!(this.props.itemMetadata.id);
                                analyticsInstance.track(EVENT_NAMES.AssetActionHeaderButtonClicked, {
                                    url: window.location.toString(),
                                    actionText:'copy and edit',
                                    itemType: this.props.itemMetadata.type
                                });
                            }}
                            disabled={!isPathway(this.props.itemMetadata)}
                          />
                        }

                        <FavoriteButton
                            itemId={originalId}
                            isFavorite={this.props.userAttributes? this.props.userAttributes.isFavorite : false}
                            btnStyle='pathway-svg'
                            showLabel={true}
                            itemType={this.props.itemMetadata.type}
                        />

                        {(!this.state.isMobileView && isPathway(this.props.itemMetadata) && (this.props.userPermissions?.library.canAssignAssetToClassroom || !this.props.isUserLoggedIn)) &&
                          <Button
                            className='action-button-link-design'
                            btnStyle='unstyled'
                            onClick={() => {
                                if(!this.props.isUserLoggedIn) {
                                    showLoginRequiredMessage();
                                    return;
                                } else {
                                    this.setState({modalType: ModalType.Classroom});
                                }
                                analyticsInstance.track(EVENT_NAMES.AssetActionHeaderButtonClicked, {
                                    url: window.location.toString(),
                                    actionText:'add to class',
                                    itemType: this.props.itemMetadata.type
                                });
                            }}
                            icon='plus'
                            iconOutline
                            label={assignMessages.itemAddToClassroomLabel}
                          />
                        }
                    </>

                <KebabMenu
                    keepExpanded={this.state.keepKebabExpanded}
                    darkKebab={true}
                    icon='kebab-horizontal'
                >
                    {this.props.onShare && this.props.itemMetadata.isPublic &&
                      <KebabMenuItem
                        iconName='share-lowprofile'
                        message={messages.actionShare}
                        onClick={this.props.onShare}
                        disabled={false}
                      />
                    }

                    {(this.state.isMobileView && isPathway(this.props.itemMetadata) && this.props.onClone) &&
                      <KebabMenuItem
                        iconName='pencil'
                        message={messages.copyAndEdit}
                        onClick={() => {
                            this.props.onClone!(this.props.itemMetadata.id);
                            analyticsInstance.track(EVENT_NAMES.AssetActionHeaderButtonClicked, {
                                url: window.location.toString(),
                                actionText:'copy and edit',
                                itemType: this.props.itemMetadata.type
                            });
                        }}
                        disabled={!isPathway(this.props.itemMetadata)}
                      />
                    }

                    {(this.state.isMobileView && isPathway(this.props.itemMetadata) && (this.props.userPermissions?.library.canAssignAssetToClassroom || !this.props.isUserLoggedIn)) &&
                        <KebabMenuItem
                            iconName='plus'
                            message={assignMessages.itemAddToClassroomLabel}
                            onClick={() => {
                                if(!this.props.isUserLoggedIn) {
                                    showLoginRequiredMessage();
                                    return;
                                } else {
                                    this.setState({modalType: ModalType.Classroom});
                                }
                                analyticsInstance.track(EVENT_NAMES.AssetActionHeaderButtonClicked, {
                                    url: window.location.toString(),
                                    actionText:'add to class',
                                    itemType: this.props.itemMetadata.type
                                });
                            }}
                            disabled={!isPathway(this.props.itemMetadata)}
                        />
                    }

                    {this.props.userPermissions?.library.canCloneForTranslation && this.props.onCreateTranslation &&
                      <KebabMenuItem
                        iconName='diff'
                        message={messages.actionCreateTranslation}
                        onClick={() => this.props.onCreateTranslation!(this.props.itemMetadata.id)}
                        disabled={false}
                      />
                    }
                    {(
                        this.props.forceDisplayMarkAsIncomplete
                        || (this.props.userAttributes?.completion && this.props.markAsIncomplete)
                    ) ?
                        <KebabMenuItem
                            iconName='redo'
                            message={messages.markAsIncomplete}
                            onClick={this.props.markAsIncomplete}
                            disabled={false}
                            data-testid='mark-incomplete'
                        />
                        : null}

                    {editUrl &&
                      <KebabMenuItem
                        iconName='pencil'
                        disabled={false}
                        message={messages.libraryCardMenuEditTitle}
                        href={editUrl}
                        onClick={() => {
                            analyticsInstance.track(EVENT_NAMES.AssetActionHeaderButtonClicked, {
                                url: window.location.toString(),
                                actionText:'Edit',
                                itemType: this.props.itemMetadata.type
                            });
                        }}
                      />
                    }
                </KebabMenu>
                {this.renderModals()}
            </div>
        );
    }

    private renderAssignButton() {
        if (
            isPathway(this.props.itemMetadata) && (
                this.props.userPermissions?.library.canAssignAssetToClassroom
                || this.props.userPermissions?.library.canAssignAssetToMentorship
            )
        ) {
            const kebabInnerButton = <>
                <Icon name={'pin'} zoom={'x1'}/>
                <span><WrappedMessage message={messages.assignButton} /></span>
                <Icon
                    className='assign-dropdown-indicator'
                    name={this.state.assignDropdownExpanded ? 'chevron-down' : 'chevron-up'}
                    zoom='x1'
                />
            </>;
            return <KebabMenu
                icon='kebab-horizontal'
                button={kebabInnerButton}
                buttonAdditionalStyling={'btn lx-btn-left-icon'}
                className={'action-button'}
                onExpanded={this.onAssignExpanded}
            >
                {this.props.userPermissions?.library.canAssignAssetToMentorship &&
                    <KebabMenuItem
                        iconName='mentorship'
                        message={assignMessages.itemAssignToMentorshipLabel}
                        onClick={() => this.setState({modalType: ModalType.Mentorship})}
                        disabled={false}
                    />
                }
            </KebabMenu>;
        }
        return null;
    }

    @bind private async onAssignExpanded(expanded: boolean) {
        this.setState({assignDropdownExpanded: !expanded});
    }

    private renderModals() {
        if (this.props.loggedInUsername) {
            switch (this.state.modalType) {
                case ModalType.Classroom:
                    return (
                        <AssignToClassroomModal
                            itemMetadata={this.props.itemMetadata}
                            onClose={() => this.setState({modalType: ModalType.None})}/>
                    );
                case ModalType.Mentorship:
                    return (
                        <AssignToMentorshipModal
                            loggedInUsername={this.props.loggedInUsername}
                            itemMetadata={this.props.itemMetadata}
                            onClose={() => this.setState({modalType: ModalType.None})}/>
                    );
                case ModalType.Pathway:
                    return (
                        <AssignToPathwayModal
                            itemMetadata={this.props.itemMetadata}
                            onClose={() => this.setState({modalType: ModalType.None})}/>
                    );
                default:
                    return null;
            }
        }
        return null;
    }
}

const isPathway = (item: ItemMetadata) => item.id.includes('lx-pathway');
