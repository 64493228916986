import * as React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'global/constants';
import { useLayoutSize } from 'utils';
import { Icon } from 'elements';

import { CareerSearchBar } from '../CareerSearchBar';
import { CareerExplorerLogo } from '../CareerExplorerLogo';
import { CareerExplorerLogoType } from '../../utils';

interface CareerHeaderProps {
  headerClassName?: string;
  navClassName?: string;
  heroSection?: React.ReactNode;
  hideSearchBar?: boolean;
  logoType?: CareerExplorerLogoType;
}

export const CareerHeader: React.FC<CareerHeaderProps> = ({
  heroSection, headerClassName, hideSearchBar, navClassName, logoType
}) => {
  const history = useHistory();
  const layoutSize = useLayoutSize();
  const [toggleSearchIcon, setToggleSearchIcon] = React.useState(false);
  const [hideLogo, setHideLogo] = React.useState(false);
  const showIcon = ['small', 'mobile'].includes(layoutSize) && !toggleSearchIcon;

  const onSearch = (query: string) => {
    if (query) {
      history.push(ROUTES.CareerExplorer.HOME + '?title=' + query);
    }
  };

  const onClickSearchIcon = () => {
    setToggleSearchIcon(!toggleSearchIcon);
    setHideLogo(true);
  };

  const onBlur = (query: string) => {
    if (query === '') {
      setToggleSearchIcon(false);
      setHideLogo(false);
    }
  };

  const navClasses = classNames('ce-nav', {
      'show-icon': showIcon,
      'hide-logo': hideLogo,
    },
    navClassName
  );

  return (
    <header className={classNames('ce-header', headerClassName)}>
      <nav className={navClasses}>
        <div className='nav-top-section'>
          <NavLink className='logo-link' to={ROUTES.CareerExplorer.HOME}>
            <CareerExplorerLogo width={170} height={50} logoType={logoType} />
          </NavLink>
          {!hideSearchBar && (
            showIcon ?
              <button
                aria-label='search-icon-button'
                onClick={onClickSearchIcon}
                className='nav-search-icon'
                data-testid='search-icon'
              >
                <Icon name='search' fill='#FFFFFF' zoom='1.3em' />
              </button>
            :
              <div className='top-search-bar' data-testid='top-search-bar'>
                <CareerSearchBar
                  onSubmit={onSearch}
                  onBlur={onBlur}
                />
              </div>
          )}
        </div>
        {heroSection}
      </nav>
    </header>
  );
};

