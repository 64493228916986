import * as React from 'react';
import classNames from 'classnames';

import { CareerCategoryColorGroupEnum } from 'labxchange-client';

interface CareerProgressBarProps {
    percentage: number;
    colorGroup: CareerCategoryColorGroupEnum;
}

export const CareerProgressBar: React.FC<CareerProgressBarProps>  = ({ percentage, colorGroup }) => (
  <div className='career-progress-bar'>
    <div className='progress-bar-wrapper'>
      <div
        className={classNames('progress-bar', colorGroup)}
        style={{ width: `${percentage}%` }}
      />
    </div>
    <span className='percentage'>{Math.round(percentage)}%</span>
  </div>
);

export default CareerProgressBar;
