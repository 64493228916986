import React, { useState, useEffect } from 'react';
import { intl } from 'i18n';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { CareerList, CareerListPaginated } from 'labxchange-client';
import { CareersApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { Spinner } from 'ui/components';

import { CareerCard } from '../CareerCard/CareerCard';
import { CareerStandardLayout } from '../CareerStandardLayout';
import { CareerSearchBar } from '../CareerSearchBar';
import { topCareers } from '../../utils';
import messages from '../../displayMessages';

export const CareerHome: React.FC  = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const query = queryParams.get('title');

  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const [careers, setCareers] = useState<CareerList[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const fetchCareers = async (value?: string, page: number = 1) => {
    try {
      setLoading(true);
      const response: CareerListPaginated = await CareersApi.list({
        title: value, page, pageSize: 16
      });
      setCareers(prevCareers => page === 1 ? response.results
        : [...prevCareers, ...response.results]
      );
      setHasNextPage(!!response.next);
      setCurrentPage(page);
      setCount(response.count);
    } catch (error) {
      setCareers([]);
      // tslint:disable-next-line:no-console
      console.error('Error fetching careers', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCareers(query || '');
  }, [query]);

  const onClickLoadMore = async () => {
    await fetchCareers(query || '', currentPage + 1);
  };

  const onSearch = (searchQuery: string) => {
    if (searchQuery) {
      setCareers([]);
      history.push(ROUTES.CareerExplorer.HOME + '?title=' + searchQuery);
    }
  };

  const heroSection = (
    <div className='home-hero-section'>
    {query
      ?
      <>
        <span>{intl.formatMessage(messages.careerShowingResultsLabel)}</span>
        <h4>{query}</h4>
      </>
      :
      <>
        <h4>{intl.formatMessage(messages.careerNavHeaderTitle)}</h4>
        <span>{intl.formatMessage(messages.careerNavHeaderSubtitle)}</span>
        <CareerSearchBar
          onSubmit={onSearch}
        />
      </>
    }
    </div>
  );

  return (
    <CareerStandardLayout
      mainClassName='career-home-page'
      pageTitle={messages.careerDefaultPageTitle}
      hideSearchBar={query ? false : true}
      heroSection={heroSection}
    >
      <div className='career-home'>
        <>
          <span className='results-count'>
            {intl.formatMessage(messages.careerResultsText, {
              count, plural: count === 1 ? '' : 's'
            })}
          </span>
          {!careers.length && query && !loading ? (
            <div className='empty-state'>
              <img
                className='empty-state-image'
                alt='No results found'
                src='/assets/images/career-explorer/career-empty-state.svg'
              />
              <h5>{intl.formatMessage(messages.careerNoResultsHeading)}</h5>
              <span>{intl.formatMessage(messages.careerNoResultsSubheading)}</span>
              <div className='top-careers'>
                <span className='ce-heading-xs'>{intl.formatMessage(messages.careerTopRecommendations)}</span>
                <div className='careers-list'>
                  {topCareers.map((career: CareerList) => <CareerCard key={career.slug} career={career} />)}
                </div>
              </div>
            </div>
            ) : (
              <div className='careers-list'>
                {careers.map((career) => <CareerCard key={career.slug} career={career} />)}
                {loading ? <Spinner /> :
                  <button
                    type='button'
                    className={classNames('load-more-button', {'hide-button': !hasNextPage})}
                    onClick={onClickLoadMore}
                  >
                    <span className='ce-label'>
                      {intl.formatMessage(messages.careerLoadMoreButton)}
                    </span>
                  </button>
                }
              </div>
            )
          }
        </>
      </div>
    </CareerStandardLayout>
  );
};

export default CareerHome;
