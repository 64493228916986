import * as React from 'react';
import { intl } from '../../../i18n';
import messages from '../../displayMessages';
import { Icon } from '../../../elements';


interface BlockPreviewProps {
    previewImageUrl: string;
    button: React.ReactNode;
    isSimulationBlock?: boolean;
}

export const BlockPreview: React.FunctionComponent<BlockPreviewProps> = (props) => {
    return (
        <div
            className='block-preview'
            style={{backgroundImage: `url(${props.previewImageUrl})`}}
        >
            {props.isSimulationBlock && (
                <div className='simulation-banner'>
                    <Icon
                        className='user-experience-indicator'
                        name='device-desktop'
                        zoom='1.5em'
                    />
                    <span className='banner-text'>
                    {intl.formatMessage(
                        messages.simulationMobileBannerInfo,
                        {
                            link: <a
                                href='https://labxchange.zendesk.com/hc/en-us/articles/360035703594-Technical-Requirements'
                                target='_blank'
                                rel='noreferrer'>
                                {intl.formatMessage(messages.simulationMobileBannerLearnMore)}</a>
                        }
                    )}
                </span>
                </div>
            )}

            <div className='block-start-button-container'>
                {props.button}
            </div>
            <div className='block-preview-overlay'></div>
        </div>
    );
};
