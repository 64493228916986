import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { StandardPageLayout } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

// tslint:disable:max-line-length

export class PrivacyPolicyPageInternal extends React.PureComponent<{}, {}> {

    public render() {
        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                headerFeature={
                    <div>
                    <h1 className='legal-header-title'>
                        <WrappedMessage message={messages.pageTitle}/></h1>
                    <p className='legal-header-sub-title'>
                        <WrappedMessage message={messages.pageSubTitle}/></p>
                    </div>
                }
            >
                <div className='legal-page'>
                    <div className='legal-content'>
                        <p>
                            This Privacy Notice (the “<strong>Notice</strong>”) explains what information LabXchange
                            (“<strong>LXC</strong>”) collects about you through the LXC services, how we use that data,
                            and when and with whom we share it. The LXC services (the “<strong>Services</strong>”) are
                            defined in our Terms of Service, which are available at {' '}
                            <NavLink to={ROUTES.General.TOS}>https://www.labxchange.org/tos</NavLink>.
                        </p>

                        <p>
                            The information you provide to LXC allows us to provide you with access to LXC, to provide
                            the Services to you, to administer the LXC website, and to keep you updated on services we
                            provide.
                        </p>

                        <p>
                            By accessing or using our Services, you agree to our collection and use of information in
                            accordance with this Privacy Notice.
                        </p>

                        <p>If you are located outside of the United States and in a region that has enacted a relevant
                            personal data protection law, you can find additional privacy disclosures that may apply to
                            you at {' '}
                            <NavLink to={ROUTES.General.NON_US_PRIVACY_DISCLOSURE}>https://www.labxchange.org/non-us-privacy-disclosure</NavLink>.
                        </p>

                        <p>
                            Capitalized terms contained but not otherwise defined in this Notice have the meanings
                            provided in our Terms of Service.
                        </p>

                        <h2>Scope</h2>

                        <p>
                            LXC is a service of the President and Fellows of Harvard College (“<strong>Harvard
                            University</strong>”). This Notice applies to information that we collect in connection with
                            our Services.
                        </p>

                        <p>
                            To become a Registered User of the Services you must first create a user account with
                            LabXChange directly or with one of our third-party sign-on services like Google or
                            Facebook.The information collected by a third party when you register on their site will be
                            governed by that organization’s privacy policy and terms of use and is not under our
                            direction or control. Some of the information collected when you create a user account with
                            a third party site will be shared with LXC and will be subject to LXC’s Privacy Notice to
                            the extent it is in our possession, but that third party’s privacy policy will continue to
                            apply to the use and disclosure of your information by the third party.
                        </p>

                        <p>This Notice does not apply to information that you may provide to Harvard University
                            independently of our websites and Services.</p>

                        <p>Your use of the Services is subject to the LXC Terms of Service, which incorporate this
                            Privacy Notice. </p>

                        <h2>Nature, Use, and Disclosure of Information </h2>

                        <h3>Information we collect</h3>

                        <p>We collect information, including Personally Identifiable Information, when you register for
                            a User Account, access the LXC website, create a profile on the LXC website, or otherwise
                            use the Services or communicate with LXC.
                            “<strong>Personally Identifiable Information </strong>”
                            means your name, your email address, and other information that
                            personally identifies you or that pertains to you as an identified or identifiable
                            individual. We encourage you to use discretion before voluntarily sharing Personally
                            Identifiable Information through the Services. </p>

                        <p>You can use many features of the Services without registering for an account. Information
                            relating to your use is collected whether or not you have registered. </p>

                        <p>The following are examples of the information we collect: </p>
                        <ul>
                            <li>
                                When you register for an LXC User Account using a third party site, such as Google or
                                Facebook, we collect certain pieces of your personal information from them. This
                                includes your full name, email address, country or region of residence, and city. In
                                addition we collect your date of birth from you. You may choose to provide additional
                                Personally Identifiable Information when you create your LXC profile.
                            </li>
                            <li>We also may obtain information about you when you contact our technical support team,
                                send us email or receive email from us, participate in a user survey, or otherwise
                                communicate with us through the Services.
                            </li>
                            <li>When you use our Services, we may collect information about which Resources, Services,
                                portions or pages of a Service were visited, the order in which they were visited, when
                                they were visited, and which hyperlinks and other user interface controls were used.
                            </li>
                            <li>We also may collect a variety of other information, including IP address, operating
                                system, unique IDs of cookies, unique IDs sent from mobile devices, network carriers or
                                data providers, device types, browser types, version and language, Internet Service
                                Provider (ISP) information, referring and exit websites and applications, attributes of
                                computer or device usage, date/time stamps, click-through URLs, information contained in
                                HTTP headers or other internet transfer protocol signals, clickstream data and other
                                similar data.
                            </li>
                            <li>We may be able to determine your geographic location (or the location of your device or
                                ISP) with such data. You may be able to change the settings on your computer or mobile
                                device to prevent it from providing us with any location information.
                            </li>
                            <li>We may also collect information about you, including Personally Identifiable Information,
                                from third parties. This may include, for example:</li>
                            <ul>
                                <li>Information provided by your employer or an organization with which you are affiliated,
                                    such as when they authorize you to use the Services on their behalf.
                                </li>
                                <li>Information provided by other users, such as when an educator generates information
                                    about you using the LXC class functionality (a “<strong>Class</strong>”) or when a mentor generates
                                    information about you.
                                </li>
                                <li>Information provided by our partners and service providers. For
                                    example, from time to time, we may offer a special program or activity in partnership
                                    with a third party. If you participate in these special programs, we may collect
                                    information from you or from the partner to facilitate those programs.
                                </li>
                            </ul>
                        </ul>

                        <p>
                            We and third parties use web analytics and other automated tools to obtain some of the
                            information we collect. These tools include cookies, pixel tags, scripts, log files, web
                            beacons, and other similar technologies, which are further described at {' '}
                            <NavLink to={ROUTES.General.COOKIES}>https://www.labxchange.org/cookies</NavLink>.
                            Some of the cookies and tracking technologies on our
                            Services are managed by us (first-party cookies), while others are managed by third parties
                            that we do not control (third-party cookies). For more information about data collected by
                            third parties, please see “Use of certain third-party applications, websites and social
                            networks” below.
                        </p>

                        <p>
                            Most browsers and mobile devices offer instructions on how to disable cookies (typically in
                            “<strong>Preferences</strong>” or “<strong>Settings</strong>”). If you disable our cookies, some functions and conveniences of
                            our Services may not work properly or at all. We will attempt to honor your privacy
                            preferences, but we may not be able to honor all of them, including, for example, Do Not
                            Track signals sent by your browser.
                        </p>

                        <h3>
                            How we use the information we collect, whom we share it with, and the reasons we share it
                        </h3>

                        <p>
                            We use the information we collect to make our Services available to you. We may also use the
                            information to communicate with you about the Services, and in the other ways described
                            below.
                        </p>

                        <p>
                            We share the information as necessary to provide the Services, as well as for other purposes
                            described below. For example, to provide the Services, we share some of your information
                            with LXC staff, and other users of our Services, who can view your profile and username and,
                            in some cases, the Resources you have contributed, as well as postings you make on forums
                            provided by LXC. We also share information with a limited number of third-party contractors
                            who perform certain functions on our behalf. For example, we share information with
                            institutions and others who have contributed Resources and other content to our system, for
                            purposes including enabling them to understand the use of their content and to improve its
                            performance and value.
                        </p>

                        <p>Comments or other information posted or contributed by you to any part of our Services
                            designed to be visible to the public or to other LXC participants may be viewed by others
                            who use or access the Services. Please bear this in mind when you post comments or other
                            information.</p>

                        <p>We may collect, use, process, store, and share information about you, including in some cases
                            Personally Identifiable Information, in the following ways:</p>
                        <ul>
                            <li>To authenticate your identity.</li>
                            <li>To respond to a specific inquiry or provide you with access to the specific Service or
                                Resource you select.
                            </li>
                            <li>To provide, administer, maintain, improve, individualize, and evaluate the Services.
                                This may include sharing information with LXC staff, administrators, and others who are
                                involved in performing these functions for us, as well as with other third parties in
                                some circumstances.
                            </li>
                            <li>To provide or conduct the Services by sharing information about you (including {' '}
                                <strong>Personally Identifiable Information</strong>) with other users of the Services. This may include,
                                for example, sharing Resources, comments, and other content that you submit (“<strong>User
                                    Content</strong>”) with other users of the Services, along with your name, user name, photo,
                                geographic location, and/or other potentially identifying information, and providing
                                other users with other information that you have elected to share.
                            </li>
                            <li>To perform research – for example, academic, scientific, or educational research.</li>
                            <li>To send you notices, updates, announcements and communications (whether by email, phone,
                                text or SMS messages, pop up notifications, or other similar methods) about the
                                Services, or about other programs, services, products, events, opportunities, or
                                activities offered by us, Harvard University, or selected contributors and other
                                partners, that may be of interest to you.
                            </li>
                            <li>To maintain and improve the functioning and security of the Services and our software,
                                systems, and network.
                            </li>
                            <li>To enable service providers or contractors to perform certain functions on our behalf,
                                including, for example, analytics and information processing; processing emails, blogs,
                                and other subscriptions; operating or administering a Service or part of it; providing
                                or updating content for any of the Services; or in connection with other aspects of our
                                Services, or in order to integrate with other products or services.
                            </li>
                            <li>
                                To respond to subpoenas, court orders, or other legal process; in response to a
                                request for cooperation from law enforcement or another government agency; to
                                investigate, prevent, or take action regarding actual or suspected violations of our
                                Terms of Service or this Privacy Notice, illegal activities, fraud, security, or
                                technical issues, misuse of the Services, or other misconduct, or to enforce our Terms
                                of Service or this Privacy Notice; as otherwise may be required by applicable law; or to
                                protect our rights, property, or safety or those of others.
                            </li>
                            <li>
                                To share aggregated, anonymized information with the public and with third parties,
                                including but not limited to researchers and partners.
                            </li>
                            <li>
                                To serve you targeted advertisements on other websites, apps, or services, including on
                                Facebook and other social networks, or on other devices you may use, using
                                interest-based advertising and third party advertising companies.
                            </li>
                            <li>
                                If the Services that you use are subject to the Family Educational Rights and Privacy
                                Act (20 U.S.C. 1232g) and the Family Educational Rights and Privacy Act Regulations (34
                                CFR Part 99) (together, “FERPA”), we may disclose information consistent with FERPA to
                                the extent FERPA applies.
                            </li>
                            <li>
                                To achieve any purpose described elsewhere in this Privacy Notice.
                            </li>
                            <li>
                                To achieve any purpose to which you have consented at the point of collection of
                                information or at another time.
                            </li>
                            <li>
                                To achieve any other purpose permitted by law.
                            </li>
                        </ul>

                        <h3>Use for personalization and pedagogical improvements</h3>

                        <p>
                            We aim to provide current and future users of the Services with the best possible
                            educational and learning experience. We may conduct research intended to enhance our
                            offerings and the quality of learning and related sciences more generally. To further these
                            goals, we sometimes present different users with different versions of our content. We do
                            this to personalize the experience to the individual learner (assess the learner's level of
                            ability and learning style, and present materials best suited to the learner), to evaluate
                            the effectiveness of our content and services, to improve our understanding of the learning
                            process, and otherwise to improve the effectiveness of our offerings, teaching, and learning
                            more generally. We may publish or otherwise publicize results from this process but will
                            only share Personally Identifiable Information as otherwise permitted under this Privacy
                            Notice.
                        </p>

                        <p>
                            We may collect usage information about your use of our Service, such as the number of
                            problems you have attempted, the number of videos you have viewed, and the amount of time
                            spent to complete a problem. This enables us to better tailor educational experiences that
                            are most appropriate for you.
                        </p>

                        <p>
                            We may use data that is no longer personally identifiable because it has been aggregated or
                            anonymized for product development, research, analytics, and other purposes, including for
                            the purpose of analyzing, improving, or marketing the Services. LXC may share this data with
                            business partners and others for those purposes.
                        </p>

                        <h3>Disclosure to other users of the Services</h3>

                        <p>
                            The Services may make your information accessible to other Users, for example, other members
                            of a Class you participate in or a person who agrees at your request to be your mentor on
                            LXC (a “<strong>Mentor</strong>”). Once information about you is made available to others online, it may be
                            collected and used by the recipients in ways we cannot control. For example, your personal
                            information may be visible to others in the following circumstances:
                        </p>
                        <ul>
                            <li>
                                <strong>Information you post to shared spaces of the Services. </strong>
                                When you post content or participate in a discussion group on the Services your profile,
                                which could include, among other things, your name and location information you provide, as well as
                                the comment or other content you post, will be visible to other Users. Accordingly,
                                please be careful about including Personally Identifiable Information in your posts.
                            </li>
                            <li>
                                <strong>Connecting with a Mentor. </strong>
                                When your account is connected to a Mentor on the Services, the Mentor will be able to
                                see your profile information and your activities on the Services, such as which videos
                                you have watched, the questions and responses you post, and other usage information.
                            </li>
                        </ul>

                        <h3>Use of certain third-party applications, websites and social networks </h3>

                        <p>
                            <strong>We may display content from, or provide links to, third-party sites and
                                resources. </strong>
                            Our Services display embedded content from, and contain links to, websites and other
                            resources of third parties, including other content partners, survey tools, and service
                            providers, which may capture Personally Identifiable Information as you use our
                            Services. One example of such content is videos from YouTube that are displayed as part
                            of the Services. Since we display YouTube videos, YouTube collects various kinds of
                            information from you when you use our Services, as described in its privacy policy.
                            YouTube and those other third parties may also serve you advertisements as part of their
                            content or service. Those websites, applications, and resources are not under our
                            control, and you acknowledge and agree that we are not responsible for the collection
                            and use of your information by them or for their content. We encourage you to be aware
                            when you are viewing third-party content via our Services or are redirected to a
                            third-party website, application, or resource, and to review the privacy policies and
                            terms of use of each website, application, and resource you visit or use.
                        </p>
                        <p>
                            <strong>We may share data with social networks at your request. </strong>
                            If we permit you to connect your LXC user account with social networks or similar
                            services, and if you decide to do so (which you may choose at your discretion), we may
                            disclose your Personally Identifiable Information to the social network or other
                            service.
                        </p>
                        <p>
                            <strong>We may share data with third-party applications that you authorize. </strong>
                            Third-party application developers and service providers (commonly known as “<strong>App
                            Developers</strong>”) may build services complementary to our Services, such as a mobile
                            application that interacts with our platform, or may use our content to build
                            unaffiliated applications and services if permitted. If you connect your LXC user
                            account to a third-party application or service, or approve access to your LXC user
                            account by a third party application or service, you consent to LXC sharing information
                            collected from you or about you, including Personally Identifiable Information, with
                            that third party.
                        </p>
                        <p>
                            <strong>We may share data in connection with special programs you participate in. </strong>
                            If you participate in special programs where we partner with third parties, we may share
                            data collected from or about you, including Personally Identifiable Information, with
                            our third party partners to facilitate the program or services being offered. For
                            instance, if you participate in a Class sponsored by your school, LXC may share
                            information about your participation with your school and educator. These program
                            partners may use your information we share with them as described in their own privacy
                            policies.
                        </p>
                        <p>
                            <strong>
                                We use Google Analytics, and may use other third-party analytics tools, to help us
                                measure traffic and usage trends for our website and to understand more about the
                                demographics of our users. </strong>
                            Google Analytics uses cookies and similar technologies to collect and analyze
                            information about the use of our website and report on activities and trends. That
                            service also may collect information regarding your use of other websites, apps, and
                            online resources. You can learn more about Google’s practices at {' '}
                            <a href='http://www.google.com/policies/privacy/partners'>
                                http://www.google.com/policies/privacy/partners
                            </a>,
                            and view its currently available opt-out options at {' '}
                            <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a>.
                        </p>
                        <p>
                            <strong>
                                We may participate in interest-based advertising and allow use of third-party
                                tracking technologies on the Services for this purpose.
                            </strong> {' '}
                            We do not display any targeted advertising on our Services. However, we may participate
                            in interest-based advertising and use third party advertising companies to serve you
                            targeted advertisements on other websites, apps, and services, including on Facebook and
                            other social networks, or on other devices you may use. These third-party ad networks
                            use tracking technologies to recognize your browser or device and to collect information
                            about your use of our Services in order to provide you customized content, advertising,
                            and commercial
                            messages that may be more relevant to your interests, as well as to provide
                            advertising-related services such as reporting, attribution, analytics, and market
                            research. You may be able to opt out of some interest-based advertising using resources
                            such as those of the Network Advertising Initiative at {' '}
                            <a target='_blank' rel='noopener noreferrer' href='https://www.networkadvertising.org/choices'>www.networkadvertising.org/choices</a>
                            {' '}or the Digital Advertising Alliance at {' '}
                            <a target='_blank' rel='noopener noreferrer' href='https://www.aboutads.info/choices'>www.aboutads.info/choices</a>.
                        </p>

                        <h3>Disclosure in the event of transfer of Services</h3>

                        <p>
                            In the event that the Services are transferred to another organization, information about
                            you, including Personally Identifiable Information, may be transferred or disclosed to LXC’s
                            successor and others involved in the transaction.
                        </p>

                        <h2>Security</h2>

                        <p>
                            The security of your Personally Identifiable Information is important to us and we endeavor
                            to protect Personally Identifiable Information in our possession or control. This includes a
                            variety of privacy and security policies, processes and procedures, including
                            administrative, physical, and technical measures. Nonetheless, no method of collection,
                            storage, or transmission is entirely secure, and we cannot guarantee that our security
                            measures will be effective.
                        </p>

                        <h2>Processing Outside Home Country </h2>

                        <p>
                            LXC is operated and managed on servers based in the United States but it and its partners
                            and service providers use third party service providers such as, for example, Amazon Web
                            Services that may transfer information out of the United States in some situations. Thus, if
                            you choose to use our Services from locations outside the United States, your use of the
                            Services will require the transfer of information, including your Personally Identifiable
                            Information, from your home country to the United States and possibly to other countries
                            whose privacy laws may provide less protection than the laws in your home country. Such
                            transfers are necessary for us to process your registration, if you wish to be a registered
                            user, and to provide you the Services in accordance with our Terms of Service. If you do not
                            wish to have such transfers occur you should not use our Services.
                        </p>

                        <h2>Collection of Data from Children</h2>

                        <p>
                            Our Services are not intended for users under the age of 13. We do not knowingly collect
                            personal data from anyone under 13 years of age. If we become aware that a person under 13
                            years of age has registered we will, to the extent feasible, delete any Personally
                            Identifiable Information of such person from our records.
                        </p>

                        <h2>School Users and Education Records</h2>

                        <p>
                            If you are a student at a school that has entered into an agreement with us to use our
                            Services as part of the school’s educational curriculum, some of the information that we
                            hold may be “Education Records” as that term is used in FERPA. Where we have such a contract
                            with your school, information about your participation in a Class created or administered by
                            your teacher or your school will be Education Records. This includes information such as the
                            results of assessments in that Class or your posts on a discussion board that is specific to
                            that Class. However, if you use our Services outside of a Class sponsored by your teacher or
                            school, information collected by us related to those additional uses will not be Education
                            Records. For example, information related to your connections to a Mentor, or a Class led by
                            someone not affiliated with your school, will not be part of your Education Records.
                        </p>

                        <p>
                            We will use and disclose information from Education Records only as permitted by our
                            contract with your school, this Privacy Notice, and applicable laws, including FERPA.
                            Consistent with the foregoing, we may share information from your Education Records directly
                            with your teacher or school.
                        </p>

                        <p>
                            If you have specific questions about reviewing, modifying, or deleting information from your
                            Education Records, please contact your school directly.
                        </p>

                        <h2>Deletion of Your Account</h2>

                        <p>
                            To delete your account and remove information from the Service, log into your account and
                            select “delete your account” through Settings. When you select “delete your account,” LXC
                            will permanently deactivate your account and remove your profile and the personally
                            identifiable information contained in your profile, subject to the limitations described
                            below. We also will delete other information from our servers, including all posts and
                            direct messages sent by you, though this process will not eliminate all information about
                            you. For example:
                        </p>
                        <ul>
                            <li>
                                Posts and direct messages written by others will not be deleted and may contain your
                                name or other information about you.
                            </li>
                            <li>
                                Your information in activity logs and archives that we maintain will not be deleted
                                until those logs or archives are deleted according to our normal business practices.
                            </li>
                            <li>
                                We will not remove content or information that we may need to comply with our legal
                                obligations, resolve disputes, enforce our agreements, or as otherwise permitted or
                                required by law.
                            </li>
                            <li>
                                Information about you that is not personally identifiable, because it has been
                                aggregated or anonymized, will also remain.
                            </li>
                        </ul>

                        <p>
                            Please also note that deletion of your LXC account will not automatically delete any third
                            party account you might have used to register with LXC or the information it contains (i.e.
                            Google, Facebook, edX, etc.). Regarding deletion of that information, please see that
                            specific site’s privacy policy.
                        </p>

                        <h2>Your California Privacy Rights</h2>

                        <p>
                            If you are a California resident, you may request information about our disclosure of
                            personal information to third parties or affiliated companies for their direct marketing
                            purposes. To obtain this information, please contact us as set forth in the “Contact Us”
                            section. Please include sufficient personal identification information so that we can
                            process the request, including that you are a California resident. Please also note that
                            this information will be provided only once per calendar year.
                        </p>

                        <p>
                            If you are under the age of 18 and a California resident, you are entitled to request
                            removal of content or information you have posted on our Service. You can request deletion
                            of your entire account and remove all of your information on the Service using the
                            procedures described under the heading “Deletion of Your Account” above. Please note that,
                            as explained there, removal of your content or information does not ensure complete or
                            comprehensive removal of all information relating to you.
                        </p>

                        <h2>Changing Our Privacy Notice</h2>

                        <p>
                            We may make changes to this Privacy Notice at any time. We will alert you that we are doing
                            so by giving notice via the Services or by some other means. By registering for, accessing,
                            or using any of the Services after any changes have been made, you signify your agreement to
                            the modified Privacy Notice and all of the changes. Changes made to our Privacy Notice will
                            be effective immediately unless we notify you of a later effective date. If the changes are
                            not acceptable to you, you should discontinue use of the Services.
                        </p>

                        <h2>Contact Us</h2>

                        <p>If you have any questions or requests about this Privacy Notice or our handling of your information, you may contact us at {' '}
                            <a href='mailto:privacy@labxchange.org'>privacy@labxchange.org</a>.
                        </p>
                    </div>
                </div>
            </StandardPageLayout>
        );
    }
}

export const PrivacyPolicyPage = connect<{}, RootState>(
    (state: RootState) => ({}),
)(PrivacyPolicyPageInternal);
