import * as React from 'react';
import { BlockProps } from './models';
import { RichText } from 'elements';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { createAssetViewedEventForItem, shouldAutoTrackAssetView } from './utils';

export class RichBlock extends React.PureComponent<BlockProps, {}> {

    public componentDidMount() {
        if (shouldAutoTrackAssetView(this.props.itemMetadata.id)) {
            createAssetViewedEventForItem(this.props.itemMetadata.id);
        }
    }

    public render() {
        const innerHtml = this.props.blockData.studentViewData.html;
        return (
            <div className='rich-block-content' dir='auto'>
                <RichText
                    innerHtml={innerHtml}
                    sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                    renderMath={true}
                    renderCode={true}
                />
            </div>
        );
    }

}
