import * as React from 'react';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-python.js';
import 'prismjs/components/prism-r.js';
import { sanitizeUnsafeHTML, SanitizeConfigOptions } from 'elements/utils/sanitization';
import { MathJax } from 'better-react-mathjax';

interface RichTextProps {
    innerHtml: string;
    sanitizeConfig?: SanitizeConfigOptions;
    renderMath?: boolean;
    renderCode?: boolean;
}

export const RichText = ({
    innerHtml,
    sanitizeConfig,
    renderMath = false,
    renderCode = false,
}: RichTextProps) => {
    const inlineRef = React.useRef<HTMLDivElement>(null);
    let safeHtml = sanitizeUnsafeHTML(innerHtml, sanitizeConfig, renderMath);

    // Only highlight code if enabled
    if (renderCode) {
        const container = document.createElement('div');
        container.innerHTML = safeHtml;

        Prism.highlightAllUnder(container);
        safeHtml = container.innerHTML;
    }

    if (renderMath) {
        return (
            <MathJax hideUntilTypeset='first'>
                <div dir='auto'
                    ref={inlineRef}
                    dangerouslySetInnerHTML={{
                        __html: safeHtml,
                    }}
                />
            </MathJax>
        );
    }

    return (
        <div dir='auto'
            ref={inlineRef}
            dangerouslySetInnerHTML={{
                __html: safeHtml,
            }}
        />
    );
};


interface PlainTextProps {
    innerHtml: string;
    sanitizeConfig?: SanitizeConfigOptions;
    className?: string;
}

export const PlainText = ({ innerHtml, sanitizeConfig = SanitizeConfigOptions.StripHTML, className }: PlainTextProps) => {
    const safeHtml = sanitizeUnsafeHTML(innerHtml, sanitizeConfig);
    return (
        <span
            className={className}
            dir='auto'
            dangerouslySetInnerHTML={{
                __html: safeHtml,
            }}
        />
    );
};
