import React, { useEffect, useState, useRef, RefObject, createRef } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { intl } from 'i18n';

import {
  Career, CareerQualification, CareerSection as CareerSectionModel,
  CareerSuggestedAssets, CareerSuggestedAssetsAssetTypeEnum,
  CareerCategoryColorGroupEnum, RelatedCareers
} from 'labxchange-client';
import { Spinner } from 'ui/components';
import { PlainText } from 'elements';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { CareersApi } from 'global/api';
import { isKeyboardEnterEvent } from 'global/utils';

import { CareerStandardLayout } from '../CareerStandardLayout';
import { CareerDailyActivity } from '../CareerDailyActivity';
import { CareerSection } from '../CareerSection';
import { AssetCardsSlab } from '../AssetCardsSlab';
import { CareerCard } from '../CareerCard';
import { CareerSectionsEnum, CareerExplorerLogoType } from '../../utils';
import CareerProgressBar from './CareerProgressBar';
import messages from '../../displayMessages';


export const CareerDetails: React.FC = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const careerSlug = pathSegments[pathSegments.length - 1];
  const [loading, setLoading] = useState(true);
  const [career, setCareer] = useState<Career>();
  const [careerSections, setCareerSections] = useState<CareerSectionModel[]>();
  const [careerSuggestedAssets, setCareerSuggestedAssets] = useState<CareerSuggestedAssets[]>();
  const colorGroup = career?.categories?.[0].colorGroup || CareerCategoryColorGroupEnum.One;
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState<string | null>(CareerSectionsEnum.Overview);

  // Refs for scrolling
  const overviewRef = useRef<HTMLDivElement>(null);
  const careerProgressionRef = useRef<HTMLDivElement>(null);
  const dayInLifeRef = useRef<HTMLDivElement>(null);
  const requirementsRef = useRef<HTMLDivElement>(null);
  const whatNextRef = useRef<HTMLDivElement>(null);
  const relatedCareersRef = useRef<HTMLDivElement>(null);
  const [careerSectionsRefs, setCareerSectionsRefs] = useState<RefObject<HTMLDivElement>[]>([]);
  const [suggestedAssetsRefs, setSuggestedAssetsRefs] = useState<RefObject<HTMLDivElement>[]>([]);

  useEffect(() => {
    const handleScroll = () => {
      const isBeyondNavSection = window.scrollY > 351;
      setIsSticky(isBeyondNavSection);

      let newActiveSection = null;

      if (overviewRef.current && window.scrollY < overviewRef.current?.offsetTop) {
        newActiveSection = 'overview';
      } else if (relatedCareersRef.current &&
          window.scrollY > relatedCareersRef.current?.offsetTop +
          (relatedCareersRef.current?.offsetHeight || 0)
        ) {
        newActiveSection = 'relatedCareers';
      } else {
        const sections: {id: string | CareerSectionsEnum, ref: RefObject<HTMLDivElement>}[] = [
          {id: CareerSectionsEnum.Overview, ref: overviewRef},
          {id: CareerSectionsEnum.CareerProgression, ref: careerProgressionRef},
          {id: CareerSectionsEnum.DayInLife, ref: dayInLifeRef},
          {id: CareerSectionsEnum.Requirements, ref: requirementsRef},
          {id: CareerSectionsEnum.WhatNext, ref: whatNextRef},
          {id: CareerSectionsEnum.RelatedCareers, ref: relatedCareersRef},
        ];

        careerSectionsRefs.forEach((ref, index) => {
          sections.push({id: `career-section-${index}`, ref});
        });

        suggestedAssetsRefs.forEach((ref, index) => {
          sections.push({id: `suggested-assets-${index}`, ref});
        });

        sections.forEach(section => {
          const rect = section.ref.current?.getBoundingClientRect();
          if (rect && rect.top <= 5 && rect.bottom >= -5) {
            newActiveSection = section.id;
          }
        });
      }

      if (newActiveSection !== activeSection) {
        setActiveSection(newActiveSection);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeSection]);

  useEffect(() => {
    const fetchCareer = async () => {
      try {
        setLoading(true);
        const response: Career = await CareersApi.read({id: careerSlug});
        setCareer(response);
        if (response.careerSections) {
          setCareerSections(response.careerSections);
          setCareerSectionsRefs(response.careerSections.map(() => createRef<HTMLDivElement>()));
        }
        if (response.careerSuggestedAssets) {
          setCareerSuggestedAssets(response.careerSuggestedAssets);
          setSuggestedAssetsRefs(response.careerSuggestedAssets.map(() => createRef<HTMLDivElement>()));
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchCareer();
  }, [careerSlug]);

  const scrollToSection = (ref: RefObject<HTMLElement>, offset: number = 0) => {
    if (ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const getSideNavLink = (ref: RefObject<HTMLElement>, title: string, id: string) => {
    const offset = id === CareerSectionsEnum.RelatedCareers ? 10 : 5;
    return (
      <span
        key={id}
        tabIndex={0}
        role='button'
        onClick={() => scrollToSection(ref, offset)}
        onKeyDown={(e) => isKeyboardEnterEvent(e) && scrollToSection(ref, offset)}
        className={classNames({'active': activeSection === id}, colorGroup)}
      >
        {title}
      </span>
    );
  };

  const renderSlabs = (slabs: CareerSuggestedAssets[]) => {
    return (
      slabs
      .map((slab: CareerSuggestedAssets, index: number) => {
        if (slab.assetType === CareerSuggestedAssetsAssetTypeEnum.SimpleCards) {
          return (
            <div
              key={index}
              className={classNames('assets-slab', `${colorGroup}-bg`)}
              ref={suggestedAssetsRefs[index]}
            >
              <AssetCardsSlab
                heading={slab.title}
                slab={slab}
              />
            </div>
          );
        }
        return null;
      })
    );
  };

  const heroSection = career && (
    <div className='details-hero-section'>
      <img alt='Career icon' src={career.icon} />
      <div className='career-info'>
        <h2>{career.title}</h2>
        <p>{career.description}</p>
      </div>
    </div>
  );

  if (loading || !career) {
    return (
      <div className='spinner-wrapper full-screen'>
        <Spinner />
      </div>
    );
  }

  return (
    <CareerStandardLayout
      mainClassName='career-details-page'
      pageTitle={messages.careerDetailsPageTitle}
      navClassName={classNames('career-details-nav', `${colorGroup}-bg`)}
      heroSection={heroSection}
      logoType={CareerExplorerLogoType.Navy}
    >
      <>
        <div className={classNames('career-side-nav', {'sticky': isSticky})}>
          <div className='links'>
            {getSideNavLink(
              overviewRef,
              intl.formatMessage(messages.careerSideNavOverview),
              CareerSectionsEnum.Overview
            )}
            {career.careerProgressionImage &&
              getSideNavLink(
                careerProgressionRef,
                intl.formatMessage(messages.careerSideNavProgression),
                CareerSectionsEnum.CareerProgression
              )
            }
            {career.careerDayActivities && career.careerDayActivities.length > 0 &&
              getSideNavLink(
                dayInLifeRef,
                intl.formatMessage(messages.careerSideNavDayInLife),
                CareerSectionsEnum.DayInLife
              )
            }
            {careerSections?.map((section, index) => getSideNavLink(careerSectionsRefs[index], section.title, `career-section-${index}`))}
            {getSideNavLink(
              requirementsRef,
              intl.formatMessage(messages.careerRequirements),
              CareerSectionsEnum.Requirements
            )}
            {career.whatToDoNext &&
              getSideNavLink(
                whatNextRef,
                intl.formatMessage(messages.careerSideNavWhatToDoNext),
                CareerSectionsEnum.WhatNext
              )
            }
            {careerSuggestedAssets?.map((assets, index) => getSideNavLink(suggestedAssetsRefs[index], assets.title, `suggested-assets-${index}`))}
            {career.relatedCareers &&
              getSideNavLink(
                relatedCareersRef,
                intl.formatMessage(messages.careerSideNavRelatedCareers),
                CareerSectionsEnum.RelatedCareers
              )
            }
          </div>
        </div>
        <div className={classNames('career-view', {'sticky': isSticky})}>
          <div className='career-details'>
            <div ref={overviewRef} className={classNames('info-boxes', `${colorGroup}-bg`)}>
              <div className='info-box'>
                <span className='ce-label-small'>{intl.formatMessage(messages.careerMinQualification)}</span>
                <span>{career.minimumQualification}</span>
              </div>
              <div className='info-box'>
                <span className='ce-label-small'>{intl.formatMessage(messages.careerSalaryRange)}</span>
                <span>{`$${career.minSalary} - $${career.maxSalary}`}</span>
              </div>
              <div className='info-box'>
                <span className='ce-label-small'>{intl.formatMessage(messages.careerWorkplace)}</span>
                <span>{career.workplace}</span>
              </div>
            </div>
            {career.careerProgressionImage && (
              <div ref={careerProgressionRef} className='progression-image'>
                <img alt='Career Progression' src={career.careerProgressionImage} />
              </div>
            )}
            {career.careerDayActivities && career.careerDayActivities.length > 0 && (
              <div ref={dayInLifeRef} className='career-activities'>
                  <CareerDailyActivity
                    colorGroup={colorGroup}
                    careerName={career.title}
                    activities={career.careerDayActivities}
                  />
              </div>
            )}
            <div className='career-sections'>
              {careerSections?.map((section: CareerSectionModel, index) => (
                <div ref={careerSectionsRefs[index]} key={index} className='career-section-wrapper'>
                  <CareerSection
                    key={index}
                    title={section.title}
                    description={section.description}
                    featuredImage={section.featuredImage}
                  />
                </div>
              ))}
            </div>
            <div className='requirements-section' ref={requirementsRef}>
              <div className='qualifications'>
                <h4>{intl.formatMessage(messages.careerRequirements)}</h4>
                <h6 className='skills-heading ce-heading-xs'>
                  {intl.formatMessage(messages.careerQualifications)}
                </h6>
                {career.careerQualifications.map((q: CareerQualification, index: number) => (
                  <div key={index} className='qualification'>
                    <span className='qualification-title ce-body-small'>
                      {q.qualification}
                    </span>
                    <CareerProgressBar percentage={parseInt(q.percentage, 10)} colorGroup={colorGroup} />
                  </div>
                ))}
              </div>
              <div className='skills'>
                <h2 className='skills-heading ce-heading-xs'>{intl.formatMessage(messages.careerHardSkills)}</h2>
                <PlainText innerHtml={career.hardSkills} sanitizeConfig={SanitizeConfigOptions.UnsafeExtendedDescHTML} />
                <h2 className='skills-heading ce-heading-xs'>{intl.formatMessage(messages.careerSoftSkills)}</h2>
                <PlainText innerHtml={career.professionalSkills} sanitizeConfig={SanitizeConfigOptions.UnsafeExtendedDescHTML} />
              </div>
            </div>
            <div className='what-next-section' ref={whatNextRef}>
              <h4>{intl.formatMessage(messages.careerWhatToDoNext)}</h4>
              <PlainText innerHtml={career.whatToDoNext} sanitizeConfig={SanitizeConfigOptions.UnsafeExtendedDescHTML} />
            </div>
            {careerSuggestedAssets && renderSlabs(careerSuggestedAssets)}
            <div className='related-careers' ref={relatedCareersRef}>
              <h4 className='rel-careers-heading'>{intl.formatMessage(messages.careerRelatedCareers)}</h4>
              <div className='careers-list'>
                {career.relatedCareers?.map((c: RelatedCareers) =>
                  <CareerCard key={c.slug} career={c} />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </CareerStandardLayout>
  );
};
