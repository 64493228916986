import * as React from 'react';
import { intl } from 'i18n';
import classNames from 'classnames';

import { Icon } from 'ui/components';
import { isKeyboardEnterEvent } from 'global/utils';

import messages from '../../displayMessages';

interface CareerSearchBarProps {
    onSubmit: (keywords: string) => void;
    onBlur?: (value: string) => void;
}

export const CareerSearchBar = (props: CareerSearchBarProps) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = React.useState(false);

    const onSubmit = () => {
        if (inputRef.current) {
            props.onSubmit(inputRef.current.value);
            inputRef.current.value = '';
        }
    };

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur(inputRef.current?.value || '');
        }
        setIsFocused(false);
    };

    return (
        <div
            className={classNames('career-search-bar', { 'focused-view': isFocused })}
            data-testid='career-search-bar'
        >
            <input
                tabIndex={0}
                type='text'
                className='search-input ce-heading-xxs'
                placeholder={isFocused ? '' : intl.formatMessage(messages.careerSearchPlaceholder)}
                aria-label={intl.formatMessage(messages.careerSearchPlaceholder)}
                onKeyDown={(e) => isKeyboardEnterEvent(e) && onSubmit()}
                onBlur={onBlur}
                ref={inputRef}
                onFocus={() => setIsFocused(true)}
            />
            <button
                tabIndex={0}
                onClick={onSubmit}
                className='search-icon'
                data-testid='search-careers-btn'
            >
                <Icon name='search' fill='#003E6B' zoom='1.3em' />
            </button>
        </div>
    );
};
