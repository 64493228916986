import { CareerList, CareerCategoryColorGroupEnum } from 'labxchange-client';


// TODO: Remove this mock data later and set up an api for recommended careers
export const topCareers: CareerList[] = [
  {
      'slug': 'software-developer',
      'title': 'Software Developer',
      'categories': [
          {
              'categoryName': 'Group Four',
              'colorGroup': CareerCategoryColorGroupEnum.One
          },
          {
              'categoryName': 'Group One',
              'colorGroup': CareerCategoryColorGroupEnum.One
          }
      ],
      'minimumQualification': 'Bachelor\'s Degree',
      'minSalary': 60000,
      'maxSalary': 100000,
      'isPublished': true
  },
  {
      'slug': 'data-scientist',
      'title': 'Data Scientist',
      'categories': [
          {
              'categoryName': 'Group Two',
              'colorGroup': CareerCategoryColorGroupEnum.Two
          }
      ],
      'minimumQualification': 'Master\'s Degree',
      'minSalary': 70000,
      'maxSalary': 120000,
      'isPublished': true
  },
  {
      'slug': 'nurse',
      'title': 'Nurse',
      'categories': [
          {
              'categoryName': 'Group Four',
              'colorGroup':  CareerCategoryColorGroupEnum.Three
          }
      ],
      'minimumQualification': 'Bachelor\'s Degree',
      'minSalary': 50000,
      'maxSalary': 80000,
      'isPublished': true
  },
  {
    'slug': 'nurse',
    'title': 'Nurse',
    'categories': [
        {
            'categoryName': 'Group Four',
            'colorGroup': CareerCategoryColorGroupEnum.Four
        }
    ],
    'minimumQualification': 'Bachelor\'s Degree',
    'minSalary': 50000,
    'maxSalary': 80000,
    'isPublished': true
},
{
  'slug': 'nurse',
  'title': 'Nurse',
  'categories': [
      {
          'categoryName': 'Group Four',
          'colorGroup': CareerCategoryColorGroupEnum.Five
      }
  ],
  'minimumQualification': 'Bachelor\'s Degree',
  'minSalary': 50000,
  'maxSalary': 80000,
  'isPublished': true
}
];

export enum CareerSectionsEnum {
    Overview = 'overview',
    CareerProgression = 'careerProgression',
    DayInLife = 'dayInLife',
    Requirements = 'requirements',
    WhatNext = 'whatNext',
    RelatedCareers = 'relatedCareers'
}

export const formatTime24to12 = (time: string) => {
    const [hours, minutes] = time.split(':');
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? 'pm' : 'am';
    const hours12 = ((hoursInt + 11) % 12 + 1);
    return `${hours12}:${minutes} ${suffix}`;
};

export enum CareerExplorerLogoType {
    Default = '/assets/images/career-explorer/career-explorer-logo-white.svg',
    Navy = '/assets/images/career-explorer/career-explorer-logo-navy.svg',
    White = '/assets/images/career-explorer/career-explorer-logo-white.svg',
}
