import * as BannerActions from '../../actions';
import * as React from 'react';
import { Banner } from 'labxchange-client';
import { connect } from 'react-redux';
import { getCookieAcceptanceBanner } from '../../selectors';
import { LXThunkUnwrap } from 'global/types';
import { RootState } from 'global/state';
import { Button } from 'ui/components';
import messages from '../../displayMessages';
import { getLoginCookieAcceptance, getLoggedInStatus } from 'auth/selectors';
import { UsersApi } from 'global/api';
import bind from 'bind-decorator';
import { RichText } from 'elements/components/RichText';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';

interface StateProps {
    banner?: Banner;
    cookieAccepted?: boolean|null;
    isLoggedIn: boolean;
}

interface State {
    hideBanner: boolean;
}

interface ActionProps {
    dismiss: typeof BannerActions.dismissBanner;
}

interface Props extends StateProps, LXThunkUnwrap<ActionProps> {
}

export class BannerCookieAcceptanceInternal extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            hideBanner: false,
        };
    }

    /**
     * If user accepts cookies before login - persist cookie acceptance state
     */
    componentDidUpdate() {
        if (this.props.isLoggedIn && !this.props.cookieAccepted && this.props.cookieAccepted !== undefined) {
            if (this.cookieIsAccepted) {
                UsersApi.acceptCookieAgreement();
            }
        }
    }

    public render() {
        if (this.state.hideBanner || (!this.props.banner || this.cookieIsAccepted)) {
            return null;
        }
        return (
            <div className='banner cookie-acceptance'>
                <div className='container-one text-center'>
                    <div className='dynamic-text'>
                        <RichText
                            innerHtml={this.props.banner.content}
                            sanitizeConfig={SanitizeConfigOptions.UnsafeBannerHTML}
                        />
                    </div>
                    <Button
                        label={messages.acceptCookiePolicy}
                        className='action-button'
                        onClick={this.acceptCookieAgreement}
                    />
                </div>
            </div>
        );
    }

    @bind private acceptCookieAgreement() {
        UsersApi.acceptCookieAgreement()
            .then(() => this.hideCookieAcceptanceModal());
    }

    private hideCookieAcceptanceModal() {
        localStorage.setItem('cookie_accepted', 'true');
        this.setState({ hideBanner: true });
    }

    get cookieIsAccepted() {
        return (
            localStorage.getItem('cookie_accepted') === 'true'
            || this.props.cookieAccepted
        );
    }
}

class BannerCookieAcceptanceContainer extends React.PureComponent<Props> {
    public render() {
        return (
            <BannerCookieAcceptanceInternal
                banner={this.props.banner}
                dismiss={this.props.dismiss}
                cookieAccepted={this.props.cookieAccepted}
                isLoggedIn={this.props.isLoggedIn}
            />
        );
    }
}

export const BannerCookieAcceptance = connect<StateProps, ActionProps, {}, RootState>(
    (state: RootState) => ({
        banner: getCookieAcceptanceBanner(state),
        cookieAccepted: getLoginCookieAcceptance(state),
        isLoggedIn: getLoggedInStatus(state),
    }), {
        dismiss: BannerActions.dismissBanner,
    },
)(BannerCookieAcceptanceContainer);
