import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleEuropeanPersonalData',
        defaultMessage: 'European Personal Data Processing Disclosure',
        description: 'HTML title for the EU personal data page.',
    },
    pageSubTitle: {
        id:'pageSubTitleEuropeanPersonalData',
        defaultMessage: 'Dated: September 19, 2019',
        description: 'Publish date for the EU personal data page.',
    },
});

export default messages;
