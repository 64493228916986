import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import bind from 'bind-decorator';
import { intl } from 'i18n';
import messages from 'clusters/displayMessages';
import libraryMessages from 'library/displayMessages';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { ClustersApi, ItemsApi } from 'global/api';
import { ClusterActionButtons } from './ActionButtons';
import { ClusterGrid } from './Clusters';
import { Funders } from './Funders';
import { Cluster, ItemResponse, RecommendedItems } from 'labxchange-client';
import { RecommendedContentWidget } from 'library/components/RecommendedContentWidget';
import { LibraryBreadcrumb } from 'library/components/Breadcrumb';
import { MetaHeader } from 'library/components/MetaHeader';
import { ItemType } from 'items/models';
import { ContainerOne } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { EmbeddedPageLayout } from 'ui/components/EmbeddedPageLayout';
import { RegistrationGate } from 'ui/components/RegistrationGate';
import { StandardPageLayout } from 'ui/components/StandardPageLayout';
import { ClusterSources } from './Sources';
import { InvalidContent } from 'library/components/InvalidContent';
import { RichText } from 'elements';

interface MatchProps {
    clusterKey: string;
}

interface Props extends RouteComponentProps<MatchProps> {}

interface State {
    cluster?: Cluster;
    similarItems?: ItemResponse[];
    moreBySource?: ItemResponse[];
    errStatus?: number;
    loading?: boolean;
}

class ClusterDynamicInternal extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
        };
        this.loadCluster();
    }

    public async componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.match.params.clusterKey !== prevProps.match.params.clusterKey) {
            this.loadCluster();
        }
    }

    public isFrame() {
        return new URLSearchParams(this.props.location.search).get('iframe');
    }

    public render() {
        const { clusterKey } = this.props.match.params;
        if (this.state.loading) {
            return null;    // TODO
        }
        if (!this.state.cluster) {
            if (this.state.errStatus === 401) {
                /// If is not logged, then show the registration gate page
                return <RegistrationGate
                  title={intl.formatMessage(libraryMessages.uiPrivateAssetAlertTitle)}
                  body={intl.formatMessage(libraryMessages.uiPrivateAssetAlertBody)}
                  primaryButtonText={uiMessages.uiSignUp}
                  secondaryButtonText={uiMessages.uiSignIn}
                  image='/assets/images/access.svg'
                />;
            }
            return <InvalidContent statusCode={this.state.errStatus} />;
        }
        const { cluster } = this.state;

        const clusterGrid = <ClusterGrid
            tours={cluster.tours}
            items={cluster.items}
            clusterMetadataId={cluster.metadata.metadata.id}
        />;

        const wrapperClassNames = 'cluster-wrapper cluster';

        if (this.isFrame()) {
            return (
                <div className={wrapperClassNames}>
                    <EmbeddedPageLayout mainClassName='cluster-view-dynamic'>{clusterGrid}</EmbeddedPageLayout>
                </div>
            );
        }

        const messageValues = {
            title: cluster!.metadata.metadata.title,
        };
        return (
            <div className='cluster-wrapper cluster'>
                <StandardPageLayout
                    pageTitle={messages.dynamicClustersPageTitle}
                    pageTitleValues={messageValues}
                    headerBackgroundUrl={cluster.coverImageUrl}
                    mainClassName='cluster-view-dynamic'
                    ariaLabelledBy='cluster-heading'
                    headerFeature={
                        <div className='cluster-header container'>
                            <div className='breadcrumbs'>
                                <LibraryBreadcrumb
                                    itemType={ItemType.Cluster}
                                    itemTitle={cluster.metadata.metadata.title}
                                />
                            </div>
                        </div>
                    }
                    footerFeature={
                        <div className='cluster-footer'>
                            <Funders
                                funderBackgroundImageUrl={cluster.funderBackgroundImageUrl}
                                funders={this.state.cluster.metadata.metadata.funders}
                            />
                            <div>
                                <RecommendedContentWidget
                                    similarItems={this.state.similarItems!}
                                    moreItemsBySource={this.state.moreBySource!}
                                />
                            </div>
                        </div>
                    }
                >
                    <div className='cluster-info-box'>
                        <ContainerOne>
                            <ClusterActionButtons
                                cluster={cluster}
                                clusterKey={clusterKey}
                            />
                            <div className='cluster-head'>
                                <h1 id='cluster-heading' dir='auto'>{cluster.metadata.metadata.title}</h1>
                                <div className='description'>
                                    <p><RichText
                                        innerHtml={cluster.metadata.metadata.description}
                                        sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                                        renderMath={true}
                                    /></p>
                                    <ClusterSources sources={cluster.sources} />
                                </div>
                            </div>
                            <hr className='divider' />
                            <MetaHeader metadata={cluster.metadata.metadata} />
                        </ContainerOne>
                    </div>
                    {clusterGrid}
                </StandardPageLayout>
            </div>
        );
    }

    private async loadCluster() {
        const { clusterKey } = this.props.match.params;
        let cluster: Cluster;
        try {
            cluster = await ClustersApi.read({id: clusterKey});
            this.setState({ cluster }, () => this.loadRecommendedItems());
        } catch (err) {
            this.setState({errStatus: err.status ? err.status : 500});
        }
        this.setState({loading: false});
    }

    @bind private loadRecommendedItems() {
        if (this.state.cluster === undefined) { return; }

        const { metadata } = this.state.cluster.metadata;
        ItemsApi.recommendedItems({
            id: metadata.id,
            similar: true,
            moreBySource: true,
        }).then((recommendedItems: RecommendedItems) => {
            this.setState({
                similarItems: recommendedItems.similarItems || [],
                moreBySource: recommendedItems.moreBySource || [],
            });
        }).catch((err) => {
            showErrorMessage('Could not fetch recommended items.', {exception: err});
        });
    }
}

export const ClusterDynamic = withRouter(ClusterDynamicInternal);
