import sanitizeHTML from 'sanitize-html';


export enum SanitizeConfigOptions {
    UnsafeHtml = 'unsafeHTML',
    UnsafeBannerHTML = 'unsafeLightHTML',
    UnsafeLightHTML = 'unsageLightHTML',
    UnsafeExtendedDescHTML = 'unsafeExtendedDescHTML',
    UnsafeHTMLAllowedStyles = 'unsafeHTMLAllowedStyles',
    UnsafeHTMLEmbeded = 'unsafeHTMLEmbeded', /// Use this only with trusted sources like youtube
    StripHTML = 'stripHTML',
    UnsafeHTMLReferences = 'unsafeHTMLReferences',
    UnsafeHTMLSimple = 'unsafeHTMLSimple'
}

// These tags are used to represent mathematical equations
// Reference: https://developer.mozilla.org/en-US/docs/Web/MathML/Element/math
// Note: do NOT add any tags not on the following list as
// they are susceptible to mXSS attacks.
// https://github.com/cure53/DOMPurify/blob/a4cfce5/src/tags.js#L227-L277
const MATHML_ALLOWED_TAGS = [
    'math',
    'menclose',
    'merror',
    'mfenced',
    'mfrac',
    'mglyph',
    'mi',
    'mlabeledtr',
    'mmultiscripts',
    'mn',
    'mo',
    'mover',
    'mpadded',
    'mphantom',
    'mroot',
    'mrow',
    'ms',
    'mspace',
    'msqrt',
    'mstyle',
    'msub',
    'msup',
    'msubsup',
    'mtable',
    'mtd',
    'mtext',
    'mtr',
    'munder',
    'munderover',
];

const MATHML_ALLOWED_ATTRIBUTES = {
    'math': ['display'],
};

// These options are designed to allow through enough tags and attributes,
// so that rich text is displayed as expected, but XSS is impossible.
// Be careful updating this lest XSS vulnerabilities be introduced!
// These options are passed to sanitizeHTML in the convenience function below.
// See https://github.com/apostrophecms/sanitize-html for docs.
const COMMON_SANITIZE_OPTIONS = {
    allowedTags: [
        // Sections derived from MDN element categories and limited to the more
        // benign categories.
        // https://developer.mozilla.org/en-US/docs/Web/HTML/Element
        // Content sectioning
        'address', 'article', 'aside', 'footer', 'header',
        'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hgroup',
        'main', 'nav', 'section',
        // Text content
        'blockquote', 'dd', 'div', 'dl', 'dt', 'figcaption', 'figure',
        'hr', 'li', 'main', 'ol', 'p', 'pre', 'ul',
        // Inline text semantics
        'a', 'abbr', 'b', 'bdi', 'bdo', 'br', 'cite', 'code', 'data', 'dfn',
        'em', 'i', 'kbd', 'mark', 'q',
        'rb', 'rp', 'rt', 'rtc', 'ruby',
        's', 'samp', 'small', 'span', 'strong', 'strike', 'sub', 'sup', 'time', 'u', 'var', 'wbr',
        // Table content
        'caption', 'col', 'colgroup', 'table', 'tbody', 'td', 'tfoot', 'th',
        'thead', 'tr',
        // Images
        'img',
    ],
    disallowedTagsMode: 'discard' as 'discard',
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [ 'img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta' ],
    // URL schemes we permit
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto', 'tel' ],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [ 'href', 'src', 'cite' ],
    allowProtocolRelative: true,
    enforceHtmlBoundary: false,
};


const STYLE_ALLOWED_ATTRIBUTES = {
    a: [ 'href', 'name', 'target', 'id' ],
    img: [ 'src', 'alt', 'width', 'height', 'style'],
    figure: ['style', 'class'],
    div: ['style', 'class', 'dir'],
    table: ['style', 'border', 'cellspacing', 'cellpadding'],
    th: ['style', 'colspan', 'rowspan'],
    td: ['style', 'colspan', 'rowspan'],
    pre: ['class'],
    p: ['style', 'align', 'class'],
    span: ['style', 'class'],
    ol: ['start', 'style'], // we need 'style' to render advance list styles e.g lower-alpha, upper-alpha
    // To allow anchor point to headers
    h1: ['id', 'style'],
    h2: ['id', 'style'],
    h3: ['id', 'style'],
    h4: ['id', 'style'],
    h5: ['id', 'style'],
    h6: ['id', 'style'],
};

const EXTENDED_STYLE_ALLOWED_SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedAttributes: STYLE_ALLOWED_ATTRIBUTES,
};

const BASIC_ALLOWED_ATTRIBUTES = {
    a: [ 'href', 'name', 'target' ],
    // We don't currently allow img itself by default, but this
    // would make sense if we did. You could add srcset here,
    // and if you do the URL is checked for safety
    img: [ 'src', 'alt'],
};

const SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedAttributes: BASIC_ALLOWED_ATTRIBUTES,
};

const STYLE_ALLOWED_SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedAttributes: STYLE_ALLOWED_ATTRIBUTES,
};

// These options are designed to strip all html.
// Use if you want to convert html to plain text.
const STRIP_OPTIONS = {
    allowedTags: [],
    disallowedTagsMode: 'discard' as 'discard',
    allowedAttributes: {},
};

const EXTENDED_DESCRIPTION_SANITIZE_OPTIONS = {
    ...EXTENDED_STYLE_ALLOWED_SANITIZE_OPTIONS,
    allowedSchemes: [ 'http', 'https', 'ftp', 'mailto', 'tel', 'data' ],
};

const BANNER_SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedTags: ['div', 'a'],
    allowedAttributes: {
        a: ['href', 'target'],
    },
};

const LIGHT_SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedTags: ['div', 'a', 'p', 'strong', 'em'],
    allowedAttributes: {
        a: ['href', 'target'],
        p: ['dir'],
    },
};

const REFERENCES_SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedTags: ['strong', 'b', 'em', 'i', 'sup', 'sub', 'ul', 'ol', 'li', 'div', 'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    allowedAttributes: {
        a: ['href', 'target'],
    },
};

const SIMPLE_SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedTags: ['strong', 'b', 'em', 'i', 'sup', 'sub', 'ul', 'ol', 'li', 'div', 'a', 'p'],
    allowedAttributes: {
        a: ['href', 'target'],
    },
};

const EMBEDED_SANITIZE_OPTIONS = {
    ...COMMON_SANITIZE_OPTIONS,
    allowedTags: ['iframe'],
    allowedAttributes: {
        iframe: ['width', 'height', 'src', 'frameborder', 'allow', 'allowfullscreen']
    },
};

/**
 * Helper function that allows passing a string + sanitizeConfig to strip unsafe code.
 *
 * This function takes an untrusted html string,
 * and returns a sanitized version of it which is safe for html display.
 * See the options above for exactly what is allowed through the filter.
 * Use this whenever displaying rich text / html marked up text in the frontend,
 * to avoid XSS vulnerabilities.
 * The output of this function, regardless of the input, should be
 * safe to pass to dangerouslySetInnerHTML.
 *
 * @param unsafeHtml string that needs to be sanitized
 * @param sanitizeConfig enum item from SanitizeConfigOptions to select
 *                       which kind of sanitization will be done.
 * @param renderMathML boolean set if MathML tags/attributes should be allowed.
 * @returns sanitized string.
 */
export const sanitizeUnsafeHTML = (unsafeHtml: string, sanitizeConfig?: SanitizeConfigOptions, renderMathML=false) => {
    let sanitizeOptions:any = SANITIZE_OPTIONS;
    switch (sanitizeConfig) {
        case SanitizeConfigOptions.UnsafeBannerHTML:
            sanitizeOptions = BANNER_SANITIZE_OPTIONS;
            break;
        case SanitizeConfigOptions.UnsafeLightHTML:
            sanitizeOptions = LIGHT_SANITIZE_OPTIONS;
            break;
        case SanitizeConfigOptions.UnsafeHTMLAllowedStyles:
            sanitizeOptions = STYLE_ALLOWED_SANITIZE_OPTIONS;
            break;
        case SanitizeConfigOptions.UnsafeExtendedDescHTML:
            sanitizeOptions = EXTENDED_DESCRIPTION_SANITIZE_OPTIONS;
            break;
        case SanitizeConfigOptions.StripHTML:
            sanitizeOptions = STRIP_OPTIONS;
            break;
        case SanitizeConfigOptions.UnsafeHTMLEmbeded:
            sanitizeOptions = EMBEDED_SANITIZE_OPTIONS;
            break;
        case SanitizeConfigOptions.UnsafeHTMLReferences:
            sanitizeOptions = REFERENCES_SANITIZE_OPTIONS;
            break;
        case SanitizeConfigOptions.UnsafeHTMLSimple:
            sanitizeOptions = SIMPLE_SANITIZE_OPTIONS;
            break;
    }


    if (renderMathML) {
        sanitizeOptions.allowedTags = sanitizeOptions.allowedTags.concat(MATHML_ALLOWED_TAGS);
        sanitizeOptions.allowedAttributes = {
            ...sanitizeOptions.allowedAttributes,
            ...MATHML_ALLOWED_ATTRIBUTES,
        };
    }

    return sanitizeHTML(unsafeHtml, sanitizeOptions);
};
