// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerCategory,
    CareerCategoryFromJSON,
    CareerCategoryToJSON,
    CareerDayActivity,
    CareerDayActivityFromJSON,
    CareerDayActivityToJSON,
    CareerMentor,
    CareerMentorFromJSON,
    CareerMentorToJSON,
    CareerQualification,
    CareerQualificationFromJSON,
    CareerQualificationToJSON,
    CareerSection,
    CareerSectionFromJSON,
    CareerSectionToJSON,
    CareerSuggestedAssets,
    CareerSuggestedAssetsFromJSON,
    CareerSuggestedAssetsToJSON,
    RelatedCareers,
    RelatedCareersFromJSON,
    RelatedCareersToJSON,
} from './';

/**
 * 
 * @export
 * @interface Career
 */
export interface Career {
    /**
     * A unique human-readable name, e.g., 'ent-specialist'
     * @type {string}
     * @memberof Career
     */
    slug: string;
    /**
     * Get related careers for a career.
     * @type {Array<RelatedCareers>}
     * @memberof Career
     */
    readonly relatedCareers?: Array<RelatedCareers>;
    /**
     * Get categories associated with a career.
     * @type {Array<CareerCategory>}
     * @memberof Career
     */
    readonly categories?: Array<CareerCategory>;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    title: string;
    /**
     * SVG icon for the career.
     * @type {string}
     * @memberof Career
     */
    readonly icon?: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    workplace?: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    subheading: string;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    minSalary: number;
    /**
     * 
     * @type {number}
     * @memberof Career
     */
    maxSalary: number;
    /**
     * Image showing career progression.
     * @type {string}
     * @memberof Career
     */
    readonly careerProgressionImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    description: string;
    /**
     * HTML formatted text describing hard skills.
     * @type {string}
     * @memberof Career
     */
    hardSkills: string;
    /**
     * HTML formatted text describing professional skills.
     * @type {string}
     * @memberof Career
     */
    professionalSkills: string;
    /**
     * 
     * @type {string}
     * @memberof Career
     */
    minimumQualification: string;
    /**
     * 
     * @type {Array<CareerQualification>}
     * @memberof Career
     */
    careerQualifications: Array<CareerQualification>;
    /**
     * HTML formatted text guiding what to do next for the career.
     * @type {string}
     * @memberof Career
     */
    whatToDoNext: string;
    /**
     * Get mentors associated with a career.
     * @type {Array<CareerMentor>}
     * @memberof Career
     */
    readonly mentors?: Array<CareerMentor>;
    /**
     * Get sorted career sections for a career.
     * @type {Array<CareerSection>}
     * @memberof Career
     */
    readonly careerSections?: Array<CareerSection>;
    /**
     * Get sorted career day activities for a career.
     * @type {Array<CareerDayActivity>}
     * @memberof Career
     */
    readonly careerDayActivities?: Array<CareerDayActivity>;
    /**
     * Get sorted career suggested assets for a career.
     * @type {Array<CareerSuggestedAssets>}
     * @memberof Career
     */
    readonly careerSuggestedAssets?: Array<CareerSuggestedAssets>;
    /**
     * Whether the career is published or not.
     * @type {boolean}
     * @memberof Career
     */
    isPublished?: boolean;
}

export function CareerFromJSON(json: any): Career {
    return {
        'slug': json['slug'],
        'relatedCareers': !exists(json, 'related_careers') ? undefined : (json['related_careers'] as Array<any>).map(RelatedCareersFromJSON),
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] as Array<any>).map(CareerCategoryFromJSON),
        'title': json['title'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'workplace': !exists(json, 'workplace') ? undefined : json['workplace'],
        'subheading': json['subheading'],
        'minSalary': json['min_salary'],
        'maxSalary': json['max_salary'],
        'careerProgressionImage': !exists(json, 'career_progression_image') ? undefined : json['career_progression_image'],
        'description': json['description'],
        'hardSkills': json['hard_skills'],
        'professionalSkills': json['professional_skills'],
        'minimumQualification': json['minimum_qualification'],
        'careerQualifications': (json['career_qualifications'] as Array<any>).map(CareerQualificationFromJSON),
        'whatToDoNext': json['what_to_do_next'],
        'mentors': !exists(json, 'mentors') ? undefined : (json['mentors'] as Array<any>).map(CareerMentorFromJSON),
        'careerSections': !exists(json, 'career_sections') ? undefined : (json['career_sections'] as Array<any>).map(CareerSectionFromJSON),
        'careerDayActivities': !exists(json, 'career_day_activities') ? undefined : (json['career_day_activities'] as Array<any>).map(CareerDayActivityFromJSON),
        'careerSuggestedAssets': !exists(json, 'career_suggested_assets') ? undefined : (json['career_suggested_assets'] as Array<any>).map(CareerSuggestedAssetsFromJSON),
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
    };
}

export function CareerToJSON(value?: Career): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'title': value.title,
        'workplace': value.workplace,
        'subheading': value.subheading,
        'min_salary': value.minSalary,
        'max_salary': value.maxSalary,
        'description': value.description,
        'hard_skills': value.hardSkills,
        'professional_skills': value.professionalSkills,
        'minimum_qualification': value.minimumQualification,
        'career_qualifications': (value.careerQualifications as Array<any>).map(CareerQualificationToJSON),
        'what_to_do_next': value.whatToDoNext,
        'is_published': value.isPublished,
    };
}


