import * as React from 'react';
import { UsersApi } from 'global/api';
import { API, LxConfig, ROUTES } from 'global/constants';
import { history } from 'global/history';
import { LXThunkAction, LXThunkDispatch } from 'global/types';
import { APIPermissions } from 'labxchange-client/models/APIPermissions';
import { checkOnboardingStatus } from 'middleware/onboarder';
import { Action, Dispatch } from 'redux';
import { authLocalStorageManager, AuthProviderEnum, isLxLoginActiveOnDebug, isLxLoginActiveOnProd } from './utils';
import { getSavedLanguage, setLanguageCookie } from 'i18n';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import uiMessages from 'ui/components/displayMessages';
import { deleteCookie, getCookie, WrappedMessage } from 'utils';
import { showLoginModal } from './components/LxLoginView/dispatch';
import messages from './components/displayMessages';
import { ActivateEmailDismissCookie } from '../banners/actions';

export enum Types {
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
}

export interface LoginSuccess extends Action {
    readonly type: Types.LOGIN_SUCCESS;
    readonly email: string;
    readonly token: string;
    readonly name: string;
    readonly username: string;
    readonly avatarUrl: string;
    readonly permissions: APIPermissions;
    readonly onboardingStatus: string;
    readonly dateOfBirth: string;
    readonly role: string;
    readonly acceptedCookieAgreement: boolean | undefined;
    readonly hasVerifiedEmail: boolean;
    readonly needsAuthMigration: boolean;
    readonly hasClass: boolean;
    readonly curriculumGroup: string | undefined;
}

export interface LoginFailure extends Action {
    readonly type: Types.LOGIN_FAILURE;
    readonly errorMessage: string;
}

export interface LogoutSuccess extends Action {
    readonly type: Types.LOGOUT_SUCCESS;
}

export type ActionTypes =
    | LoginSuccess
    | LoginFailure
    | LogoutSuccess;

/**
 * Asynchronously verify whether or not the recent login attempt succeeded,
 * or whether the login status set by getInitialLoginState() was correct.
 *
 * This method calls an API to confirm whether or not the user is logged in,
 * and gets basic information (username & name) about the logged in user.
 */
export const checkLoginStatus = async (dispatch: LXThunkDispatch) => {
    const token = localStorage.getItem('auth_token');
    if (token === null) {
        // Without an auth token, the user is definitely not logged in.
        markUserAsLoggedOut(dispatch);
        return;
    }
    // Check if the auth token is valid:
    let status;
    try {
        status = await UsersApi.loginStatus();
    } catch (err) {
        if (err.status === 401) {
            // The user is blocked.
            // The middleware has already dispatched markUserAsLoggedOut.
            const email = (await err.json()).email;
            history.push(ROUTES.General.DENIED_BLOCKED, { email });
            return;
        } else if (err.status === 451) {
            // The user is on an embargoed region
            markUserAsLoggedOut(dispatch);
            history.push(ROUTES.General.DENIED_COUNTRY);
            return;
        } else {
            // Otherwise, there was some other unexpected non-auth related error,
            // so we should at least warn about it.
            // Do not mark the user as logged out, because the error here is not likely related to auth.
            showErrorMessage(
                <WrappedMessage message={uiMessages.loginStatusError} />,
                {
                    exception: err,
                    confirmText: uiMessages.uiOk,
                },
            );
            return;
        }
    }

    if (status.language && status.language !== getSavedLanguage()) {
        setLanguageCookie(status.language);
        window.location.reload();
        return;
    }

    authLocalStorageManager.name = status.name;
    authLocalStorageManager.email = status.email;
    authLocalStorageManager.username = status.username;
    authLocalStorageManager.onboardingStatus = status.onboardingStatus;
    authLocalStorageManager.role = status.role;
    authLocalStorageManager.avatarUrl = status.avatarUrl;
    authLocalStorageManager.hasVerifiedEmail = status.hasVerifiedEmail;
    authLocalStorageManager.needsAuthMigration = status.needsAuthMigration;
    dispatch({
        name: status.name,
        email: status.email,
        token,
        type: Types.LOGIN_SUCCESS,
        username: status.username,
        avatarUrl: status.avatarUrl,
        permissions: status.permissions,
        onboardingStatus: status.onboardingStatus,
        dateOfBirth: status.dateOfBirth,
        role: status.role,
        acceptedCookieAgreement: status.acceptedCookieAgreement,
        hasVerifiedEmail: status.hasVerifiedEmail,
        needsAuthMigration: status.needsAuthMigration,
        hasClass: status.hasClass,
        curriculumGroup: status.curriculumGroup,
    });

    checkOnboardingStatus();
};

export const redirectToLxSignUp = (): void => {
    if (isLxLoginActiveOnDebug()) {
        if (window.location.pathname !== ROUTES.General.LX_SIGN_UP) {
            authLocalStorageManager.authRedirectToLocation = window.location;
            // To avoid an infinite series of redirects in the event of an auth_token
            // that is present but expired, clear it just in case:
            localStorage.removeItem('auth_token');
            // Now redirect to the login page:
            history.push(ROUTES.General.LX_SIGN_UP);
        }
    }
    else {
        if (window.location.pathname !== ROUTES.General.SIGN_UP) {
            authLocalStorageManager.authRedirectToLocation = window.location;
            // To avoid an infinite series of redirects in the event of an auth_token
            // that is present but expired, clear it just in case:
            localStorage.removeItem('auth_token');
            // Now redirect to the login page:
            history.push(ROUTES.General.SIGN_UP);
        }
    }
};

/** Programatic way to redirect to login. You can also use <RedirectToLogin/> */
export const redirectToLogin = (): void => {
    if (isLxLoginActiveOnProd()) {
        authLocalStorageManager.authRedirectToLocation = window.location;
        showLoginModal();
        return;
    }

    if (window.location.pathname !== ROUTES.General.SIGN_IN) {
        authLocalStorageManager.authRedirectToLocation = window.location;
        // To avoid an infinite series of redirects in the event of an auth_token
        // that is present but expired, clear it just in case:
        localStorage.removeItem('auth_token');
        // Now redirect to the login page:
        history.push(ROUTES.General.SIGN_IN);
    }
};

export const redirectToLxLogin = (): void => {
    if (isLxLoginActiveOnDebug()) {
        if (window.location.pathname !== ROUTES.General.LX_SIGN_IN) {
            authLocalStorageManager.authRedirectToLocation = window.location;
            // To avoid an infinite series of redirects in the event of an auth_token
            // that is present but expired, clear it just in case:
            localStorage.removeItem('auth_token');
            // Now redirect to the login page:
            history.push(ROUTES.General.LX_SIGN_IN);
        }
    }
    else {
        if (window.location.pathname !== ROUTES.General.SIGN_IN) {
            authLocalStorageManager.authRedirectToLocation = window.location;
            // To avoid an infinite series of redirects in the event of an auth_token
            // that is present but expired, clear it just in case:
            localStorage.removeItem('auth_token');
            // Now redirect to the login page:
            history.push(ROUTES.General.SIGN_IN);
        }
    }
};

export const redirectToResetPasswordPage = (email?: string): void => {
    history.push(ROUTES.General.RESET_PASSWORD_EMAIL, { email });
};

export const login = (): LXThunkAction<void> => {
    const authUrl = new URL(API.Auth.OAUTH2_AUTHORIZE);
    authUrl.searchParams.set('client_id', LxConfig.Oauth2ClientId);
    authUrl.searchParams.set('response_type', 'token');

    if(getCookie(ActivateEmailDismissCookie) === '1') {
        deleteCookie(ActivateEmailDismissCookie, '1');
    }

    return async (dispatch, getState) => {
        window.location.href = authUrl.toString();
    };
};

export const loginWithProvider = (provider: AuthProviderEnum): void => {
    // We redirect to /oatuh/authorize to complete the login.
    // We encode this url to avoid errors with the params.
    // Don't use this with `email` provider.

    const next = `${API.Auth.OATUH2_AUTHORIZE_ENDPOINT}?client_id=${LxConfig.Oauth2ClientId}&response_type=token`;
    const encodedNext = encodeURIComponent(next);

    // Redirect to google endpoint
    window.location.href = `${LxConfig.BackendBaseUrl}/accounts/${provider}/login?next=${encodedNext}`;
};

export const loginWithEmail = async (email: string, password: string) => {

    /// Build data for request
    const formData  = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    try {
        /// This response login the user on django and returns
        /// a `Set-Cookie` header to set the `labxchange_sessionid` cookie.
        ///
        /// By default the `Set-Cookie` doesn't works. It is nedeed the `credentials`
        /// param for it to work and save the cookie.
        /// Ref: https://stackoverflow.com/a/46481100
        return await fetch(`${LxConfig.BackendBaseUrl}/api/v1/users/login/`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
        });
    }
    catch {
        /// When the servers returns an 500 error the frontend generates a CORS error
        /// because the backend does not send the Access-Control-Allow-Credentials header
        /// when unexpected errors occur
        showErrorMessage(<WrappedMessage message={messages.lxAuthUnableToLogin} />);
        return;
    }
};

export const loginFailure = (message: string) => (dispatch: Dispatch) => {
    dispatch({ type: Types.LOGIN_FAILURE, errorMessage: message });
    localStorage.removeItem('auth_token');
};

/**
 * Internal helper to mark the user as logged out.
 * This should not be used directly, but only via
 * logout() or checkLoginStatus()
 */
export const markUserAsLoggedOut = (dispatch: LXThunkDispatch) => {
    dispatch({ type: Types.LOGOUT_SUCCESS });
    clearAuthState();
};

const clearAuthState = () => {
    authLocalStorageManager.authToken = null;
    authLocalStorageManager.name = null;
    authLocalStorageManager.username = null;
    authLocalStorageManager.onboardingStatus = null;
    authLocalStorageManager.userBirthdate = null;
    authLocalStorageManager.hasVerifiedEmail = false;
    authLocalStorageManager.needsAuthMigration = false;
    authLocalStorageManager.role = null;
    authLocalStorageManager.viewedEmailVerificationStep = false;
};

export const logout = (redirectTo = API.Auth.LOGOUT) => {
    clearAuthState();
    window.location.href = redirectTo;
};
