import { defineMessages } from 'react-intl';

const messages = defineMessages({
    curriculumsPageHeaderTitle: {
        id:'curriculumsPageHeaderTitle',
        defaultMessage: 'Browse by curriculum',
        description: 'Header title for the curriculums list page',
    },
    curriculumsPageHeaderPill: {
        id:'curriculumsPageHeaderPill',
        defaultMessage: 'New!',
        description: 'Text for the header pill message',
    },
    curriculumsPageLoadingError: {
        id:'curriculumsPageLoadingError',
        defaultMessage: 'Encountered an error while loading curriculums',
        description: 'Error message when loading curriculums fails',
    },
    curriculumsPageSectionTitle: {
        id:'curriculumsPageSectionTitle',
        defaultMessage: 'Currently available curricula',
        description: 'Section title for listed curriculums on the page',
    },
    curriculumsPageSectionParagraph: {
        id:'curriculumsPageSectionParagraph',
        defaultMessage: 'Explore a diverse array of resources meticulously designed to meet the standards and objectives of your region\'s specific educational framework.',
        description: 'Section title for listed curriculums on the page',
    },
    curriculumsPageCardButtonText: {
        id:'curriculumsPageCardButtonText',
        defaultMessage: 'Explore curriculum',
        description: 'Button text for the curriculum card on curriclums list page',
    },
    curriculumsPageComingSoonText: {
        id:'curriculumsPageComingSoonText',
        defaultMessage: 'Coming soon!',
        description: 'Text for the coming soon section title on the curriculums page',
    },
    curriculumsPageRequestTitle: {
        id:'curriculumsPageRequestTitle',
        defaultMessage: 'Don\'t see your curriculum?',
        description: 'Text for the request curriculum section title on the curriculums page',
    },
    curriculumsPageRequestParagraphText: {
        id:'curriculumsPageRequestParagraphText',
        defaultMessage: 'Share your feedback and submit a request to add your country\'s curriculum to LabXchange.',
        description: 'Text for the request curriculum paragraph on the curriculums page',
    },
    curriculumsPageRequestButtonText: {
        id:'curriculumsPageRequestButtonText',
        defaultMessage: 'Request your curriculum',
        description: 'Text for the request curriculum button on the curriculums page',
    },
    curriculumTopicPageSidebarError: {
        id:'curriculumTopicPageSidebarError',
        defaultMessage: 'Unable to load data for the provided curriculum.',
        description: 'Error message for sidebar of curriculum topic page',
    },
    curriculumTopicPageSidebarOpenBtnText: {
        id:'curriculumTopicPageSidebarOpenBtnText',
        defaultMessage: 'Curriculum',
        description: 'Text for the sidebar open button',
    },
    curriculumModuleOverviewLink: {
        id:'curriculumModuleOverviewLink',
        defaultMessage: 'Overview',
        description: 'Link text for multiple curriculum learning objectives parent',
    },
    curriculumPageTitle: {
        id:'curriculumPageTitle',
        defaultMessage: '{name} - Curriculum overview',
        description: 'Heading for curriculum overview page',
    },
    learningObjectivesTotalCount: {
        id:'learningObjectivesTotalCount',
        defaultMessage: '{count} in total',
        description: 'Count tag for learning objectives',
    },
    learningObjectivesCountOnly: {
        id:'learningObjectivesCountOnly',
        defaultMessage: '{count}',
        description: 'Count tag for learning objectives',
    },
    learningObjectivesShowMore: {
        id:'learningObjectivesShowMore',
        defaultMessage: 'Show more',
        description: 'Text for the show more button',
    },
    learningObjectivesShowLess: {
        id:'learningObjectivesShowLess',
        defaultMessage: 'Show less',
        description: 'Text for the show less button',
    },
});

export default messages;
