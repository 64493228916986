import * as React from 'react';
import { intl } from 'i18n';

import { CareerExplorerLogoType } from '../../utils';
import messages from '../../displayMessages';

interface CareerExplorerLogoProps {
    width: number;
    height: number;
    logoType?: CareerExplorerLogoType;
}

export const CareerExplorerLogo: React.FC<CareerExplorerLogoProps> = ({
  width, height, logoType = CareerExplorerLogoType.White
}) => (
    <img
      className='ce-logo'
      src={logoType}
      width={width}
      height={height}
      alt={intl.formatMessage(messages.careerUiHeaderNavBarBrand)}
      title={intl.formatMessage(messages.careerUiHeaderNavBarBrand)}
    />
);
