import { RootState } from 'global/state';
import * as React from 'react';
import { connect } from 'react-redux';
import { StandardPageLayout } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

// tslint:disable:max-line-length

export class TermsOfServicePageInternal extends React.PureComponent<{}, {}> {

    public render() {
        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                headerFeature={
                    <div>
                        <h1 className='legal-header-title'>
                            <WrappedMessage message={messages.pageTitle}/></h1>
                        <p className='legal-header-sub-title'>
                            <WrappedMessage message={messages.pageSubTitle}/></p>
                    </div>
                }
            >
                <div className='legal-page'>
                    <div className='legal-content'>
                        <h2>1. Introduction</h2>

                        <p>Welcome to LabXchange (“<strong>LXC</strong>”). Please read these Terms of Service (the
                            “<strong>TOS</strong>”) before registering for, accessing, or using any portion of the LXC
                            website located at www.labxchange.org (the “<strong>Site</strong>”) or any other websites,
                            applications, programs, or online products or services of LXC (collectively, the
                            “<strong>Services</strong>”). The term “Services” includes the Site and all content and
                            pages located within and accessible via the Site, as well as any functions, platforms,
                            forums, directories, and other electronic services available via the Site, as they may be
                            modified over time. </p>

                        <p>These TOS constitute an agreement between you and
                            the President and Fellows of Harvard College (“<strong>Harvard University</strong>”), acting
                            through LXC. <strong>By seeking to register for, accessing, or using any Services, you
                                accept and agree to be legally bound by the Agreements, whether or not you are a
                                registered user or have an account. If you do not understand or do not wish to be bound
                                by these TOS, do not register for, access, or use any
                                Services.</strong></p>

                        <p>We may make changes to these TOS at any time, but will alert you that we are doing so by
                            giving notice via the Services or by some other means. By seeking to register, access, or
                            use any Services after any changes have been made, you signify your agreement to the
                            modified TOS and all of the changes, which will be effective immediately unless we
                            notify you of a later effective date. If the changes are not acceptable to you, you should
                            discontinue use of the Services.</p>

                        <p>As used in these TOS, “we,” “us,” “our” and “LXC” refer to the President and Fellows of
                            Harvard College acting through LXC. The terms “you,” “your” and “yours” refer to you as the
                            individual or organization accessing or using the Services. “User” means anyone accessing
                            the Site, including both unregistered and registered users.</p>

                        <h2>2. User Accounts and User Registration</h2>

                        <p>In order to participate in most Services you must become a registered user (a “<strong>Registered
                            User</strong>”) of the Site. To become a Registered User you must create an LXC user account
                            (a “<strong>User Account</strong>”). You may create an account with LXC directly or LXC may
                            permit you to apply for registration through third-party sites or services, such as Google,
                            Facebook, or edX (collectively “Third-Party Sites”). By registering for LXC using a
                            Third-Party Site, you agree that LXC may access your Third-Party Site’s account information.
                            LXC does not control the practices of Third-Party Sites and you should carefully review the
                            Third-Party Sites’ privacy policies and other conditions of use.</p>

                        <p>
                            To create a User Account, you will need to complete the registration form provided on the
                            Site and establish login credentials  (“<strong>Login Credentials</strong>”) for your User Account. LXC may
                            request additional information as needed to determine your eligibility to be a Registered
                            User.. Creation of a User Account and use of certain functionality (such as the ability to
                            post content that will be publicly available) are subject to LXC’s approval in its
                            discretion. LXC may revoke your User Registration and disable your Login Credentials if LXC
                            determines, in its discretion, that you have breached these TOS.

                        </p>

                        <p>
                            You agree that you will never divulge or share access to information regarding your User
                            Account or Login Credentials with any third party for any reason. You also agree that you
                            will create, use, and/or access only one User Account, and that you will not access the Site
                            using any User Account or Login Credentials other than your own.
                        </p>

                        <p>
                            You agree to notify us immediately upon becoming aware of or suspecting any unauthorized use
                            of your User Account or Login Credentials. We will not be responsible for any loss or damage
                            arising from unauthorized access to your User Account or Login Credentials.</p>

                        <p>
                            In applying for a User Account, you may be prompted or required to enter additional
                            information, such as your name and location. Additional information may be required to
                            confirm your identity, and different or additional information may be required depending on
                            the type of account or user role you seek. You warrant that all such information provided by
                            you is accurate, current, complete, and complies with your obligations under these TOS.
                            You also agree to maintain and update such information to keep it accurate, current and
                            complete. You acknowledge that if any such information provided by you is untrue,
                            inaccurate, not current or incomplete, we reserve the right to terminate your use of the
                            Services.
                        </p>

                        <p>
                            You acknowledge and agree that, as a Registered User, personally identifiable information about
                            you will be available for others to view via certain public-facing pages and other Services
                            on LXC. These Services include Services permitting you to communicate with other Registered
                            Users and other Registered Users to communicate with you via message boards, discussion
                            forums and other Services provided by LXC.
                        </p>

                        <p>You acknowledge that LXC may choose, but is not obligated, to make any inquiries, either
                            directly or through third parties, that LXC deems necessary to validate your registration
                            information, including, without limitation, engaging third parties to provide verification
                            services. LXC reserves all rights to take legal action against anyone who misrepresents
                            personal information or is otherwise untruthful about their identity.</p>

                        <h2>3. Eligibility</h2>

                        <p>The LXC Services are intended only for organizations and for persons 13 years of age or
                            older. By using, visiting or registering for the Site or Services you represent and warrant
                            to LXC that you are at least 13 years old.</p>

                        <p>
                            Individuals under 18 years of age but 13 years of age or older (“<strong>Junior
                            Users</strong>”) may be able to use parts of the Services and register for a User Account at
                            our discretion. Junior Users may not create classes on LXC, contribute content that will be
                            publicly available, or use other functionality of the Services that we do not authorize for
                            Junior Users. We may impose additional requirements for registration or access by Junior
                            Users.
                        </p>

                        <p>
                            Regardless of your age, you are only eligible to use the Services and create a User Account
                            if doing so will not violate any applicable law or regulation.
                        </p>

                        <p>
                            Those who wish to register and use the Services must meet any other minimum requirements set
                            forth in these TOS. If you do not qualify, you may not use the Services.
                        </p>

                        <h2>4. User Content; License Grant; Representations and Warranties</h2>

                        <p>Text, images, simulations, video, audio, audiovisual, graphical, interactive and other kinds
                            of content and files, along with information about the content that will aid its use and
                            discovery on the Site (collectively, “<strong>Resources</strong>”) are available from
                            various sources, including Users, for use in the Services. The Services also include
                            comments, responses to assessments, notes, likes, feedback, and other kinds of content
                            posted or provided by Users in connection with the Services (collectively,
                            “<strong>Comments</strong>” and, together with Resources created or provided by Users,
                            “<strong>User Content</strong>”).</p>

                        <p>Depending on your User status, you may be able to upload or otherwise provide User Content.
                            The types of Resources that can be provided, or created using the Services, are explained on
                            the Site. In some cases, Resources may consist of embed codes or links to your own content
                            hosted by a third party. However, you may only provide embed codes or links to your own
                            content hosted on those third party services that we have approved for this purpose and have
                            identified as <a href='https://labxchange.zendesk.com/hc/en-us/articles/15439162233367-Approved-Third-Party-Services'>Approved Third Party Services</a> on
                            the Site (each, an “<strong>Approved Third Party Service</strong>”). You may not provide an
                            embed code or link, or otherwise try to make available via the LXC Services, a Resource
                            hosted or stored on a site or service other than an Approved Third Party Service.</p>

                        <p>With respect to User Content you create or provide in connection with your use of the
                            Services, you grant to LXC a fully transferable, worldwide, perpetual, royalty-free, paid
                            up, non-exclusive, and sublicensable license to reproduce, modify, adapt, make derivative
                            works of, distribute, publicly perform, publicly display, and otherwise use, disseminate, and
                            make available the User Content, in any manner or medium now known or later developed, and
                            to authorize others to do any of the foregoing, except as provided with respect to Permitted
                            CC Licenses in the following paragraph.</p>

                        <p>Depending on your User status, you may be able to provide Resources that are subject to a
                            Permitted CC License (as defined below), and to incorporate in a Resource items of content
                            (such as, for example, an image used in a video Resource) that are subject to a Permitted CC
                            License, so long as, in each case, you identify the Permitted CC License applicable to each
                            such Resource or item of incorporated content, and provide the attribution information
                            required by the Permitted CC License, using the functionality for such identification and
                            attribution information provided on the Site at the time you provide the Resource. In that
                            event, LXC will have the license rights granted under the applicable Permitted CC License,
                            rather than the license granted in the preceding paragraph. The rest of these TOS will apply
                            with respect to those Resources and items. As used in these TOS, the term
                            “<strong>Permitted CC Licenses</strong>” means any generic or international (i.e., unported) version of a
                            Creative Commons Attribution (CC BY) or Creative Commons Attribution-NonCommercial (CC
                            BY-NC) license.</p>

                        <p>
                            You may not provide any Resources or incorporated items of content that are subject to any
                            other public license, open source license, or other license terms that would limit,
                            condition, or otherwise apply in any way to the use of such Resources by LXC or its Users.
                            This includes, but is not limited to, any Creative Commons license, other than a Permitted
                            CC License, and any GNU license. You warrant to LXC and agree that none of the Resources you
                            provide are or will be subject to any such license.
                        </p>

                        <p>
                            You may only provide to LXC User Content that you have the lawful right to provide. You are
                            solely responsible for your User Content and the consequences of creating and providing it
                            for use in the Services. You represent and warrant that: <br/>
                            (1) your User Content will not contain any third party copyrighted material, or material
                            that is subject to other third party rights, unless you have permission from the rightful owner of the material or you are
                            otherwise legally entitled to provide the material to LXC and to grant LXC all of the
                            license rights granted in these TOS; <br/>
                            (2) your User Content will not: (a) infringe, violate, or misappropriate any third party
                            right, including any copyright, trademark, patent, trade
                            secret, moral right, privacy right, right of publicity, or any other intellectual property
                            or proprietary right or (b) slander, defame, libel, or invade the right of privacy,
                            publicity, or other property rights of any other person; <br/>
                            (3) as between you and LXC, you will be responsible for the payment of any third party fees
                            related to the provision and use
                            of your User Content; and <br/>
                            (4) your User Content will not contain any viruses, adware, spyware, worms, or other
                            malicious code or harmful contaminants. For clarity, if you provide
                            a Resource that is hosted by an Approved Third Party Service, you are not making any
                            representation or warranty under preceding clause (2)(a) regarding compliance by LXC with
                            any terms or conditions imposed by the Approved Third Party Service, if any such compliance
                            is required.
                        </p>

                        <p>
                            We intend to enable some User Content to be shared with a restricted group of other Users,
                            and intend to make other User Content public for all to view. You should not provide any
                            User Content that you do not wish to be visible to others. You acknowledge and agree that,
                            although we may provide features intended to restrict the visibility of some or all of your
                            User Content, we do not guarantee that such User Content will never be accessible by others.
                            We disclaim all liability with respect to any unauthorized or unintended access to any
                            restricted User Content.
                        </p>

                        <p>
                            You acknowledge that we may review User Content provided by you or other Users, and that we
                            will have the right (but not the obligation), in our sole discretion, to remove any User
                            Content without any liability to you. We reserve the right to remove User Content without
                            prior notice. You acknowledge and agree that we may preserve User Content and may disclose
                            User Content if required to do so by law or in good faith believe that any such preservation
                            or disclosure is reasonably necessary to comply with legal processes, enforce these TOS,
                            respond to claims that any User Content violates the rights of third parties, or protect our
                            rights, property, or personal safety or those of our Users and the public.
                        </p>

                        <h2>5. Conduct</h2>

                        <p>You agree that you are responsible for your own use of the Services and for the User Content
                            you create or provide. You agree that you will use the Services in compliance with these
                            TOS and all applicable local, state, national, and international laws, rules and
                            regulations, including privacy and copyright laws, any laws regarding the transmission of
                            technical data exported from your country of residence, and all United States export control
                            laws.</p>

                        <p>THE FOLLOWING KINDS OF CONTENT ("<strong>Prohibited Content</strong>”) ARE STRICTLY PROHIBITED
                            ON THE SITE AND IN THE SERVICES:</p>

                        <p>
                            1. Content that defames, harasses, discriminates against, harms or threatens others;<br/>
                            2. Content that discusses illegal activities with the intent to commit them;<br/>
                            3. Content that infringes on or misappropriates intellectual property rights;<br/>
                            4. Content that you do not have the right to disclose;<br/>
                            5. Profane, pornographic, obscene, indecent, or unlawful content;<br/>
                            6. Advertising or any form of commercial solicitation, junk mail, or spam;<br/>
                            7. Content related to partisan political activities; and<br/>
                            8. Content that contains intentionally inaccurate information or that is posted with the
                            intent of misleading others.
                        </p>

                        <p>This is not an exhaustive list of Prohibited Content, but rather general guidelines. We
                            reserve the right to remove Prohibited Content of which we become aware, but will be under
                            no obligation to do so.</p>

                        <p>
                            In addition, you agree not to
                            (a) use the Services in any manner intended to damage, disable, overburden, interfere with,
                            or disrupt any part of the Services or the computer equipment or
                            network(s) connected to the Services or any other User's use and enjoyment of the Services;
                            (b) attempt to gain unauthorized access to the Services, other accounts, or computer
                            equipment or networks connected to the Services through hacking, password mining, or any
                            other means; (c) obtain or attempt to obtain any materials or information on or via the
                            Services not intentionally made available to you through the Services;
                            (d) copy or use any portion of the Services other than as expressly allowed under these TOS;
                            (e) use any high volume, automated, or electronic means to access the Services (including
                            without imitation robots, spiders, or scripts);
                            (f) frame the Site, place pop-up windows over its pages, or otherwise affect the display of
                            its pages; or g) force headers or otherwise
                            manipulate identifiers in order to disguise the origin of any communication transmitted
                            through the Services.
                        </p>

                        <p>LXC reserves the right to pursue any and all legal remedies against you if you violate these
                            TOS.</p>

                        <h2>6. Rights Granted to You; LXC’s Intellectual Property Rights</h2>

                        <p>
                            We intend to make certain Resources available on the Site and via the Services for use in
                            accordance with these TOS. Some of the Resources will consist of User Content, in whole
                            or in part. Other Resources will be created or provided by us or our other licensors.
                        </p>

                        <p>
                            We grant you a personal, non-exclusive, non-transferable, revocable license to access and use
                            the Resources as set forth in these TOS. Subject to these TOS, you may use the Resources in
                            the ways in which Registered Users of your type are permitted to use them, as described on
                            the Site. Except as otherwise provided in the following paragraph with respect to Resources
                            subject to a Permitted CC License:
                        </p>
                        <ol>
                            <li>You may only use the Resources for your own personal, non-commercial, purposes. Use of
                                the Resources as part of your teaching activities will be deemed to be non-commercial as
                                long as no charge is imposed for use of the Resources or the Services.
                            </li>
                            <li>You may only use the Resources on the Site and via the Services. You may not
                                download, retransmit, publish, distribute, frame, display, or otherwise make available
                                any of the Resources.
                            </li>
                        </ol>
                        <p>
                            In some cases, particular Resources, or items of content incorporated into a Resource (such
                            as, for example, an image used in a video Resource), may be subject to a Permitted CC
                            License. Those Resources and items will be identified as such on the Site, when known to us.
                            You are free to use those Resources and items in the manner allowed under the Permitted CC
                            License, and the license and restrictions set forth in the preceding paragraph will not
                            apply. The rest of these TOS will apply to your use of those Resources and items. It is your
                            responsibility to comply with the terms and conditions of any applicable Permitted CC
                            License.
                        </p>

                        <p>
                            You also may use User Comments to which you have permitted access, provided that your use is
                            made in conjunction with your permitted use of the Resources and Services.
                        </p>

                        <p>The LabXchange and Harvard University names, logos, trademarks, and service marks that may
                            appear on the Site ("<strong>Marks</strong>") are the property of Harvard University and are
                            protected under United States and foreign laws. You are not granted hereunder any right or
                            license to use the Marks. All other trademarks, service marks, and logos used on the Site,
                            with or without attribution, are the trademarks, service marks, or logos of their respective
                            owners.</p>

                        <p>Except for User Content, the Resources and all other parts of the Site, and Services are the
                            property of LXC and/or its affiliates or licensors and are protected by copyright, patent,
                            and/or other proprietary intellectual property rights under United States and foreign law.
                            We and/or our licensors reserve all rights to the Resources, Site and Services that are not
                            expressly granted in these TOS. You may not reverse-engineer, decompile, disassemble, or
                            otherwise access the source code for any software that may be used to operate the Site or
                            the Services. You may not remove any copyright, license, or other notices on any Resources or
                            otherwise on the Site or Services.</p>

                        <h2>7. Termination of Services; Withdrawal of Resources</h2>

                        <p>
                            You agree that we may terminate your use of the Services, in our sole discretion, for any
                            reason or no reason, and that we shall not have any liability to you for any such action.
                            Without limiting any of our other rights or remedies, it is our policy to terminate, in
                            appropriate circumstances, use of the Site by Users who are repeat copyright infringers. We
                            will not be required to provide you any service or engage in any activity if we believe that
                            the service or activity may violate any applicable law or regulation. You further
                            acknowledge that for the purpose of the Services your sole relationship with us is as
                            defined in these TOS. Termination of use will not give rise to a right to any grievance or
                            other resolution process for student or personnel disputes at any school, division,
                            institute, or program of or associated with Harvard University. You agree that we have the
                            right to cancel or modify the Services at any time, including, without limitation, the right
                            to withdraw or modify Resources. You acknowledge that other Users also may withdraw
                            Resources, including Resources you are using. If you no longer wish to use the Services, you
                            may terminate your participation upon notice to us. The rights granted to you under these
                            TOS will terminate upon any termination of your right to use the Services, but the other
                            provisions of these TOS will survive any such termination.
                        </p>

                        <h2>8. No Academic Credit; Credentials</h2>

                        <p>You will not receive academic credit from Harvard University for using the Services. Use of
                            the Services and Resources does not enroll or register you in any school, division,
                            institute, or program of or associated with Harvard University, or entitle you to access or
                            use the resources or receive any benefits or privileges of any of the foregoing entities
                            other than the LXC Services.</p>

                        <p>Educators and institutions who use the Services may offer a credential or other
                            acknowledgment for participants who have satisfactorily demonstrated achievement in a
                            program they provide. The decision to award any such credential or other acknowledgment to a
                            given participant will be solely within the discretion of the relevant educator or
                            institution.</p>

                        <p>If you use the Services as part of a course provided by an educator or institution or
                            otherwise in connection with an educational Institution with which you are associated (your
                            “<strong>Home Institution</strong>”), the Home Institution may receive information
                            concerning assessments and achievements that are conducted and recorded using Services, in
                            accordance with the LXC Privacy Notice. However, the decision whether your Home Institution
                            will award any credit, benefit, or credential, and the nature of any such credit, benefit, or
                            credential, will lie solely with your Home Institution.</p>

                        <h2>9. Links</h2>

                        <p>
                            The Services may include hyperlinks to websites or applications maintained or controlled by
                            others. LXC is not responsible for and does not approve or endorse the contents or use of
                            any of the information, products, or services that may be offered at these websites or
                            applications. If you decide to access linked third-party websites or applications you do so
                            at your own risk. You expressly release LXC from any liability arising from your use of any
                            third party site.
                        </p>

                        <h2>10. Choice of Law; Dispute Resolution</h2>

                        <p>
                            You agree that these TOS and any claim or dispute arising out of or relating to these TOS,
                            any Service, or any Resource or service obtained from or through the Site will be governed
                            by the laws of the Commonwealth of Massachusetts, excluding its conflicts of law principles.
                            You agree that all such claims and disputes will be heard and resolved exclusively in the
                            federal or state courts located in and serving Middlesex County or Suffolk County,
                            Massachusetts, U.S.A. You consent to the personal jurisdiction of those courts over you for
                            this purpose, and you waive and agree not to assert any objection to such proceedings in
                            those courts (including any defense or objection of lack of proper jurisdiction or venue or
                            inconvenience of forum).
                        </p>

                        <h2>11. Disclaimers of Warranty; Limitations of Liability</h2>

                        <p>
                            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE SITE, SERVICES, RESOURCES, AND ANY
                            INFORMATION, PRODUCTS, OR SERVICES THEREIN OR OBTAINED THEREBY ARE PROVIDED "AS IS" WITHOUT
                            WARRANTY OF ANY KIND (EXPRESS, IMPLIED, OR OTHERWISE), INCLUDING, WITHOUT LIMITATION, ANY
                            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                            NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE SITE WILL OPERATE IN AN UNINTERRUPTED OR
                            ERROR-FREE MANNER OR THAT THE SITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WITHOUT
                            LIMITING THE FOREGOING, WE DO NOT WARRANT THAT (A) THE SERVICES OR SITE WILL MEET YOUR
                            REQUIREMENTS OR EXPECTATIONS OR ACHIEVE THE INTENDED PURPOSES, (B) THE SERVICES OR SITE WILL
                            NOT EXPERIENCE OUTAGES OR OTHERWISE WILL BE UNINTERRUPTED, TIMELY, OR SECURE, (C) THE
                            INFORMATION OR SERVICES OBTAINED THROUGH OR FROM THE SERVICES OR SITE WILL BE ACCURATE,
                            COMPLETE, CURRENT, ERROR-FREE, OR RELIABLE, OR (D) ANY DEFECTS IN OR ON THE SERVICES OR SITE
                            WILL BE CORRECTED. WE MAKE NO REPRESENTATION OR WARRANTY REGARDING YOUR ABILITY TO TRANSMIT
                            AND RECEIVE INFORMATION FROM OR THROUGH THE SITE, AND YOU AGREE AND ACKNOWLEDGE THAT YOUR
                            ABILITY TO ACCESS THE SERVICES AND SITE MAY BE IMPAIRED.
                        </p>

                        <p>
                            WHILE THE SITE AND THE SERVICES MAY INCLUDE INFORMATION CONCERNING MEDICAL CARE, THE
                            INFORMATION IS PROVIDED FOR GENERAL EDUCATIONAL PURPOSES ONLY AND NONE OF IT SHOULD BE USED
                            TO DIAGNOSE OR TREAT PATIENTS.
                        </p>

                        <p>
                            EXCEPT INSOFAR AS THE FOLLOWING LIMITATION MAY BE PROHIBITED BY APPLICABLE LAW, WE SHALL NOT
                            BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY CONSEQUENTIAL, INDIRECT, PUNITIVE, SPECIAL,
                            OR INCIDENTAL DAMAGES, WHETHER FORESEEABLE OR UNFORESEEABLE (INCLUDING, BUT
                            NOT LIMITED TO, LOSS OF PROFITS OR EARNING POWER, LOSS OF DATA, LOSSES DUE TO ERRORS OR
                            INTERRUPTION IN AVAILABILITY OF THE SITE, UNAVAILABILITY OF ANY SERVICE, SERVER OR
                            COMMUNICATIONS FACILITY, OR DAMAGES DUE TO ACTS OR OMISSIONS OF OTHERS USING THE SITE, A
                            RESOURCE, OR A SERVICE), ARISING OUT OF OR RELATING TO THE AGREEMENTS, THE SITE, THE
                            RESOURCES, OR THE SERVICES, INCLUDING WITHOUT LIMITATION YOUR AND OTHERS' USE OF OR
                            INABILITY TO USE THE SITE, SERVICES, OR ANY RESOURCE, OR YOUR SUBMISSION, MODIFICATION,
                            CREATION, OR USE OF RESOURCES, OR OTHERS’ USE OF RESOURCES YOU SUBMIT, MODIFY, OR CREATE, OR
                            YOUR INTERACTION WITH OTHER USERS (WHETHER ONLINE OR IN PERSON), OR YOUR RELIANCE UPON
                            INFORMATION OBTAINED FROM OR THROUGH THE SITE OR ANY SERVICES, WHETHER BASED IN CONTRACT,
                            TORT, STATUTORY, OR OTHER LAW. OUR TOTAL CUMULATIVE LIABILITY TO YOU ARISING OUT OF OR
                            RELATED TO THE AGREEMENTS, THE SITE OR THE SERVICES (INCLUDING, WITHOUT LIMITATION, IN THE
                            WAYS DESCRIBED IN THE PRECEDING SENTENCE), WHETHER BASED IN CONTRACT, TORT, STATUTORY OR
                            OTHER LAW, WILL NOT EXCEED THE GREATER OF $100 OR THE AMOUNT, IF ANY, THAT YOU PAID US TO
                            USE THE SERVICES IN THE TWELVE MONTHS PRECEDING THE CLAIM. THE DISCLAIMERS AND LIMITATIONS
                            SET FORTH IN THIS SECTION SHALL APPLY, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                            WHETHER OR NOT WE HAVE BEEN NEGLIGENT OR OTHERWISE AT FAULT.
                        </p>

                        <p>
                            YOU ACKNOWLEDGE AND AGREE THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH IN THESE TOS REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND US, AND THAT THESE LIMITATIONS ARE ESSENTIAL TO OUR WILLINGNESS TO MAKE THE SITE AND SERVICES AVAILABLE TO YOU. EACH OF THESE DISCLAIMERS AND LIMITATIONS IS INTENDED TO BE SEPARATELY ENFORCEABLE, REGARDLESS OF WHETHER ANY OTHER REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                        </p>

                        <p>
                            YOU ACKNOWLEDGE THAT, FOR PURPOSES OF THE FOREGOING DISCLAIMERS AND LIMITATIONS, AS WELL AS THE INDEMNITY IN SECTION 12 BELOW, THE TERMS “WE,” “OUR,” “US” AND "LXC" INCLUDE THE CORPORATE BODY PRESIDENT AND FELLOWS OF HARVARD COLLEGE, ALSO KNOWN AS HARVARD UNIVERSITY, AND ITS VARIOUS SCHOOLS, THE MEMBERS OF ITS GOVERNING BOARDS, AND ITS OFFICERS, FACULTY MEMBERS, EMPLOYEES, CONTRACTORS, AND REPRESENTATIVES.
                        </p>

                        <p>
                            CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THE LAWS OR REGULATIONS OF THESE JURISDICTIONS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                        </p>

                        <h2>12. Indemnity</h2>

                        <p>
                            You agree to indemnify and hold harmless us (as defined for this purpose in Section 11 above) from any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees and costs, relating to or arising out of (a) your use or attempted use of the Site, Resources, or Services in violation of these TOS; (b) your violation of any law or rights of any third party; or (c) any User Content that you post or otherwise make available on the Site or through any Service, including without limitation any claim of infringement or misappropriation of intellectual property or other proprietary rights.

                        </p>

                        <h2>13. Copyright Policy</h2>

                        <p>
                            Copyright owners who believe their material has been infringed on the Site should contact Harvard's DMCA agent as specified at {' '}
                            <a href='https://www.harvard.edu/reporting-copyright-infringements'>www.harvard.edu/reporting-copyright-infringements</a>.
                        </p>

                        <h2>14. Independent Contractors</h2>

                        <p>
                            You and LXC are independent contractors, and neither has the authority to act for or to bind the other in any way.
                        </p>

                        <h2>15. Integration</h2>

                        <p>
                            These Terms of Service constitute the entire agreement between you and LXC with respect to your use of the Site and Services, superseding any prior agreements between you and us regarding your use of the Site and Services. Some terms applicable to your application for a User Account or your use of a Service may be provided to you at the time of application or access or on Service-specific web pages. Those additional terms may include, among other things, terms relating to the act of registering or Service requirements. Your registration or participation also shall be subject to your agreement and compliance with those additional terms. For clarity, these TOS do not supersede any prior agreement you have with Harvard University with respect to your use of any website other than the Site (as defined above) or any Harvard site, program, or service other than the Site and Services.
                        </p>

                        <h2>16. Waiver; Severability</h2>

                        <p>
                            The failure of LXC to exercise or enforce any right or provision of these TOS shall not constitute a waiver of such right or provision. If any provision of these TOS is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, to the maximum extent that may lawfully be done, and the other provisions of these TOS shall remain in full force and effect.
                        </p>

                        <h2>17. Assignment</h2>

                        <p>
                            LXC may freely transfer or assign any portion of its rights or delegate its obligations under these TOS. You may not transfer or assign, by operation of law or otherwise, any portion of your rights or delegate your obligations under these TOS without the prior written consent of LXC, and any attempted such transfer or assignment shall be void and of no effect.
                        </p>
                    </div>
                </div>
            </StandardPageLayout>
        );
    }
}

export const TermsOfServicePage = connect<{}, RootState>(
    (state: RootState) => ({}),
)(TermsOfServicePageInternal);
