import * as React from 'react';

interface CareerSectionProps {
  title: string;
  featuredImage?: string;
  description?: string;
}

export const CareerSection: React.FC<CareerSectionProps> = ({title, description, featuredImage}) => (
  <div className='career-section'>
    <h4>{title}</h4>
    {description && (
      <>
        <h6 className='desc-heading ce-heading-xs'>Description</h6>
        <span className='sec-description'>{description}</span>
      </>
    )}
    {featuredImage && (
      <div className='sec-image-wrapper'>
        <img
          alt={`Featured snap for ${title} section`}
          src={featuredImage}
          className='sec-image'
        />
      </div>
    )}
  </div>
);
