import {
    ContainerOne,
    Theatre,
} from 'elements';
import { ItemType } from 'items/models';
import * as React from 'react';
import messages from '../../displayMessages';
import { BlockPreview } from './BlockPreview';
import { BlockProps } from './models';
import { createAssetViewedEventForItem } from './utils';
import { SandboxedIFrame, OnAnalyticsEventParams } from 'elements/components/SandboxedIFrame';
import { NextButton } from 'ui/components';
import { intl } from 'i18n';
import { useAnalytics } from 'use-analytics';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ItemMetadataTypeEnum } from 'labxchange-client';
import { toggleHelpCenterVisibility } from 'utils';


export const SimulationBlock = (props: BlockProps) => {
    const history = useHistory();
    const location = useLocation();
    const [showBlockView, setShowBlockView] = React.useState(false);
    const [focusStartButton, setFocusStartButton] = React.useState(false);
    const startButtonId = 'start-button';

    const onShow = () => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('fullscreen', 'true');
        history.push(`?${searchParams.toString()}`);
        createAssetViewedEventForItem(props.itemMetadata.id);
        setShowBlockView(true);
        toggleHelpCenterVisibility('hide');
    };

    // Analytics
    const analytics = useAnalytics();
    const onAnalyticsEvent = ({eventName, eventData}: OnAnalyticsEventParams) => {
        analytics.track(eventName, {...eventData, url: window.location.toString()});
    };

    // Set button title depending on simulation type
    let showButtonTitle = messages.blockSimulationStartLabel;
    if (props.itemMetadata.type === ItemType.Interactive) {
        showButtonTitle = messages.blockInteractiveStartLabel;
    }

    // Get simulation frame data from prop
    const iframeSrc = props.blockData.studentViewData.simulation_url;


    React.useEffect(() => {
    const isFullscreen = new URLSearchParams(location.search).has('fullscreen');
    setShowBlockView(isFullscreen);
    if (isFullscreen) {
        toggleHelpCenterVisibility('hide');
    }

    }, [location.search]);

    React.useEffect(() => {
        if (focusStartButton === true) {
            document.getElementById(startButtonId)?.focus();
            setFocusStartButton(false);
            toggleHelpCenterVisibility('show');
        }
    }, [focusStartButton]);

    return (
        <ContainerOne>
            <BlockPreview
                previewImageUrl={props.itemMetadata.imageUrl}
                button={
                    <NextButton id={startButtonId} title={intl.formatMessage(showButtonTitle)} onClick={onShow}/>
                }
                isSimulationBlock={props.itemMetadata.type === ItemMetadataTypeEnum.Simulation}
            />
            {showBlockView ? (
                <Theatre
                    title={props.itemMetadata.title}
                    onRequestClose={() => {
                        setShowBlockView(false);
                        toggleHelpCenterVisibility('show');
                        window.history.back();
                        setFocusStartButton(true);
                    }}
                >
                    <SandboxedIFrame
                        title={props.itemMetadata.title}
                        src={iframeSrc}
                        scrolling='auto'
                        width='100%'
                        onRequestClose={() => {
                           setShowBlockView(false);
                            window.history.back();
                        }}
                        extraInformation={{
                            username: props.username,
                            classromName: props.classroomName,
                        }}
                        onAnalyticsEvent={onAnalyticsEvent}
                    />
                </Theatre>
            ) : null}
        </ContainerOne>
    );
};
