import * as React from 'react';
import { BlockProps } from './models';
import { Theatre } from 'elements/components/Modals';
import { CollapsibleWithChevron } from 'elements/components/Containers';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { WrappedMessage } from 'utils';
import { ItemsApi } from 'global/api';
import messages from '../../displayMessages';
import { Icon, showErrorMessage } from 'ui/components';
import { editApiResponseToEditorState } from 'library/components/BlockEditor/serializers';
import {
    ImageEditorState,
    XBlockType,
} from 'library/components/BlockEditor/models';
import { createAssetViewedEventForItem } from './utils';
import { RichText } from 'elements';

interface State {
    loading: boolean;
    showEnlargedImage: boolean;
    editorState?: Readonly<ImageEditorState>;
}

export class ImageBlock extends React.PureComponent<BlockProps, State> {
    constructor(props: BlockProps) {
        super(props);
        this.state = {
            loading: true,
            showEnlargedImage: false,
        };
    }

    async componentDidMount() {
        await this.loadData();
        createAssetViewedEventForItem(this.props.itemMetadata.id);
    }

    /**
     * Fetch image data to render block
     */
    private async loadData() {
        const id = this.props.itemMetadata.id;
        try {
            // Load the XBlock edit data from the API, if available:
            const dataRaw = await ItemsApi.editXblock({
                id,
                blockKey: this.props.itemMetadata.id
            });
            // NOTE, XXX: parsing as json here is needed because of GenericObjectSerializer.
            const data = JSON.parse(dataRaw);
            // Load data from backend, then:
            const editorState = editApiResponseToEditorState(XBlockType.Image, data) as ImageEditorState;
            this.setState({ editorState, loading: false, });
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.errorLoadingImage} values={{id}} />,
                {exception: err}
            );
        }
    }

    public render() {
        if (this.state.loading || !this.state.editorState) {
            return null;
        }
        const img = this.state.editorState;

        if (this.state.showEnlargedImage) {
            const theatreData = {
                src: img.imageUrl,
                alt: img.altText,
            };

            return (
                <Theatre
                    title={this.props.itemMetadata.title}
                    onRequestClose={() => this.onHideEnlargedImage()}
                    imageTheaterData={theatreData}
                />
            );
        }

        return (
            <ImageView img={img} onEnlarge={() => this.onShowEnlargedImage()} />
        );
    }

    private onShowEnlargedImage() {
        this.setState({ showEnlargedImage: true });
    }

    private onHideEnlargedImage() {
        this.setState({ showEnlargedImage: false });
    }

}


interface ImageProps {
    img: ImageEditorState;
    onEnlarge: () => void;
}

export const ImageView: React.FC<ImageProps> = ({ img, onEnlarge }) => (
    <div className='image-block'>
        <figure>
            <img
                src={img.imageUrl}
                alt={img.altText}
            />
            <div className='image-enlarge-button-wrapper'>
                <button
                    className='image-enlarge-button'
                    onClick={onEnlarge}
                >
                    <WrappedMessage message={messages.clickToEnlarge} />
                    {' '}<Icon name='search' zoom='20' />
                </button>
            </div>
            <figcaption>
                {img.captionText &&
                    <span className='caption'>
                        <RichText
                            innerHtml={img.captionText}
                            sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                            renderMath={true}
                        />
                    </span>
                }
                {img.captionText && img.citationText && ' '}
                {img.citationText &&
                    <cite>
                        <RichText
                            innerHtml={img.citationText}
                            sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                            renderMath={true}
                        />
                    </cite>
                }
            </figcaption>
        </figure>
        {img.extendedDescText === '' ? null : <div className='extended-description'>
            <CollapsibleWithChevron
                title={messages.imageBlockExtendedDescription.defaultMessage}
                defaultOpen={false}
            >
                <RichText
                    innerHtml={img.extendedDescText}
                    sanitizeConfig={SanitizeConfigOptions.UnsafeExtendedDescHTML}
                    renderMath={true}
                />
            </CollapsibleWithChevron>
        </div>
        }
    </div>
);
