// types and components shared between the question block variants
import * as React from 'react';
import messages from '../../displayMessages';
import { Button, Icon } from 'ui/components';
import { intl } from 'i18n';
import { RichText } from 'elements/components/RichText';
import { WrappedMessage } from 'utils';
import { ProblemResponseType } from '../../../assessment-editor/models';

// NOTE: these types must be kept in sync with
// what is stored int labxchange/backend/apps/blcoks/item_blocks/question_block.py::QuestionBlock.question_data
export interface Option {
    content: string;
    checked: boolean;
    comment: string;
}

export interface OptionResponseAnswer {
    index?: number;
}

export interface OptionResponse {
    type: 'optionresponse';
    question: string;
    display: 'radio' | 'dropdown';
    options: Option[];
    studentAnswer: OptionResponseAnswer;
}

export interface StringResponseAnswer {
    response?: string;
}

export interface StringResponse {
    type: 'stringresponse';
    question: string;
    studentAnswer: StringResponseAnswer;
    comment?: string;
    answer?: string;
}

export interface ChoiceResponseAnswer {
    selected?: number[];
    selected_and_correct?: number[];
}

export interface ChoiceResponse {
    type: 'choiceresponse';
    question: string;
    choices: Option[];
    studentAnswer: ChoiceResponseAnswer;
    comment: string;
}

export type QuestionData = OptionResponse | StringResponse | ChoiceResponse;

export interface Hint {
    content: string;
}

export interface ViewData<T> {
    questionData: T;
    maxAttempts: number;
    current_score: number;
    total_possible: number;
    hints: Hint[];
    studentAttempts: number;
    correct?: boolean;
}

interface HintsProps {
    hints: Hint[];
}

interface HintsState {
    [index: number]: boolean;
}


export class Hints extends React.PureComponent<HintsProps, HintsState> {
    constructor(props: HintsProps) {
        super(props);
        this.state = {};
    }

    public render(): React.ReactNode {
        const hints = this.props.hints.map((hint, i) => {
            const open = this.state[i];
            return (
                <div className='hint'>
                    <button className='hint-title' onClick={() => this.toggle(i)}>
                        <span>
                            {this.props.hints.length > 1
                                ? <WrappedMessage
                                    message={messages.hint}
                                    values={{ n: i + 1 }}
                                />
                                : <WrappedMessage
                                    message={messages.hintOne}
                                />
                            }
                        </span>
                        {open ? <Icon name='chevron-up' /> : <Icon name='chevron-down' />}
                    </button>
                    {this.state[i] === true &&
                        <div className='hint-content'>
                            <RichText innerHtml={hint.content}/>
                        </div>
                    }
                </div>
            );
        });

        return (
            <div className='hints'>
                {hints}
            </div>
        );
    }

    private toggle(i: number) {
        this.setState({ [i]: !this.state[i] });
    }
}

interface IncrementalHintsProps {
    hints: Hint[];
    currentHintIndex: number;
}

export class IncrementalHints extends React.PureComponent<IncrementalHintsProps, HintsState> {
    constructor(props: IncrementalHintsProps) {
        super(props);
        this.state = {
        };
    }

    public render(): React.ReactNode {
        const hints = this.props.hints.slice(0, this.props.currentHintIndex+1).map((hint, i) => {
            return (
                <div className='hint'>
                    <div className='hint-title'>
                        <span>
                            {this.props.hints.length > 1
                                ? <WrappedMessage
                                    message={messages.hint}
                                    values={{ n: i + 1 }}
                                />
                                : <WrappedMessage
                                    message={messages.hintOne}
                                />
                            }
                        </span>
                    </div>
                    <div className='hint-content'>
                        <RichText innerHtml={hint.content}/>
                    </div>
                </div>
            );
        });

        return (
            <div className='hints'>
                {hints}
            </div>
        );
    }
}

interface FeedbackProps {
    max: number;
    used: number;
    correct?: boolean;
    answer?: string;
}

export const Feedback: React.FC<FeedbackProps> = ({ max, used, correct, answer }) => {
    if (correct === true) {
        return (
            <div className='general-feedback correct'>
                <Icon name='alert-correct-hex' />
                <WrappedMessage message={messages.questionCorrect} />
            </div>
        );
    } else if (correct === false && max > 0 && used < max) {
        return (
            <div className='general-feedback info'>
                <Icon name='alert-info-hex' />
                <WrappedMessage
                    message={messages.questionIncorrectAttempts}
                    values={{ remaining: max - used }}
                />
            </div>
        );
    } else if (correct === false && answer) {
        return (
            <div className='general-feedback incorrect'>
                <Icon name='alert-fail-hex' />
                <WrappedMessage message={messages.questionIncorrectWithAnswer} values={{ answer }} />
            </div>
        );
    } else if (correct === false && max > 0 && used >= max) {
        return (
            <div className='general-feedback incorrect'>
                <Icon name='alert-fail-hex' />
                <WrappedMessage
                    message={messages.questionIncorrectAttempts}
                    values={{ remaining: 0 }}
                />
            </div>
        );
    } else if (correct === false) {
        return (
            <div className='general-feedback incorrect'>
                <Icon name='alert-fail-hex' />
                <WrappedMessage message={messages.questionIncorrect} />
            </div>
        );
    } else {
        return null;
    }
};

interface SpecificFeedbackProps {
    comment?: string;
    correct?: boolean;
}

export const SpecificFeedback: React.FC<SpecificFeedbackProps> = ({ comment, correct }) => {
    if (comment) {
        return <div className='feedback correct'>
            <RichText
                innerHtml={intl.formatMessage(correct ? messages.correctFeedback : messages.incorrectFeedback) + '&nbsp;' + comment || ''}
            />
        </div>;
    } else {
        return null;
    }
};

interface GroupFeedbackProps {
    comment?: string;
    correct?: boolean;
    type?: string;
}

export const GroupFeedback: React.FC<GroupFeedbackProps> = ({ comment, correct, type }) => {
    if (correct === null || correct === undefined)
        return <div></div>;
    return (
        <div className={`group-feedback ${correct ? 'group-feedback-correct' : 'group-feedback-incorrect'}`}>
            <div className='feedback-icon'>
                <Icon name={correct ? 'alert-correct-hex' : 'alert-fail-hex'} />
                <strong>
                    <WrappedMessage message={correct ? messages.correctFeedback : messages.incorrectFeedback} />
                </strong>
            </div>
            <div className='feedback-text'>
                {comment ?
                  <RichText innerHtml={'&nbsp;' + comment || ''}/> :
                  (
                    type &&
                    type === ProblemResponseType.ChoiceResponse &&
                    <WrappedMessage message={correct ? messages.correctQestionnChoiceFeedbackDefault : messages.incorrectQestionnChoiceFeedbackDefault} />
                  )
                }
            </div>
        </div>
    );
};

interface SubmitProps {
    hidden: boolean;
    disabled: boolean;
    isTryAgain?: boolean;
    maxAttempts: number;
    usedAttempts: number;
    showHintButton?: boolean;
    onSubmit: () => void;
    onGetHint?: () => void;
}

export const Submit: React.FC<SubmitProps> = (props) => (
    <div className='submit-content'>
        <div>
            {props.hidden
                ? <div className='spacer' />
                : <Button
                    label={props.isTryAgain ? messages.tryAgainButtonText : messages.submitAnswer}
                    btnStyle={props.isTryAgain ? 'normal' : 'primary'}
                    className={props.isTryAgain ? 'try-again-btn' : 'submit-btn'}
                    disabled={props.disabled}
                    onClick={() => props.onSubmit()}
                />
            }
            {props.showHintButton && <Button
                    className='get-hint-btn'
                    label={messages.getHintButtonText}
                    btnStyle={'outline'}
                    onClick={() => props.onGetHint && props.onGetHint()}
                />
            }
        </div>
        {props.maxAttempts > 0 &&
            <span className='attempts'>
                <WrappedMessage
                    message={messages.questionAttemptsLeft}
                    values={{
                        attempts: Math.max(props.maxAttempts - props.usedAttempts, 0),
                    }}
                />
            </span>
        }
    </div>
);

export const noMoreSubmissions = (data: ViewData<QuestionData>): boolean => {
    // return true if the question will allow no more submissions
    return data.correct || (data.maxAttempts > 0 && data.studentAttempts >= data.maxAttempts);
};
