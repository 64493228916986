import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleNonUSPrivacyDisclosure',
        defaultMessage: 'Non-US Privacy Disclosure',
        description: 'HTML title for the Non US Privacy Disclosure page.',
    },
    pageSubTitle: {
        id:'pageSubTitleNonUSPrivacyDisclosure',
        defaultMessage: 'Dated: June 08, 2023',
        description: 'Publish date for the Non US Privacy Disclosure page.',
    },
});

export default messages;
