import { RootState } from 'global/state';
import * as React from 'react';
import { connect } from 'react-redux';
import { StandardPageLayout } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

// tslint:disable:max-line-length

export class CookiesPageInternal extends React.PureComponent<{}, {}> {

    public render() {
        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                headerFeature={
                    <div>
                    <h1 className='legal-header-title'>
                        <WrappedMessage message={messages.pageTitle}/></h1>
                    <p className='legal-header-sub-title'>
                        <WrappedMessage message={messages.pageSubTitle}/></p>
                    </div>
                }
            >
                <div className='legal-page'>
                    <div className='legal-content'>
<p>Cookies, pixel tags, scripts, log files, web beacons, localStorage and similar technologies are typically small data files that record information when you access or use our Services. The data helps us perform a variety of functions. These functions include operating the Services, measuring and improving the performance of the Services, tracking your preferences, and delivering content to you, depending on your needs and interests. The data files are stored in various places. Some are placed directly onto your computer or device by your web browser. Others might be embedded in a website, webpage or email that you access.</p>
<p>Cookies are small text files placed on your computer or device that store information about you and that can be accessed by the Services. We may use both “session” cookies and “persistent” cookies. Session cookies expire at the end of your browser session. Persistent cookies are stored in between browser sessions so we can remember your preferences or actions. You should be able to control how and whether cookies will be accepted by your web browser. Most browsers offer instructions on how to reset the browser to reject cookies. If you reject our cookies, some functions and conveniences of the Services may not work properly.</p>
<p>Pixel tags (also called scripts, web beacons, web bugs or clear GIFs) are typically invisible tags placed on certain pages of the Services but not on your computer or device. When you access those pages, pixel tags generate a generic notice of your visit. Pixel tags usually work in conjunction with cookies, registering when a particular computer or device visits a particular page. This can help us personalize the presentation of the Services for you. If you turn off cookies, the pixel tag will simply detect an anonymous visit.</p>
<p>Log files are electronic files that record events, transactions, actions or messages that have occurred, such as requests you make to our web server. We can analyze these records to get an idea of where visitors are coming from, how often they are returning and how they are navigating through and using our Services. We may also use other technologies that collect and store information about your use of the Services. Like the technologies described above, these other technologies might store information on your computer or device or on our website or web server. The specific names and features may change as technology advances.</p>
<p>localStorage is a mechanism for storing small pieces of data on a user’s device in a similar manner to cookies, and is used for similar purposes such as keeping a user logged into the service.</p>
                    </div>
                </div>
            </StandardPageLayout>
        );
    }
}

export const CookiesPage = connect<{}, RootState>(
    (state: RootState) => ({}),
)(CookiesPageInternal);
