import { defineMessages } from 'react-intl';

const messages = defineMessages({
    careerDefaultPageTitle: {
        id:'careerDefaultPageTitle',
        defaultMessage: 'Career Explorer',
        description: 'Default value for the HTML title attribute',
    },
    careerDetailsPageTitle: {
        id:'careerDetailsPageTitle',
        defaultMessage: 'Career Details - Career Explorer',
        description: 'Title for Details page.',
    },
    careerPageTitlePlaceholder: {
        id:'careerPageTitlePlaceholder',
        defaultMessage: '{title} - Career Explorer',
        description: 'Placeholder for the value of the HTML title attribute',
    },
    careerUiHeaderNavBarBrand: {
        id:'careerUiHeaderNavBarBrand',
        defaultMessage: 'Career Explorer',
        description: 'Title for Navbar.',
    },
    careerUiNavSearch: {
        id:'careerUiNavSearch',
        defaultMessage: 'Search careers',
        description: 'Placeholder text for the career explorer search box.',
    },
    careerCardComingSoonText: {
        id:'careerCardComingSoonText',
        defaultMessage: '- Coming soon! -',
        description: 'Text to display when a career is in draft status.',
    },
    careerResultsText: {
        id: 'careerResultsText',
        defaultMessage: '{count} result{plural}',
        description: 'Text to display for search results.',
    },
    careerCardMinQualification: {
        id: 'careerCardMinQualification',
        defaultMessage: 'Min. Qualification: ',
        description: 'Text to display for minimum qualification.',
    },
    careerCardSalaryRange: {
        id: 'careerCardSalaryRange',
        defaultMessage: 'Salary Range: ',
        description: 'Text to display for salary range.',
    },
    careerSearchPlaceholder: {
        id: 'careerSearchPlaceholder',
        defaultMessage: 'Search careers',
        description: 'Placeholder for career search box.',
    },
    careerNavHeaderTitle: {
        id: 'careerHeaderTitle',
        defaultMessage: 'Discover your future',
        description: 'Title for the header.',
    },
    careerNavHeaderSubtitle: {
        id: 'careerHeaderSubtitle',
        defaultMessage: 'Biological careers for any education level.',
        description: 'Subtitle for the header.',
    },
    careerShowingResultsLabel: {
        id: 'careerShowingResultsLabel',
        defaultMessage: 'Showing results for',
        description: 'Label for search results.',
    },
    careerNoResultsHeading: {
        id: 'careerNoResultsHeading',
        defaultMessage: 'No results found',
        description: 'Heading for no search results.',
    },
    careerNoResultsSubheading: {
        id: 'careerNoResultsSubheading',
        defaultMessage: 'Try searching for something else.',
        description: 'Subheading for no search results.',
    },
    careerTopRecommendations: {
        id: 'careerTopRecommendations',
        defaultMessage: 'Top recommendations',
        description: 'Title for top recommendations.',
    },
    careerLoadMoreButton: {
        id: 'careerLoadMoreButton',
        defaultMessage: 'Load More',
        description: 'Button to load more careers.',
    },
    careerMinQualification: {
        id: 'careerMinQualification',
        defaultMessage: 'Min qualification',
        description: 'Text to display for minimum qualification.',
    },
    careerSalaryRange: {
        id: 'careerSalaryRange',
        defaultMessage: 'Salary range',
        description: 'Text to display for salary range.',
    },
    careerWorkplace: {
        id: 'careerWorkplace',
        defaultMessage: 'Workplace',
        description: 'Text to display for career workplace.',
    },
    careerRequirements: {
        id: 'careerRequirements',
        defaultMessage: 'Requirements',
        description: 'Text to display for career requirements.',
    },
    careerQualifications: {
        id: 'careerQualifications',
        defaultMessage: 'Qualifications',
        description: 'Text to display for career qualifications.',
    },
    careerHardSkills: {
        id: 'careerHardSkills',
        defaultMessage: 'Hard Skills',
        description: 'Text to display for career hard skills.',
    },
    careerSoftSkills: {
        id: 'careerSoftSkills',
        defaultMessage: 'Soft Skills',
        description: 'Text to display for career soft skills.',
    },
    careerWhatToDoNext: {
        id: 'careerWhatToDoNext',
        defaultMessage: 'What to do next?',
        description: 'Text to display for what to do next.',
    },
    careerRelatedCareers: {
        id: 'careerRelatedCareers',
        defaultMessage: 'Related Careers',
        description: 'Text for related careers.',
    },
    careerSideNavOverview: {
        id: 'careerSideNavOverview',
        defaultMessage: 'Overview',
        description: 'Text for the overview section in the side nav.',
    },
    careerSideNavProgression: {
        id: 'careerSideNavProgression',
        defaultMessage: 'Career progression',
        description: 'Text for the progression section in the side nav.',
    },
    careerSideNavDayInLife: {
        id: 'careerSideNavDayInLife',
        defaultMessage: 'Day in the life',
        description: 'Text for the day in the life section in the side nav.',
    },
    careerSideNavHardSkills: {
        id: 'careerSideNavHardSkills',
        defaultMessage: 'Hard skills',
        description: 'Text for the hard skills section in the side nav.',
    },
    careerSideNavSoftSkills: {
        id: 'careerSideNavSoftSkills',
        defaultMessage: 'Soft skills',
        description: 'Text for the soft skills section in the side nav.',
    },
    careerSideNavWhatToDoNext: {
        id: 'careerSideNavWhatToDoNext',
        defaultMessage: 'What to do next?',
        description: 'Text for the what to do next section in the side nav.',
    },
    careerSideNavRelatedCareers: {
        id: 'careerSideNavRelatedCareers',
        defaultMessage: 'Related careers',
        description: 'Title for related careers section in the side nav.',
    },
    careerSuggestedAssetsExploreLearnings: {
        id: 'careerSuggestedAssetsExploreLearnings',
        defaultMessage: 'Explore learnings',
        description: 'Text for the explore learnings button in suggested assets section.',
    },
    careerActivityTitleCardIntro: {
        id: 'careerActivityTitleCardIntro',
        defaultMessage: 'Day in life of a',
        description: 'Text for the title card intro.',
    },
    careerActivityTitleCardScroll: {
        id: 'careerActivityTitleCardScroll',
        defaultMessage: 'Scroll',
        description: 'Text for the scroll text.',
    },
});

export default messages;
