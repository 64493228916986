import * as React from 'react';
import classNames from 'classnames';
import { intl } from 'i18n';

import { Icon } from 'elements';
import { CareerDayActivity, CareerCategoryColorGroupEnum } from 'labxchange-client';

import { formatTime24to12 } from 'career-explorer/utils';
import messages from '../../displayMessages';

interface CareerDailyActivityProps {
  careerName: string;
  activities: CareerDayActivity[];
  colorGroup: CareerCategoryColorGroupEnum;
}

export const CareerDailyActivity: React.FC<CareerDailyActivityProps> = ({
  careerName, activities, colorGroup
}) => (
  <div className={classNames('activities-container', colorGroup)} data-testid='activities-container'>
    <div className='title-card'>
      <div className='intro-section'>
        <span className='intro-text'>
          {intl.formatMessage(messages.careerActivityTitleCardIntro)}
        </span>
        <h6 className='career-title'>{careerName}</h6>
      </div>
      <div className='scroll-section'>
        <span className='ce-heading-xs scroll-text'>
          {intl.formatMessage(messages.careerActivityTitleCardScroll)}
        </span>
        <Icon name='arrow-right-long' className='scroll-arrow' />
      </div>
    </div>
    <div className='activities-list'>
      {activities.map((activity: CareerDayActivity, index: number) => (
          <div key={index} className='activity-card'>
            <div className='image-container'>
              <svg
                width='280'
                height='6'
                viewBox='0 0 280 6'
                fill='none'
                className='activity-rectangle'
              >
                <rect x='-19' width='346' height='6' fill='white' />
              </svg>
              <svg
                className={classNames('activity-polygon', colorGroup)}
                width='220'
                height='252'
                viewBox='0 0 220 252'
                fill='none'
              >
                <path
                  d='M3.8808 64.732L110 3.4641L216.119 64.732V187.268L110 248.536L3.8808 187.268V64.732Z'
                  stroke='white'
                  strokeWidth='6'
                />
              </svg>
              <img className='activity-image' alt='Career Activity' src={activity.activityImage} />
            </div>
            <div className='activity-info'>
              <span className='activity-time ce-heading-s'>{formatTime24to12(activity.activityTime)}</span>
              <span className='activity-description'>{activity.description || ''}</span>
            </div>
          </div>
      ))}
    </div>
  </div>
);

export default CareerDailyActivity;
