import { Action } from 'redux';
import { BannersApi } from 'global/api';
import { LXThunkAction } from 'global/types';
import { Banner, BannerResponse } from 'labxchange-client';
import { getStore } from 'global/store';

export const ActivateEmailBannerId = '__activate_email_banner__';
export const PolicyAndTermsUpdatesBannerId = '__policy_update_banner__';
export const ActivateEmailDismissCookie = 'labxchange_activate_email_banner_dismiss';

export enum Types {
    CACHE_BANNERS = 'CACHE_BANNERS',
    DISMISS_BANNER = 'DISMISS_BANNER',
}

export interface FetchBanners extends Action {
    readonly type: Types.CACHE_BANNERS;
    readonly response: Readonly<BannerResponse>;
}

export interface DismissBanner extends Action {
    readonly type: Types.DISMISS_BANNER;
    readonly banner: Banner;
}

export type ActionTypes =
    | FetchBanners
    | DismissBanner;

export const fetchBanners = (): LXThunkAction<void> => async (dispatch, getState) => {
    const response = await BannersApi.list({});
    dispatch({type: Types.CACHE_BANNERS, response});
};

export const dismissBanner = (banner: Banner): LXThunkAction<void> => async (dispatch, getState) => {
    dispatch({type: Types.DISMISS_BANNER, banner});
};

export async function fetchBannersNow() {
    await getStore().dispatch(fetchBanners() as any);
}
