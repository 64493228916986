import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitleTermsOfService',
        defaultMessage: 'Terms of Service',
        description: 'HTML title for the tos page.',
    },
    pageSubTitle: {
        id:'pageSubTitleTermsOfService',
        defaultMessage: 'Dated: June 08, 2023',
        description: 'Publish date for the tos page.',
    },
});

export default messages;
