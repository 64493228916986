import { defineMessages } from 'react-intl';

const messages = defineMessages({
    pageTitle: {
        id:'pageTitlePrivacyPolicy',
        defaultMessage: 'Privacy Notice',
        description: 'HTML title for the privacy notice page.',
    },
    pageSubTitle: {
        id:'pageSubTitlePrivacyPolicy',
        defaultMessage: 'Dated: June 08, 2023',
        description: 'Publish date for the privacy notice page.',
    },
});

export default messages;
