// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    ClassroomsGeneralPermissions,
    ClassroomsGeneralPermissionsFromJSON,
    ClassroomsGeneralPermissionsToJSON,
    LibraryGeneralPermissions,
    LibraryGeneralPermissionsFromJSON,
    LibraryGeneralPermissionsToJSON,
    MentorshipGeneralPermissions,
    MentorshipGeneralPermissionsFromJSON,
    MentorshipGeneralPermissionsToJSON,
    OrganizationsGeneralPermissions,
    OrganizationsGeneralPermissionsFromJSON,
    OrganizationsGeneralPermissionsToJSON,
    ProfilesGeneralPermissions,
    ProfilesGeneralPermissionsFromJSON,
    ProfilesGeneralPermissionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface APIPermissions
 */
export interface APIPermissions {
    /**
     * 
     * @type {ClassroomsGeneralPermissions}
     * @memberof APIPermissions
     */
    classrooms: ClassroomsGeneralPermissions;
    /**
     * 
     * @type {LibraryGeneralPermissions}
     * @memberof APIPermissions
     */
    library: LibraryGeneralPermissions;
    /**
     * 
     * @type {MentorshipGeneralPermissions}
     * @memberof APIPermissions
     */
    mentorship: MentorshipGeneralPermissions;
    /**
     * 
     * @type {OrganizationsGeneralPermissions}
     * @memberof APIPermissions
     */
    organizations: OrganizationsGeneralPermissions;
    /**
     * 
     * @type {ProfilesGeneralPermissions}
     * @memberof APIPermissions
     */
    profile: ProfilesGeneralPermissions;
}

export function APIPermissionsFromJSON(json: any): APIPermissions {
    return {
        'classrooms': ClassroomsGeneralPermissionsFromJSON(json['classrooms']),
        'library': LibraryGeneralPermissionsFromJSON(json['library']),
        'mentorship': MentorshipGeneralPermissionsFromJSON(json['mentorship']),
        'organizations': OrganizationsGeneralPermissionsFromJSON(json['organizations']),
        'profile': ProfilesGeneralPermissionsFromJSON(json['profile']),
    };
}

export function APIPermissionsToJSON(value?: APIPermissions): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'classrooms': ClassroomsGeneralPermissionsToJSON(value.classrooms),
        'library': LibraryGeneralPermissionsToJSON(value.library),
        'mentorship': MentorshipGeneralPermissionsToJSON(value.mentorship),
        'organizations': OrganizationsGeneralPermissionsToJSON(value.organizations),
        'profile': ProfilesGeneralPermissionsToJSON(value.profile),
    };
}


