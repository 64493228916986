import { CalendarDate } from 'ui/components/DateInput/CalendarDate';
import { LoginStatusModel, notLoggedInStatus } from './models';
import { EnableLxLoginEnum, LxConfig } from 'global/constants';
import { IconSymbol } from 'ui/components';
import { capitalizeFirstLetter } from 'utils';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { History } from 'history';

export interface OAuth2TokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    scope: string;
    token_type: string;
}

export interface OAuth2ErrorResponse {
    error?: string;
    error_description?: string;
}

export class HttpError extends Error {
    public status: number;

    constructor(message: string, status: number) {
        super(message);
        this.status = status;
    }
}

export enum AuthProviderEnum {
    Google = 'google',
    Facebook = 'facebook',
    Edx = 'edx',
    Email = 'email',
}

export const checkResponse = async (response: Response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else if (response.status === 401) {
        // TODO: use gettext here
        throw new HttpError('Invalid Username or Password', response.status);
    } else {
        throw new HttpError('Error logging in, please try again', response.status);
    }
};

/**
 * Interface to provide consistent access to the localStorage variables
 * where we cache the user's OAuth2 authentication token as well as the
 * user's name and username.
 */
class AuthLocalStorageManager {
    get authToken(): string|null { return window.localStorage.getItem('auth_token'); }
    set authToken(token: string|null) { this.storeValue('auth_token', token); }
    get authRedirectTo(): string|null { return window.localStorage.getItem('auth_redirect_to'); }
    set authRedirectTo(redirectTo: string|null) { this.storeValue('auth_redirect_to', redirectTo); }
    set authRedirectToLocation(location: Location) {
        const {pathname, search, hash} = location;
        this.authRedirectTo = `${pathname}${search}${hash}`;
    }
    get username(): string|null { return window.localStorage.getItem('auth_username'); }
    set username(username: string|null) { this.storeValue('auth_username', username); }
    get name(): string|null { return window.localStorage.getItem('auth_name'); }
    set name(name: string|null) { this.storeValue('auth_name', name); }
    get email(): string|null { return window.localStorage.getItem('auth_email'); }
    set email(email: string|null) { this.storeValue('auth_email', email); }
    get avatarUrl(): string|null { return window.localStorage.getItem('auth_avatar_url'); }
    set avatarUrl(avatarUrl: string|null) { this.storeValue('auth_avatar_url', avatarUrl); }
    get onboardingStatus(): string|null {return window.localStorage.getItem('onboarding_status'); }
    set onboardingStatus(status: string|null) { this.storeValue('onboarding_status', status); }
    get role(): string|null { return window.localStorage.getItem('user_role'); }
    set role(role: string|null) { this.storeValue('user_role', role); }
    get userBirthdate(): CalendarDate|null {
        const date = window.localStorage.getItem('userBirthdate');
        if (date === null || date.length !== 'YYYY-MM-DD'.length || date.charAt(4) !== '-') {
            return null;
        }
        return CalendarDate.fromIsoString(date);
    }
    set userBirthdate(date: CalendarDate|null) {
        if (date) {
            window.localStorage.setItem('userBirthdate', date.isoString);
        } else {
            window.localStorage.removeItem('userBirthdate');
        }
    }
    get hasVerifiedEmail(): boolean|null { return window.localStorage.getItem('has_verified_email') === 'true'; }
    set hasVerifiedEmail(value: boolean|null) {
        if (value) {
            window.localStorage.setItem('has_verified_email', 'true');
        }
        else {
            window.localStorage.setItem('has_verified_email', 'false');
        }
    }
    get needsAuthMigration(): boolean|null { return window.localStorage.getItem('needs_auth_migration') === 'true'; }
    set needsAuthMigration(value: boolean|null) {
        if (value) {
            window.localStorage.setItem('needs_auth_migration', 'true');
        }
        else {
            window.localStorage.setItem('needs_auth_migration', 'false');
        }
    }
    get viewedEmailVerificationStep(): boolean|null { return window.localStorage.getItem('viewed_email_verification_step') === 'true'; }
    set viewedEmailVerificationStep(value: boolean|null) {
        if (value) {
            window.localStorage.setItem('viewed_email_verification_step', 'true');
        }
        else {
            window.localStorage.setItem('viewed_email_verification_step', 'false');
        }
    }
    private storeValue(key: string, value: string|null) {
        if (value === null) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, value);
        }
    }
    get signUpMedium(): string|null { return window.localStorage.getItem('sign_up_medium'); }
    set signUpMedium(value: string|null) { if(value) window.localStorage.setItem('sign_up_medium', value ); }
    get viewedRoleSelectionStep(): boolean|null { return window.localStorage.getItem('viewed_role_selection_step') === 'true'; }
    set viewedRoleSelectionStep(value: boolean|null) {
        if (value) {
            window.localStorage.setItem('viewed_role_selection_step', 'true');
        }
        else {
            window.localStorage.setItem('viewed_role_selection_step', 'false');
        }
    }
    get viewedJoinAClassStep(): boolean|null { return window.localStorage.getItem('viewed_join_a_class_step') === 'true'; }
    set viewedJoinAClassStep(value: boolean|null) {
        if (value) {
            window.localStorage.setItem('viewed_join_a_class_step', 'true');
        }
        else {
            window.localStorage.setItem('viewed_join_a_class_step', 'false');
        }
    }
    get signedUpUsingClassCode(): string|null { return window.localStorage.getItem('signed_up_using_class_code'); }
    set signedUpUsingClassCode(value: string | null) {
        if (value) {
            window.localStorage.setItem('signed_up_using_class_code', value);
        } else {
            window.localStorage.removeItem('signed_up_using_class_code');
        }
    }
    get signupUsingJoinClassButton(): boolean|null { return window.localStorage.getItem('signup_using_join_class_button') === 'true'; }
    set signupUsingJoinClassButton(value: boolean|null) {
        if (value) {
            window.localStorage.setItem('signup_using_join_class_button', 'true');
        }
        else {
            window.localStorage.setItem('signup_using_join_class_button', 'false');
        }
    }
    get hasClass(): boolean|null { return window.localStorage.getItem('hasClass') === 'true'; }
    set hasClass(value: boolean|null) {
        if (value) {
            window.localStorage.setItem('hasClass', 'true');
        }
        else {
            window.localStorage.setItem('hasClass', 'false');
        }
    }
}
export const authLocalStorageManager = new AuthLocalStorageManager();

export const getInitialLoginState = (): LoginStatusModel => {
    // If the user is *just* in the process of logging in, they'll
    // be redirected from the LMS back to LabXchange and an OAuth
    // token will be in the URL hash. Record that now:
    const hashToken = getLoginTokenFromHash(window.location.hash);
    if (hashToken) {
        authLocalStorageManager.authToken = hashToken;
        // However, we don't yet know their name/username, so
        // we can't yet show the user as logged in. We'll return
        // that they aren't logged in and wait for main.tsx to
        // dispatch checkLoginStatus() which will get their name/username
        // and then mark them as logged in:
        return notLoggedInStatus;
    }
    // If the browser has a cached auth token, then we assume
    // the user is correclty logged in and proceed with setting
    // the state accordingly. However, we will also double-check
    // that the user is actually logged in: main.tsx will dispatch
    // checkLoginStatus() which will asynchronously use an API to
    // verify the user's login status and then update the state.
    const token = authLocalStorageManager.authToken;
    const name = authLocalStorageManager.name;
    const email = authLocalStorageManager.email;
    const username = authLocalStorageManager.username;
    const avatarUrl = authLocalStorageManager.avatarUrl;
    const onboardingStatus = authLocalStorageManager.onboardingStatus;
    const userBirthdate = authLocalStorageManager.userBirthdate;
    const role = authLocalStorageManager.role;
    const hasVerifiedEmail = authLocalStorageManager.hasVerifiedEmail;
    const needsAuthMigration = authLocalStorageManager.needsAuthMigration;
    const hasClass = authLocalStorageManager.hasClass;
    if (token && name && username && email) {
        return {
            name,
            email,
            token,
            username,
            avatarUrl: avatarUrl? avatarUrl: '',
            loginErrorMessage: null,
            onboardingStatus,
            dateOfBirth: userBirthdate?.isoString || null,
            role,
            acceptedCookieAgreement: undefined,
            hasVerifiedEmail: hasVerifiedEmail || false,
            needsAuthMigration: needsAuthMigration || false,
            hasClass: hasClass || false,
            curriculumGroup: undefined,
        };
    } else {
        // The user is definitely not logged in.
        return notLoggedInStatus;
    }
};

export const getLoginTokenFromHash = (hash: string): string|null => {
    const match = hash.match('[#&]access_token=([^&]*)');
    return match && decodeURIComponent(match[1]);
};

export const getAuthHeaders = () => {
    return {
        authorization: `Bearer ${authLocalStorageManager.authToken}`,
    };
};

export const isLxLoginActive = () => {
    return LxConfig.EnableLxLogin === EnableLxLoginEnum.Debug ||
            LxConfig.EnableLxLogin === EnableLxLoginEnum.Production;
};

export const isLxLoginActiveOnDebug = () => {
    return LxConfig.EnableLxLogin === EnableLxLoginEnum.Debug;
};

export const isLxLoginActiveOnProd = () => {
    return LxConfig.EnableLxLogin === EnableLxLoginEnum.Production;
};

/**
 * Verifies if a password is valid
 *
 * You need to keep this validation sync with the backend validators (See `sign_up_user` view)
 */
export const validatePassword = (password: string | undefined) => {
    if (password === undefined || password.length < 10) {
        return false;
    }
    return true;
};

export const getProviderIcon = (provider: AuthProviderEnum): IconSymbol => {
    if (provider === AuthProviderEnum.Facebook) {
        return 'facebook-color';
    }
    return provider.toString() as IconSymbol;
};

export function providerToString(provider: AuthProviderEnum): string {
    if (provider === AuthProviderEnum.Edx) {
        return 'edX';
    }
    return capitalizeFirstLetter(provider.toString());
}



function useAddQueryParam(key:string, value:string) {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const currentUrlParams = new URLSearchParams(location.search);
        currentUrlParams.set(key, value);
        history.replace(`${location.pathname}?${currentUrlParams.toString()}`);
    }, [key, value]);
}

export default useAddQueryParam;

export function insertQueryParam(history: History, key: string, value: string): void {
    const currentUrlParams = new URLSearchParams(history.location.search);
    currentUrlParams.set(key, value);
    history.replace(`${history.location.pathname}?${currentUrlParams.toString()}`);
}

export enum ProgressPercentageEnum {
    SignupPage = 20,
    EmailActivation = 40,
    BirthdayInput = 40,
    RoleSelection = 60,
    JoinAClass = 80,
    EducatorPreferences = 80
}
