import * as React from 'react';

import messages from '../../displayMessages';
import { BlockProps } from './models';
import { RichText } from 'elements';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { createAssetViewedEventForItem, shouldAutoTrackAssetView } from './utils';
import { WrappedMessage } from 'utils';

export class NarrativeBlock extends React.PureComponent<BlockProps, {}> {

    public componentDidMount() {
        if (shouldAutoTrackAssetView(this.props.itemMetadata.id)) {
            createAssetViewedEventForItem(this.props.itemMetadata.id);
        }
    }

    public render() {
        const keyPoints = this.props.blockData.studentViewData.key_points;
        const narrative = this.props.blockData.studentViewData.narrative;

        return (
            <div className='rich-block-content' dir='auto'>
                <div className='narrative-block-student-view'>
                    {keyPoints ?
                        <div className='narrative-block-key-points'>
                            <div className='narrative-block-key-points-heading'>
                                <WrappedMessage message={messages.blockNarrativeKeyPoints} />
                            </div>
                            <div className='narrative-block-key-points-details' dir='auto'>
                                <RichText
                                    innerHtml={keyPoints}
                                    sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                                    renderMath={true}
                                    renderCode={true}
                                />
                            </div>
                        </div> : null
                    }
                    {narrative ?
                        <div className='narrative-block-narrative' dir='auto'>
                            <RichText
                                innerHtml={narrative}
                                sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                                renderMath={true}
                                renderCode={true}
                             />
                         </div> : null
                    }
                </div>
            </div>
        );
    }
}
