import { intl } from 'i18n';
import * as React from 'react';
import messages from '../displayMessages';
import { NavLink } from 'react-router-dom';
import footerMessages from 'ui/components/Footer/displayMessages';
import { ROUTES } from 'global/constants';
import { MessageDescriptor } from 'react-intl';

interface Props {
    type: 'sign-in' | 'sign-up';
    clickOnMessage?: MessageDescriptor;  /// Used on sign-in
    clickOnMessageValues?: any;
}

export const TermsAndPolicyComponent: React.FunctionComponent<Props> = (props) => {
    return (
        <div className='auth-terms-policy-text-component'>
        {props.type === 'sign-in' && props.clickOnMessage &&
            <>
                {intl.formatMessage(messages.lxAuthSignInPolicyMsgComplete, {
                    msg: intl.formatMessage(props.clickOnMessage, props.clickOnMessageValues),
                    terms: <NavLink to={ROUTES.General.TOS}>{intl.formatMessage(footerMessages.footerTermsOfService)}</NavLink>,
                    privacy: <NavLink to={ROUTES.General.PRIVACY_POLICY}>{intl.formatMessage(footerMessages.footerPrivacyPolicy)}</NavLink>
                })}
            </>
        }
        {props.type === 'sign-up' &&
            <>
                {intl.formatMessage(messages.lxAuthSignUpPolicyMsgComplete, {
                    terms: <NavLink className='tos' to={ROUTES.General.TOS}>{intl.formatMessage(footerMessages.footerTermsOfService)}</NavLink>,
                    privacyNotice: <NavLink to={ROUTES.General.PRIVACY_POLICY}>{intl.formatMessage(footerMessages.footerPrivacyPolicy)}</NavLink>
                })}
            </>
        }
        </div>
    );
};
