import * as React from 'react';
import bind from 'bind-decorator';
import { CollapsibleWithChevron } from 'elements/components/Containers';
import {
    ItemMetadata,
    ItemTranslation,
} from 'labxchange-client';
import { Icon } from 'ui/components';
import messages from 'library/displayMessages';
import { WrappedMessage } from 'utils';
import { TranslationSelectorModal } from 'library/components';
import { LanguageItem } from 'library/components/TranslationSelectorModal/TranslationSelectorModal';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { intl } from 'i18n';
import { RichText } from 'elements';


interface AudioTranscripts {
    availableLanguages: {code: string, languageNative: string}[];
    queriedTranscripts: Map<string, string>;
    defaultLanguage: string;
}

interface AudioTranscriptProps {
    itemMetadata: ItemMetadata;
    audioTranscripts: AudioTranscripts;
}

interface AudioTranscriptState {
    freeze: boolean;
    translations: LanguageItem[];
    displayModal: boolean;
    transcripts: string;
    languages: {code: string, languageNative: string}[];
    queriedTranscripts: Map<string, string>;
}

export class AudioTranscript extends React.Component<AudioTranscriptProps, AudioTranscriptState> {

    constructor(props: AudioTranscriptProps) {
        super(props);
        this.state = {
            freeze: false,
            displayModal: false,
            translations: [],
            transcripts: '',
            languages: [],
            queriedTranscripts: new Map()
        };
    }

    componentDidMount() {
        const queriedTranscripts = this.props.audioTranscripts.queriedTranscripts;
        const languages = this.props.audioTranscripts.availableLanguages;
        const transcripts = queriedTranscripts.get(this.props.audioTranscripts.defaultLanguage) || '';
        this.setState({
            queriedTranscripts,
            languages,
            transcripts
        });
    }

    render() {
        if (this.state.transcripts.length === 0 ) {
            return null;
        }
        return (
            <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                className='transcripts-widget mt-4 mb-1'
                onMouseEnter={() => this.setState({ freeze: true })}
                onMouseLeave={() => this.setState({ freeze: false })}
                role='region'
            >
                <CollapsibleWithChevron
                    title={intl.formatMessage(messages.videoTranscriptsHeader)}
                    defaultOpen={false}
                    headerActions={this.headerActions()}
                >
                    <RichText
                        innerHtml={this.state.transcripts}
                        sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                        renderMath={true}
                        renderCode={true}
                    />
                </CollapsibleWithChevron>
                {this.state.displayModal ?
                    <TranslationSelectorModal
                        translations={this.getTranslations()}
                        metadata={this.props.itemMetadata}
                        onClose={() => this.closeModal()}
                        onConfirm={this.loadLanguageTranscript}
                    />
                : null}
            </div>
        );
    }

    /**
     * If languages doesn't exists - provide empty span element
     */
    private headerActions() {
        if (this.state.languages.length > 1) {
            return (<button
                    className='btn lx-btn-unstyled translation-switcher'
                    onClick={this.translationSwitchHandler}
                >
                    <Icon name='language' />
                    <WrappedMessage message={messages.videoTranscriptsTranslate} />
                </button>
            );
        }
        return <span></span>;
    }

    @bind getTranslations(): LanguageItem[] {
        return this.state.languages.map(lang => ({
            language: lang.code,
            languageNative: lang.languageNative
        }));
    }

    @bind private closeModal() {
        this.setState({ displayModal: false });
    }

    @bind private loadLanguageTranscript(language: ItemTranslation) {
        this.findLanguageTranscript(language.language);
        this.closeModal();
    }

    @bind private translationSwitchHandler(event: any) {
        this.setState({ displayModal: true });
        event.stopPropagation();
    }

    @bind public async findLanguageTranscript(language: string) {
        const transcripts = this.state.queriedTranscripts.get(language) || '';
        this.setState({ transcripts });
    }
}
