import { getLoggedInStatus, getUserPermissions } from 'auth/selectors';
import bind from 'bind-decorator';
import classNames from 'classnames';
import { Icon } from 'elements';
import { ExploreApi } from 'global/api';
import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import { APIPermissions, FeaturedItems, ItemResponse } from 'labxchange-client';
import { Card, CardProps } from 'library/components/Card';
import { detailUrlForEntity } from 'library/utils';
import * as React from 'react';
import { MessageDescriptor } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';
import { intl } from 'i18n';
import messages from '../../displayMessages';
import { UI_IS_XS } from '../../../ui/breakpoints';
import uiMessages from 'ui/components/displayMessages';

interface NameString {
    [key: string]: MessageDescriptor;
}

const nameToString : NameString = {
    learn: messages.exploreMoreLearn,
    teach: messages.exploreMoreTeach,
    research: messages.exploreMoreResearch
};

export interface Category {
    nameMessage: MessageDescriptor;
    slug: string;
    subCategories: SubCategory[];
}

export interface SubCategory {
    nameMessage: MessageDescriptor;
    slug: string;
    icon?: string;
    iconOutline?: string;
}

interface ExploreSidebarProps {
    updateSelectedCategory(subCategory: SubCategory): void;
}

interface ExploreSidebarState {
    initialState: boolean;
    selectedCategory: Category;
    selectedSubCategory: SubCategory;
}

export class ExploreSidebar extends React.PureComponent<ExploreSidebarProps, ExploreSidebarState> {
    private categories: Category[] = [
        {
            nameMessage: messages.featuredSubjectsCategory,
            slug: 'featured-subjects',
            subCategories: [
                {
                    nameMessage: messages.introductinToLabXchangeSubCategory,
                    slug: 'introduction-to-labxchange',
                    icon: 'introduction-to-labxchange.png',
                    iconOutline: 'introduction-to-labxchange-outline.png',
                }, {
                    nameMessage: messages.biologicalSciencesSubCategory,
                    slug: 'biological-sciences',
                    icon: 'biological-sciences.svg',
                    iconOutline: 'biological-sciences-outline.svg',
                }, {
                    nameMessage: messages.chemistrySubCategory,
                    slug: 'chemistry',
                    icon: 'chemistry.svg',
                    iconOutline: 'chemistry-outline.svg',
                }, {
                    nameMessage: messages.globalHealthSubCategory,
                    slug: 'global-health',
                    icon: 'global-health.svg',
                    iconOutline: 'global-health-outline.svg',
                }, {
                    nameMessage: messages.healthScienceSubCategory,
                    slug: 'health-science',
                    icon: 'health-science.svg',
                    iconOutline: 'health-science-outline.svg',
                }, {
                    nameMessage: messages.scienceAndSocietySubCategory,
                    slug: 'science-and-society',
                    icon: 'science-and-society.svg',
                    iconOutline: 'science-and-society-outline.svg',
                }, {
                    nameMessage: messages.scientificProcessSubCategory,
                    slug: 'scientific-process',
                    icon: 'scientific-process.svg',
                    iconOutline: 'scientific-process-outline.svg',
                }, {
                    nameMessage: messages.prepareForCollegeSubCategory,
                    slug: 'prepare-for-college',
                    icon: 'prepare-for-college.svg',
                    iconOutline: 'prepare-for-college-outline.svg',
                }, {
                    nameMessage: messages.prepareForGraduateSchoolSubCategory,
                    slug: 'prepare-for-graduate-school',
                    icon: 'prepare-for-graduate-school.svg',
                    iconOutline: 'prepare-for-graduate-school-outline.svg',
                }, {
                    nameMessage: messages.prepareForCareersSubCategory,
                    slug: 'prepare-for-career',
                    icon: 'prepare-for-career.svg',
                    iconOutline: 'prepare-for-career-outline.svg',
                }, {
                    nameMessage: messages.professionalDevelopmentSubCategory,
                    slug: 'professional-development',
                    icon: 'professional-development.svg',
                    iconOutline: 'professional-development-outline.svg',
                },
            ],
        }, {
            nameMessage: messages.contentTypeCategory,
            slug: 'content-type',
            subCategories: [
                {
                    nameMessage: messages.clusterSubCategory,
                    slug: 'cluster',
                    icon: 'cluster.svg',
                    iconOutline: 'cluster-outline.svg',
                }, {
                    nameMessage: messages.pathwaySubCategory,
                    slug: 'pathway',
                    icon: 'pathway.svg',
                    iconOutline: 'pathway-outline.svg',
                }, {
                    nameMessage: messages.interactiveSubCategory,
                    slug: 'scrollable',
                    icon: 'scrollable.svg',
                    iconOutline: 'scrollable-outline.svg',
                }, {
                    nameMessage: messages.simulationSubCategory,
                    slug: 'simulation',
                    icon: 'simulation.svg',
                    iconOutline: 'simulation-outline.svg',
                }, {
                    nameMessage: messages.videoSubCategory,
                    slug: 'video',
                    icon: 'video.svg',
                    iconOutline: 'video-outline.svg',
                }, {
                    nameMessage: messages.narrativeSubCategory,
                    slug: 'narrative',
                    icon: 'narrative.svg',
                    iconOutline: 'narrative-outline.svg',
                }, {
                    nameMessage: messages.caseStudySubCategory,
                    slug: 'case-study',
                    icon: 'case-study.svg',
                    iconOutline: 'case-study-outline.svg',
                }, {
                    nameMessage: messages.documentSubCategory,
                    slug: 'document',
                    icon: 'document.svg',
                    iconOutline: 'document-outline.svg',
                }, {
                    nameMessage: messages.assignmentSubCategory,
                    slug: 'assignment',
                    icon: 'assignment.svg',
                    iconOutline: 'assignment-outline.svg',
                }, {
                    nameMessage: messages.teachingGuideSubCategory,
                    slug: 'teaching-guide',
                    icon: 'case-study.svg',
                    iconOutline: 'case-study-outline.svg',
                }, {
                    nameMessage: messages.textSubCategory,
                    slug: 'text',
                    icon: 'text.svg',
                    iconOutline: 'text-outline.svg',
                }, {
                    nameMessage: messages.imageSubCategory,
                    slug: 'image',
                    icon: 'image.svg',
                    iconOutline: 'image-outline.svg',
                }, {
                    nameMessage: messages.audioSubCategory,
                    slug: 'audio',
                    icon: 'audio.svg',
                    iconOutline: 'audio-outline.svg',
                },
            ],
        }, {
            nameMessage: messages.contentSourcesCategory,
            slug: 'content-sources',
            subCategories: [
                {
                    nameMessage: messages.abeSubCategory,
                    icon: 'abe.svg',
                    slug: 'abe',
                },
                {
                    nameMessage: messages.khanAcademySubCategory,
                    icon: 'khan-academy.png',
                    slug: 'khan-academy',
                }, {
                    nameMessage: messages.iBiologySubCategory,
                    icon: 'ibiology.png',
                    slug: 'ibiology',
                }, {
                    nameMessage: messages.phETSubCategory,
                    icon: 'phet.png',
                    slug: 'phet',
                }, {
                    nameMessage: messages.harvardUDMCBSubCategory,
                    icon: 'mcb.png',
                    slug: 'mcb',
                }, {
                    nameMessage: messages.theJournalOfStoriesInScienceSubCategory,
                    icon: 'stories-in-science.png',
                    slug: 'the-journal-of-stories-in-science',
                }, {
                    nameMessage: messages.wellcomeGenomeCampusSubCategory,
                    icon: 'wellcome-genome-campus.png',
                    slug: 'wellcome-genome-campus',
                }, {
                    nameMessage: messages.harvardXSubCategory,
                    slug: 'harvardx',
                    icon: 'harvardx.png',
                }, {
                    nameMessage: messages.scienceIRLSubCategory,
                    slug: 'science-irl',
                    icon: 'science-irl.png',
                }, {
                    nameMessage: messages.pgEdSubCategory,
                    icon: 'pged.png',
                    slug: 'pged',
                }, {
                    nameMessage: messages.theConcordConsortiumSubCategory,
                    slug: 'the-concord-consortium',
                    icon: 'concord-consortium.png',
                }, {
                    nameMessage: messages.bioBuilderSubCategory,
                    slug: 'bio-builder',
                    icon: 'biobuilder.png',
                }, {
                    nameMessage: messages.nhgriSubCategory,
                    slug: 'nhgri',
                    icon: 'nhgri.png',
                },
            ],
        }, {
            nameMessage: messages.communityFavoritesCategory,
            slug: 'community-favorites',
            subCategories: [],
        }, {
            nameMessage: messages.trendingTopicsCategory,
            slug: 'trending-topics',
            subCategories: [],
        },
    ];

    constructor(props: ExploreSidebarProps) {
        super(props);
        this.state = {
            initialState: true,
            selectedCategory: this.categories[0],
            selectedSubCategory: this.categories[0].subCategories[0],
        };
    }

    public renderHeadline() {
        return (
            <div className='headline'>
                <WrappedMessage message={messages.exploreByBaseline}/>
            </div>
        );
    }

    public renderCategories() {
        if (this.state.initialState) {
            return (
                <div className='categories categories-initial-state'>
                {this.categories.map((category) => {
                    const isSelected = this.state.selectedCategory.slug === category.slug;
                    const hasSubCategories = category.subCategories.length >= 1;
                    const iconStyle = {
                        backgroundImage: `url('/assets/images/icons/${category.slug}${isSelected ? '' : '-outline'}.svg')`,
                    };
                    return (
                        <button key={category.slug}
                            tabIndex={0}
                            onClick={() => {this.handleCategoryClick(category.slug); }}
                            aria-pressed={isSelected ? true : false}
                            className={classNames('category',
                            {'category-selected': isSelected},
                            {'category-with-subcategories': hasSubCategories})}>
                            <div style={iconStyle} className='category-icon'></div>
                            <div className='category-name'><WrappedMessage message={category.nameMessage}/></div>
                            {hasSubCategories
                            ? <Icon name='chevron-right' zoom='16' className='category-back-icon'/>
                            : null}
                        </button>
                    );
                })}
                </div>
            );
        } else {
            const iconStyle = {
                backgroundImage: `url('/assets/images/icons/${this.state.selectedCategory.slug}.svg')`,
            };
            return (
                <div className='categories categories-not-initial-state'>
                    <button className='category category-inside'
                         onClick={() => this.handleGoBack()}>
                        <div className='explore-go-back-button'>
                            <Icon name='chevron-left' zoom='32' />
                        </div>
                        <div style={iconStyle}
                             className='category-icon'>
                        </div>
                        <div className='category-name'>
                            <WrappedMessage message={this.state.selectedCategory.nameMessage}/>
                        </div>
                    </button>
                    {this.renderSubCategories()}
                </div>
            );
        }
    }

    public renderSubCategories() {
        return (
            <div className='sub-categories'>
                {this.state.selectedCategory.subCategories.map((subCategory) => {
                    const isSelected = this.state.selectedSubCategory.slug === subCategory.slug;
                    const iconStyle = {
                        backgroundImage: `url('/assets/images/icons/${subCategory.icon}')`,
                    };
                    if (!isSelected && subCategory.iconOutline !== undefined) {
                        iconStyle.backgroundImage = `url('/assets/images/icons/${subCategory.iconOutline}')`;
                    }
                    return (
                        <button key={subCategory.slug} tabIndex={0}
                            aria-pressed={isSelected ? true : false}
                            className={classNames('sub-category', {'sub-category-selected': isSelected})}
                            onClick={() => {this.handleSubCategoryClick(subCategory.slug); }}>
                            <div style={iconStyle}
                                 className={classNames(
                                     'sub-category-icon',
                                     'd-none',
                                     'd-md-block',
                                     {'sub-category-icon-filtered': subCategory.iconOutline},
                                 )}>
                            </div>
                            <div className='sub-category-name'>
                                <WrappedMessage message={subCategory.nameMessage}/>
                            </div>
                        </button>
                    );
                })}
            </div>
        );
    }

    public render() {
        return (
            <div className='explore-sidebar'>
                {this.renderHeadline()}
                {this.renderCategories()}
            </div>
        );
    }

    public async componentDidMount() {
        await this.fetchCurrentItems();
    }

    public async fetchCurrentItems() {
        if (this.state.selectedSubCategory === undefined) {
            if (this.state.selectedCategory !== undefined) {
                this.props.updateSelectedCategory(this.state.selectedCategory);
            }
        } else {
            this.props.updateSelectedCategory(this.state.selectedSubCategory);
        }
    }

    private handleGoBack() {
        this.setState({initialState: true});
    }

    private handleCategoryClick(categoryName: string) {
        this.categories.forEach((category) => {
            if (category.slug === categoryName) {
                this.setState({
                    initialState: category.subCategories.length ? false : true,
                    selectedCategory: category,
                    selectedSubCategory: category.subCategories[0],
                }, () => {
                    this.fetchCurrentItems();
                });
                return;
            }
            return;
        });
    }

    private handleSubCategoryClick(subCategoryName: string) {
        this.state.selectedCategory.subCategories.forEach((subCategory) => {
            if (subCategory.slug === subCategoryName) {
                this.setState({selectedSubCategory: subCategory,
                }, () => {
                    this.fetchCurrentItems();
                });
                return;
            }
            return;
        });
    }
}

interface ExploreItemsProps {
    category?: Category|SubCategory;
    currentItems?: FeaturedItems;
    userPermissions?: APIPermissions;
    isLoggedin?: boolean;
    isNewHomePage?: boolean;
}

interface ExploreItemState {
    hideResourcesButton: boolean;
}

export class ExploreItems extends React.PureComponent<ExploreItemsProps, ExploreItemState> {

    public constructor(props: ExploreItemsProps) {
        super(props);
        this.state = {
            hideResourcesButton: this.isMobileBreakpoint(),
        };
    }

    public componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    @bind private isMobileBreakpoint() {
        return UI_IS_XS.matches;
    }

    @bind private onResize() {
        this.setState({
            hideResourcesButton: this.isMobileBreakpoint(),
        });
    }

    @bind private getCategoryString(name: string): MessageDescriptor {
        return nameToString[name.toLowerCase()];
    }

    public render() {
        if (this.props.category === undefined || this.props.currentItems === undefined) {
            return (
                <div className='explore-cards'>
                    <div className='category-description'>
                        <WrappedMessage message={messages.categoryContentEmptyState}/></div>
                </div>
            );
        }

        const cardItems = this.cardsStateFromData().map((cardProps, index) => (
            <li key={index}><Card {...cardProps} /></li>
        ));

        const categoryString = this.getCategoryString(this.props.currentItems.name);

        const moreResourcesButton =
            <Link
                to={this.props.currentItems.seeMoreLink ? this.props.currentItems.seeMoreLink : ROUTES.Library.HOME}
                className='btn btn-primary explore-more-button'>
                <WrappedMessage message={messages.exploreMoreResourcesButton} values={{category: <WrappedMessage message={categoryString} />}}/>
            </Link>;

        const categoryNameMessage = this.props.category.slug === 'introduction-to-labxchange' ?
            uiMessages.defaultPageTitle : this.props.category.nameMessage;

        return (
            <div className='explore-cards'>
                {this.props.isNewHomePage ?
                  <div className='new-explore-header'>
                    <div>
                      <div className='category-title'>
                        <WrappedMessage message={this.props.category.nameMessage}/>
                      </div>
                      <div className='category-description'>
                          {this.props.currentItems.description}
                      </div>
                    </div>
                      {!this.state.hideResourcesButton &&
                        <div>
                            {moreResourcesButton}
                        </div>
                      }
                  </div> :
                    <>
                        <div className='category-title'>
                            <WrappedMessage message={this.props.category.nameMessage}/>
                        </div>
                        <div className='category-description'>
                            {this.props.currentItems.description}
                        </div>
                    </>
                }

                <div className='category-cards'>
                    <ul className='cards-list list-unstyled'>
                        {cardItems}
                    </ul>
                </div>
                {!this.props.isNewHomePage &&
                  <Link to={
                      this.props.currentItems.seeMoreLink?
                          this.props.currentItems.seeMoreLink :
                          this.props.page === 'home' ? ROUTES.Explore.HOME : ROUTES.Library.HOME
                  } className='btn btn-primary explore-more-button'>
                    <WrappedMessage message={ this.props.page === 'home' ? messages.exploreMoreHomeButton : messages.exploreMoreButton}/>
                    <span className='sr-only'>
                        <WrappedMessage
                            message={messages.exploreMoreResourcesSRMessage}
                            values={{categoryName: <WrappedMessage message={categoryNameMessage} />}}
                        />
                    </span>
                  </Link>
                }

                {(this.props.isNewHomePage && this.state.hideResourcesButton) &&
                    moreResourcesButton
                }
            </div>
        );
    }

    private cardsStateFromData() {
        if (this.props.currentItems === undefined) {return ([]); }
        const items = this.props.currentItems.items;
        const cards: CardProps[] = items.map((item: ItemResponse) => {
            const props: CardProps = {
                detailUrl: detailUrlForEntity(item.metadata),
                metadata: item.metadata,
                userAttributes: item.userAttributes,
                mobileViewMode: true,
                showMenuButton: true,
                isPublicContentOnly: true,
                isUserLoggedIn: this.props.isLoggedin,
                userPermissions: this.props.userPermissions,
            };
            return props;
        });

        return cards;
    }
}

interface ReduxStateProps {
    userPermissions?: APIPermissions;
    isLoggedin?: boolean;
}

interface ExploreContentProps extends ReduxStateProps {
    category?: Category|SubCategory;
    hideSidebar?: boolean;
    isNewHomePage?: boolean;
    page?: string;
}

interface ExploreContentState {
    category?: Category|SubCategory;
    currentItems?: FeaturedItems;
}

export class ExploreContentInternal extends React.PureComponent<ExploreContentProps, ExploreContentState> {
    public static defaultProps = {
        hideSidebar: false,
        page: 'explore',
    };

    constructor(props: ExploreContentProps) {
        super(props);
        this.state = {};
    }

    public async componentDidMount() {
        if (this.props.category) {
            this.updateSelectedCategory(this.props.category);
        }
    }

    @bind public async updateSelectedCategory(category: Category|SubCategory) {
        this.setState({category});

        let currentItems: FeaturedItems|undefined;
        try {
            currentItems = await ExploreApi.read({
                id: category.slug,
            });
        } catch (err) {
            // 404 means no matching items, and so is not an error.
            if (err.status !== 404) {
                showErrorMessage(<WrappedMessage message={messages.featuredItemsError} />, {exception: err});
            }
        }

        // If a different subcategory was set while we were waiting, don't override it.
        if (this.state.category && (this.state.category.slug === category.slug)) {
            this.setState({currentItems});
        }
    }

    public render() {
        return (
            <div className='explore'>
                {this.props.hideSidebar ? null : <ExploreSidebar updateSelectedCategory={this.updateSelectedCategory}/>}
                <ExploreItems
                    category={this.state.category}
                    currentItems={this.state.currentItems}
                    isLoggedin={this.props.isLoggedin}
                    userPermissions={this.props.userPermissions}
                    isNewHomePage={this.props.isNewHomePage}
                    page={this.props.page}
                />
            </div>
        );
    }
}

export const ExploreContent = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
        isLoggedin: getLoggedInStatus(state),
    }),
)(ExploreContentInternal);
