import { APIPermissions } from 'labxchange-client/models/APIPermissions';

/** Login model definitions */

export interface LoginStatusModel {
    loginErrorMessage: null | string;
    /** The full name of the user */
    name: string;
    /** The email of the user */
    email: string;
    /** The OAuth2 token used to call the LabXchange API */
    token: string;
    /** The username of the logged in user */
    username: string;
    /** The avatar URL of the logged in user */
    avatarUrl: string;
    /** The general permissions for the user */
    permissions?: APIPermissions;
    /** The onboarding status for the user */
    onboardingStatus: null | string;
    /** Date of birth for the user */
    dateOfBirth: null | string;
    /** Role for the user */
    role: null | string;
    /** The cookie acceptance status */
    acceptedCookieAgreement: boolean | undefined;
    /** Indicates if the email of the user is verified */
    hasVerifiedEmail: boolean;
    /** Indicates if the user needs auth migration */
    needsAuthMigration: boolean;
    /** Indicates if the user has class(es) */
    hasClass: boolean;
    /** The curriculum group user belongs to */
    curriculumGroup?: string;
}

export const notLoggedInStatus: Readonly<LoginStatusModel> = {
    loginErrorMessage: null,
    onboardingStatus: null,
    dateOfBirth: null,
    role: null,
    name: '',
    email: '',
    token: '',
    username: '',
    avatarUrl: '',
    acceptedCookieAgreement: undefined,
    hasVerifiedEmail: false,
    needsAuthMigration: false,
    hasClass: false,
    curriculumGroup: undefined,
};
