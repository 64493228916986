import { Button, InputSelect, Pagination as ParagonPagination } from '@edx/paragon';
import bind from 'bind-decorator';
import { PAGINATION_SIZE } from 'global/constants';
import * as React from 'react';
import { intl } from 'i18n';

import { WrappedMessage } from 'utils';
import messages from './displayMessages';

interface PaginationSizeOptionsProps {
    name: string;
    value: number;
    options: {label: string, value: number}[];
    onChange: (paginationSize: number) => void;
    hidePerPageSuffix?: boolean;
}

export class PaginationSizeOptions extends React.Component<PaginationSizeOptionsProps> {

    public render() {
        const label = (
            <span className='sr-only'>
                <WrappedMessage message={messages.paginationSizeOptionsLabel}/>
            </span>
        );
        const inputSelect = <InputSelect className='custom-select-pagination' label={label} name={this.props.name}
                             value={this.props.value} options={this.props.options} onChange={this.onChange}/>;

        return (
            <span className='pagination-size-options'>
                <WrappedMessage
                    message={this.props.hidePerPageSuffix ? messages.paginationSizeOptionsTextWithoutSuffix : messages.paginationSizeOptionsText}
                    values={{
                        count_per_page: inputSelect,
                    }}
                />
            </span>
        );
    }

    @bind private onChange(value: string) {
        this.props.onChange(parseInt(value, 10) || PAGINATION_SIZE);
    }
}

interface PaginationProps {
    pageCount: number;
    currentPage: number;
    onPageSelect: (page: number) => void;
}

export class Pagination extends React.Component<PaginationProps> {

    public render() {
        const props = {
            buttonLabels: {
                currentPage: intl.formatMessage(messages.paginationCurrentPageLabel),
                next: intl.formatMessage(messages.paginationNextLabel),
                page: intl.formatMessage(messages.paginationPageLabel),
                pageOfCount: intl.formatMessage(messages.paginationPageOfCountLabel),
                previous: intl.formatMessage(messages.paginationPreviousLabel),
            },
            className: 'pagination-wrapper',
            paginationLabel: intl.formatMessage(messages.paginationAriaLabel),
            ...this.props,
        };

        return (
            <ParagonPagination { ...props } />
        );
    }
}

interface JumpToState {
    inputValue: string;
    labelId: number;
}

export class JumpTo extends React.Component<PaginationProps, JumpToState> {
    static uniqueId = 0;

    constructor(props: PaginationProps) {
        super(props);
        this.state = {
            inputValue: props.currentPage.toString(),
            labelId: JumpTo.uniqueId,
        };
        JumpTo.uniqueId += 1;
    }

    public render() {
        const labelId = this.state.labelId;
        return (
            <div className='pagination-jumpto'>
                <label htmlFor={`paginationJumptoInput${labelId}`}>
                    <WrappedMessage message={messages.paginationJumptoLabel}/>
                </label>
                <div className='input-group'>
                    <input
                        name='pagination_jumpto'
                        id={`paginationJumptoInput${labelId}`}
                        value={this.state.inputValue}
                        className='pagination-jumpto-input form-control'
                        onChange={this.trackInputValue}
                        onKeyPress={this.handleKeyPress}
                    />
                    <Button className='pagination-jumpto-button btn py-0'
                        onClick={this.updatePageSelection}>
                        <WrappedMessage message={messages.paginationGoLabel}/>
                    </Button>
                </div>
            </div>
        );
    }

    public componentDidUpdate(prevProps: PaginationProps) {
        if (this.props.currentPage !== prevProps.currentPage) {
            // If some other component on the page has changed the page number, always update:
            this.setState({inputValue: this.props.currentPage.toString()});
        }
    }

    @bind private trackInputValue(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        // this ensures that both the state and the input will always be either
        // an empty string or a valid page number
        if (value === '' ||
            (/^(?:[1-9][0-9]*)*$/.test(value) && parseInt(value, 10) <= this.props.pageCount)) {
            this.setState({inputValue: value});
        }
    }

    @bind private handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.updatePageSelection();
        }
    }

    @bind private updatePageSelection() {
        const inputValue = this.state.inputValue;
        if (inputValue) {
            const value = parseInt(inputValue, 10);
            // make sure the number is still valid; it is possible to be higher
            // if user enters a high value, then increases the number of items
            // per page, resulting in less pages.
            if (value <= this.props.pageCount) {
                this.props.onPageSelect(value);
            } else {
                this.setState({inputValue: this.props.currentPage.toString()});
            }
        }
    }

}
