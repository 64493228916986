import { bind } from 'bind-decorator';
import * as React from 'react';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { sanitizeUnsafeHTML } from 'elements/utils/sanitization';

import { WrappedMessage } from 'utils';
import messages from '../displayMessages';
import Skeleton from 'react-loading-skeleton';

interface Props {
    text?: string;
    limit: number;
    html?: boolean;
    // If html is set, will use number of lines instead of number of characters as the limit.
    // However this is not reliable; HTMLEllipsis sometimes does not trim enough.
    // So don't assume the number of lines will never exceed this.
    lines?: number;
    showSkeleton?: boolean;
}

interface State {
    expanded: boolean;
    showToggleButton: boolean;
}

/**
 * Renders a chunk of text that is initially limited to a certain length,
 * and can be expanded to be displayed in full.
 */
export class ExpandableText extends React.Component<Props, State> {

    public static defaultProps = {
        limit: 300,
        lines: 3,
        html: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            expanded: false,
            showToggleButton: false,
        };
    }

    public render() {
        // Display full text if we don't exceed the limit, or the block is expanded.
        // Pad the end with whitespace for possible buttons.
        if (this.props.showSkeleton) {
            return <span className='expandable-text'>
                <Skeleton />
            </span>;
        } else if (this.props.text) {
            let displayText;
            if (this.props.html) {
                let lines = this.props.lines || 3;
                if (this.state.expanded) {
                    lines = 1000; // hopefully won't get description any longer than that...
                }
                displayText = <HTMLEllipsis
                    unsafeHTML={sanitizeUnsafeHTML(this.props.text)}
                    maxLine={lines}
                    basedOn='letters'
                    onReflow={this.handleReflow}
                />;
            } else {
                if (this.props.text.length <= this.props.limit || this.state.expanded) {
                    displayText = this.props.text;
                } else {
                    const shortText = this.props.text.substring(0, this.props.limit).trimRight();
                    displayText = `${shortText}… `;
                }
            }

            let showToggles = false;
            if (!this.props.html && this.props.text.length > this.props.limit) {
                showToggles = true;
            } else if (this.props.html) {
                // TODO: how to check if Truncate has actually truncated text? We
                // may need to implement the logic ourselves...
                // This currently means that more/less toggles are *always* shown
                // for html type expandable text.
                showToggles = this.state.showToggleButton;
            }

            return (
                <span className='expandable-text'>
                    {displayText}
                    {showToggles &&
                    <button onClick={this.handleMoreLessButton} className='lx-btn more-less-link'>
                        {
                            this.state.expanded ?
                                <WrappedMessage message={messages.uiShowLess}/> :
                                <WrappedMessage message={messages.uiMore}/>
                        }
                    </button>
                    }
                </span>
            );
        } else {
            return null;
        }
    }

    @bind private handleReflow(options: { clamped: boolean; text: string }) {
        if (options.clamped) {
            // If the text is long enough that it needs to be trimmed, turn on the toggle button.
            if (!this.state.showToggleButton) {
                this.setState({
                    showToggleButton: true
                });
            }
        }
    }

    @bind private handleMoreLessButton(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }
}
