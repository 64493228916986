import * as React from 'react';
import DropdownTreeSelect, {
  TreeNode, DropdownTreeSelectProps
} from 'react-dropdown-tree-select';
import { intl } from 'i18n';

import { useLayoutSize } from 'utils';
import messages from '../displayMessages';

interface TreeDropdownProps extends Omit<DropdownTreeSelectProps, 'data'> {
  data: TreeNode[];
}

const TreeDropdownInternal: React.FC<TreeDropdownProps> = (props) => {
  const { data, ...rest } = props;
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const layoutSize = useLayoutSize();
  const isMobile = ['small'].includes(layoutSize);

  // Set the dropdown styles based on the available space
  // The modal backdrop is used to calculate the available space
  const setDropdownStyles = () => {
    const dropdownElement: any = dropdownRef.current?.querySelector('.dropdown-content');
    const viewportHeight = window.innerHeight;

    if (dropdownElement) {
      const dropdownRect = dropdownRef.current?.getBoundingClientRect();

      if (dropdownRect) {
        const availableHeight = viewportHeight - dropdownRect.bottom - 8;

        if (!isMobile) {
          dropdownElement.style.maxHeight = `${availableHeight}px`;
        }

        if (availableHeight < 150) {
          dropdownElement.style.position = 'relative';
          dropdownElement.style.maxHeight = '300px';
        }

        dropdownElement.style.overflowY = 'auto';
        dropdownElement.style.minWidth = `${dropdownRect.width}px`;
        dropdownElement.style.maxWidth = `${dropdownRect.width}px`;
      }
    }
  };

  // Update the placeholder text based on the selected items
  const updateTagListPlaceholder = () => {
    const tagList = dropdownRef.current?.querySelector('.tag-list');
    if (tagList) {
      const tagItems = tagList.querySelectorAll('.tag-item');
      const placeholderItem = tagList.querySelector('.placeholder');
      if (placeholderItem) {
        const selectedCount = tagItems.length - 1;
        placeholderItem.textContent = selectedCount > 0 ?
          intl.formatMessage(messages.feedbackModalSelectedPOs, { count: selectedCount }) :
          intl.formatMessage(messages.feedbackModalPODropdownPlaceholder);
      }
    }
  };

  // Observe the tag list and update the placeholder text
  React.useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === 'childList' &&
          (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0)
        ) {
          updateTagListPlaceholder();
        }
      });
    });

    const dropdownElement = dropdownRef.current;
    if (dropdownElement) {
      const tagList = dropdownElement.querySelector('.tag-list');
      if (tagList) {
        observer.observe(tagList, {
          childList: true,
        });
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [data]);


  // The dropdown content is added to the DOM only when the dropdown is opened
  // So, we need to observe the dropdown content and set the styles accordingly
  React.useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const node = mutation.addedNodes[0] as HTMLElement;
          if (node.classList && node.classList.contains('dropdown-content')) {
            setDropdownStyles();
          }
        }
      });
    });

    const dropdownElement = dropdownRef.current;
    if (dropdownElement) {
      observer.observe(dropdownElement, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [data]);

  return (
    <div ref={dropdownRef} className='tree-dropdown-wrapper'>
      <DropdownTreeSelect
        className='tree-dropdown'
        data={data}
        mode='multiSelect'
        showPartiallySelected={true}
        keepTreeOnSearch={true}
        inlineSearchInput={true}
        {...rest}
      />
    </div>
  );
};

export const TreeDropdown = React.memo(TreeDropdownInternal, () => true);

export default TreeDropdown;
