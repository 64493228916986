import * as React from 'react';
import { intl } from 'i18n';
import { useHistory } from 'react-router-dom';

import { CareerSuggestedAssets, ItemResponse } from 'labxchange-client';
import { Card, CardProps } from 'library/components';
import { detailUrlForEntity } from 'library/utils';
import { ROUTES } from 'global/constants';
import { isKeyboardEnterEvent } from 'global/utils';
import { Spinner } from 'ui/components';

import messages from '../../displayMessages';

interface AssetCardsSlabProps {
    heading: string;
    slab: CareerSuggestedAssets;
}

export const AssetCardsSlab: React.FC<AssetCardsSlabProps> = (props) => {
  const history = useHistory();

  const cardsStateFromData = (slab?: CareerSuggestedAssets) => {
    if (!slab || !slab.items) return ([]);
      const items: ItemResponse[] = slab.items;
      const cardsDetail: CardProps[] = items.map((item: ItemResponse) => {
          const result: CardProps = {
              detailUrl: detailUrlForEntity(item.metadata),
              metadata: item.metadata,
              userAttributes: item.userAttributes,
              mobileViewMode: true,
              showMenuButton: true,
              isPublicContentOnly: true,
              isAssetCard: true,
          };
          return result;
      });
      return cardsDetail;
  };

  const cards = cardsStateFromData(props.slab);

  const onClickExploreLearnings = () => {
    history.push(ROUTES.Library.HOME);
  };

  return (
    <div className='asset-cards-container'>
      <h4 className='slab-title'>{props.heading}</h4>
      <div className='cards-list'>
        <ul className='cards-ul list-unstyled'>
          {
            cards.length === 0 ? <div data-testid='spinner'><Spinner /></div> :
            <>
              {cards.map((cardProps, index) => (
                  <li key={index} >
                    <Card {...cardProps}/>
                  </li>
              ))}
              <div className='explore-learnings-btn'>
                <button
                  onClick={onClickExploreLearnings}
                  onKeyDown={(e) => isKeyboardEnterEvent(e) && onClickExploreLearnings()}
                  data-testid='explore-learnings-button'
                >
                  {intl.formatMessage(messages.careerSuggestedAssetsExploreLearnings)}
                </button>
              </div>
            </>
          }
        </ul>
      </div>
    </div>
  );
};

export default AssetCardsSlab;
