// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    Grade,
    GradeFromJSON,
    GradeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CurriculumSubject
 */
export interface CurriculumSubject {
    /**
     * 
     * @type {string}
     * @memberof CurriculumSubject
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CurriculumSubject
     */
    name: string;
    /**
     * Get all public grades in curriculum.
     * @type {Array<Grade>}
     * @memberof CurriculumSubject
     */
    readonly grades?: Array<Grade>;
}

export function CurriculumSubjectFromJSON(json: any): CurriculumSubject {
    return {
        'slug': json['slug'],
        'name': json['name'],
        'grades': !exists(json, 'grades') ? undefined : (json['grades'] as Array<any>).map(GradeFromJSON),
    };
}

export function CurriculumSubjectToJSON(value?: CurriculumSubject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'name': value.name,
    };
}


