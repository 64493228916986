// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import {
    CareerCategory,
    CareerCategoryFromJSON,
    CareerCategoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface RelatedCareers
 */
export interface RelatedCareers {
    /**
     * A unique human-readable name, e.g., 'ent-specialist'
     * @type {string}
     * @memberof RelatedCareers
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareers
     */
    title: string;
    /**
     * SVG icon for the career.
     * @type {string}
     * @memberof RelatedCareers
     */
    readonly icon?: string;
    /**
     * Get categories associated with a career.
     * @type {Array<CareerCategory>}
     * @memberof RelatedCareers
     */
    readonly categories?: Array<CareerCategory>;
    /**
     * 
     * @type {string}
     * @memberof RelatedCareers
     */
    minimumQualification: string;
    /**
     * 
     * @type {number}
     * @memberof RelatedCareers
     */
    minSalary: number;
    /**
     * 
     * @type {number}
     * @memberof RelatedCareers
     */
    maxSalary: number;
    /**
     * Whether the career is published or not.
     * @type {boolean}
     * @memberof RelatedCareers
     */
    isPublished?: boolean;
}

export function RelatedCareersFromJSON(json: any): RelatedCareers {
    return {
        'slug': json['slug'],
        'title': json['title'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] as Array<any>).map(CareerCategoryFromJSON),
        'minimumQualification': json['minimum_qualification'],
        'minSalary': json['min_salary'],
        'maxSalary': json['max_salary'],
        'isPublished': !exists(json, 'is_published') ? undefined : json['is_published'],
    };
}

export function RelatedCareersToJSON(value?: RelatedCareers): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'slug': value.slug,
        'title': value.title,
        'minimum_qualification': value.minimumQualification,
        'min_salary': value.minSalary,
        'max_salary': value.maxSalary,
        'is_published': value.isPublished,
    };
}


