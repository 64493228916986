import { getLoggedInStatus, getUserPermissions } from 'auth/selectors';
import bind from 'bind-decorator';
import update from 'immutability-helper';
import * as React from 'react';
import { connect } from 'react-redux';

import { AnalyticsApi, SearchApi } from 'global/api';
import { LIBRARY_SORT_OPTIONS } from 'global/constants';
import { RootState } from 'global/state';
import { ItemType } from 'items/models';
import {
    AnalyticsEventEventTypeEnum,
    APIPermissions,
    AppliedFilter,
    AppliedFilterFilterOnEnum,
    ItemMetadata,
    ItemResponse,
    SearchModeParameters,
    SearchRequest,
    SearchRequestModeEnum,
    SearchRequestOrderingEnum,
} from 'labxchange-client';
import { detailUrlForEntity } from 'library/utils';
import { TaxonomyData, TaxonomyCategory } from 'search/components/Taxonomy';
import {
    eqSet,
    getAppliedSearchFilters,
    getCurrentPageFromString,
    getPaginationSizeFromString,
    getSortOrderingFromString,
    sanitizeSearchParams,
} from 'search/utils';
import { CardAddButtonAccessory, CardFauxAdd } from 'ui/components/Card/Cards';
import { localeInfo } from 'i18n';
import messages from '../../displayMessages';
import { Card, CardProps } from '../Card';
import { stopWords } from 'ui/components/Highlight';
import { ResponsiveFilteredSearch } from '../ResponsiveFilteredSearch/ResponsiveFilteredSearch';
import { buildContentFilters, buildFilterOptionsFromSearchData, LibrarySearchFilter } from './library-search';
import { UI_IS_SM } from 'ui/breakpoints';
import { TagData, nameForTag } from 'search/components/Tag';
import { RecommendedItemsContainer } from 'elements';
import { sanitizeUnsafeHTML } from 'elements/utils/sanitization';

interface ReduxStateProps {
    userPermissions?: APIPermissions;
    isLoggedin?: boolean;
}


interface LibraryContentListProps {
    /**
     * Initial search parameters that can be passed in by a parent component.
     * Must be parseable by URLSearchParams(). A string is used to avoid issues comparing for equality.
     */
    searchParams?: string;
    /**
     * Optional callback to call when search parameters are changed. Can be
     * used by the parent for eg. keeping the actual url up to date.
     */
    onSearchParamsChanged?: (newParams: URLSearchParams) => void;
    /**
     * If displaying this in a modal, set isModalView so that an appropriate layout is displayed.
     */
    isModalView: boolean;
    /**
     * The placeholder view to display if no cards returned.
     */
    placeholderView?: React.ReactNode;
    /**
     * Whether to use the wide or portrait cards style.
     */
    cardsStyle: 'wide' | 'portrait';
    /**
     * Whether to show the add content button.
     */
    showCreateContentButton?: boolean;
    /**
     * Handler called when the add content button is clicked.
     */
    onCreateContent: () => void;
    /**
     * Display a plus button accessory in the Cards, if true
     */
    displayAddButton: boolean;
    /**
     * callback for the plus button on Cards, if displayAddButton is true
     */
    onItemAdd: (item: ItemMetadata) => void;
    /**
     * callback called on selecting/deselecting Cards, if displayAddButton and multipleSelect are true
     */
    onSelectionChanged?: (itemIds: Set<string>) => void;
    /**
     * If enabled, clicking anywhere on Cards (except for a plus button, if displayed)
     * opens the detail url in a new tab / window
     */
    openCardDetailUrlsInNewTab: boolean;
    /**
     * Only display items favorited by the user
     */
    searchOnlyFavoritedByUser?: boolean;
    /**
     * Only display items owned by the user
     */
    searchOnlyOwnedByUser?: boolean;
    /**
     * Only display items from organizations that the user is an admin of
     */
    searchOnlyInUserOrg?: boolean;
    /**
     * Only display items owned by the organization
     */
    searchOnlyOwnedByOrganization?: string;
    /**
     * Only display items user has access to
     */
    searchUserAccessibleItems?: boolean;
    /**
     * If enabled exclude these item types from the result
     */
    excludeTypes?: ItemType[];
    /**
     * The opposite of excludeTypes. With this you can exclude all item types except the
     * ones on this list.
     */
    onlyThisTypes?: ItemType[];
    /**
     * Whether showing public content
     */
    isPublicContentOnly?: boolean;
    /**
     * Whether to activate multiple selection behaviour of displayAddButton is true
     */
    multipleSelect?: boolean;
    /**
     * List of selected item IDs. Used when in multiple selection mode.
     */
    selectedItems: Set<string>;
    /**
     * Sort options, if not passed, LIBRARY_SORT_OPTIONS is used by default
     */
    sortOrderOptions?: { label: string, value: SearchRequestOrderingEnum }[];
    defaultSortOrder?: SearchRequestOrderingEnum;
    // set to false to prevent the automatic filter by language
    disableDefaultFilters?: boolean;
    scrollToRef?: React.RefObject<any>;
    showCompletion?: boolean;
    /**
     * If you want to scroll to the top after search change. True by default
     * This is ignored if scrollToRef is set
     */
    scrollToTop?: boolean;
    /**
     * There is a functionality to search filters. For example, if the user searchs
     * 'cell videos', the ContenType:video are activated and the search keywords
     * is 'cell'.
     * This prop disable that functionality.
     */
    disableFilterSearch?: boolean;
    // this will control the show/hide of filters modal on mobile and tablet view.
    showFilterModal?: boolean;
    isKebabMenuVisible?: boolean;
    showLibraryActions?: boolean;
    onClickViewItem?: (item: ItemMetadata | undefined) => void;
    onClickLaunchItem?: (item: ItemMetadata | undefined) => void;
}

interface LibraryContentListState {
    cards: CardProps[];
    filterHidden: boolean;
    /**
     * List of all the filters that can be applied to the search (Subject Area, Content Type, etc.)
     * This comes from ElasticSearch, so it reflects only the subset of filter options that are actually in use.
     */
    availableSearchFilters: ReadonlyArray<TaxonomyData>;
    contentFilters?: ReadonlyArray<TagData>;
    resultCount: number; // Number of matches that were returned from the search
    // Pagination of search results
    currentPage: number;
    paginationSize: number;
    sortOrder: SearchRequestOrderingEnum;
    appliedFilterIds: ReadonlySet<string>;
    searchKeywords: ReadonlySet<string>;
    loadingNewResults: boolean;
    haveLoadedOnce: boolean; // Need to know this so that we only hide the bars the first time fetching results.
    // How many times we have refreshed the search results. Used to avoid displaying old results.
    refreshCount: number;
    showMore?: boolean;
    // User has content, even if filter return empty cards list
    contentExists: boolean;
    /// Used on filter search
    allSearchFilters?: ReadonlyArray<TaxonomyData>;
    // Variable used to display recommendations if search results returns empty.
    recommendedItems: CardProps[];
}

/**
 * The LibraryContentList is the main view of content available in LabXchange.
 *
 * It is comprised of a FilterBar for filtering results, a CardsListTopBar
 * for controlling pagination, a CardsListBottomBar for paging actions,
 * and an unordered list of Card components for displaying results.
 */
export class LibraryContentListInternal extends React.PureComponent<LibraryContentListProps & ReduxStateProps,
                                                            LibraryContentListState> {

    public static defaultProps = {
        cardsStyle: 'wide',
        onItemAdd: (item: ItemMetadata) => {/* */},
        onCreateContent: () => {/* */},
        selectedItems: new Set(),
        sortOrderOptions: LIBRARY_SORT_OPTIONS,
        scrollToTop: true,
    };

    constructor(props: LibraryContentListProps & ReduxStateProps) {
        super(props);

        const allowedFilters = new Set(Object.keys(LibrarySearchFilter));
        const params = sanitizeSearchParams(props.searchParams || '', allowedFilters);

        if (props.isModalView && props.cardsStyle === 'portrait') {
            throw new Error('portrait cards cannot be used in a modal.');
        }

        const selectedSiteLanguage = `Language:${localeInfo.key}`;

        const appliedFilterIds = params.getAll('t');
        if (!this.props.disableDefaultFilters) {
            appliedFilterIds.push(selectedSiteLanguage);
        }

        this.state = {
            cards: [...Array(5)],
            currentPage: getCurrentPageFromString(params.get('page')),
            filterHidden: true,
            paginationSize: getPaginationSizeFromString(params.get('size')),
            resultCount: 0,
            sortOrder: getSortOrderingFromString(params.get('order'), props.defaultSortOrder ? props.defaultSortOrder : SearchRequestOrderingEnum.Relevance, props.sortOrderOptions? props.sortOrderOptions : LIBRARY_SORT_OPTIONS),
            availableSearchFilters: [],
            appliedFilterIds: new Set(appliedFilterIds),
            searchKeywords: new Set(params.getAll('q')),
            loadingNewResults: true,
            haveLoadedOnce: false,
            refreshCount: 0,
            contentExists: false,
            recommendedItems: [],
        };
    }

    public componentDidMount() {
        if (!this.props.disableFilterSearch) {
            this.checkFilterInSearch().then(() => this.refreshSearchResults());
        }
        if (this.props.searchParams) {
            AnalyticsApi.create({
                data: {
                    eventType: AnalyticsEventEventTypeEnum.AssetSearchPerformed,
                    data: {
                        url: window.location.href,
                        pathname: window.location.pathname,
                        oldSearchKeywords: '',
                        newSearchKeywords: Array.from(this.state.searchKeywords).join(' '),
                    },
                },
            });
        }
    }

    public componentDidUpdate(prevProps: LibraryContentListProps, prevState: LibraryContentListState) {
        if (prevProps.searchParams !== this.props.searchParams) {
            // Search params have been updated from parent; spread out to state.
            const allowedFilters = new Set(Object.keys(LibrarySearchFilter));
            const sortOrdering = this.props.defaultSortOrder ? this.props.defaultSortOrder : SearchRequestOrderingEnum.Relevance;
            const params = sanitizeSearchParams(this.props.searchParams || '', allowedFilters);
            const oldSearchKeywords = this.state.searchKeywords;
            const newSearchKeywords = new Set(params.getAll('q'));
            this.setState({
                currentPage: getCurrentPageFromString(params.get('page')),
                paginationSize: getPaginationSizeFromString(params.get('size')),
                appliedFilterIds: new Set(params.getAll('t')),
                searchKeywords: newSearchKeywords,
                sortOrder: getSortOrderingFromString(params.get('order'), sortOrdering, this.props.sortOrderOptions? this.props.sortOrderOptions : LIBRARY_SORT_OPTIONS),
            });
            // Send analytics event if search terms change caused this update
            if (newSearchKeywords.size !== 0 && !eqSet(newSearchKeywords, this.state.searchKeywords)) {
                AnalyticsApi.create({
                    data: {
                        eventType: AnalyticsEventEventTypeEnum.AssetSearchPerformed,
                        data: {
                            url: window.location.href,
                            pathname: window.location.pathname,
                            oldSearchKeywords: Array.from(oldSearchKeywords).join(' '),
                            newSearchKeywords: Array.from(newSearchKeywords).join(' '),
                        },
                    },
                });
            }
        } else if (
            !eqSet(prevState.appliedFilterIds, this.state.appliedFilterIds) ||
            prevState.currentPage !== this.state.currentPage ||
            prevState.paginationSize !== this.state.paginationSize ||
            prevState.sortOrder !== this.state.sortOrder ||
            prevProps.searchOnlyOwnedByUser !== this.props.searchOnlyOwnedByUser ||
            prevProps.searchOnlyInUserOrg !== this.props.searchOnlyInUserOrg ||
            prevProps.searchOnlyOwnedByOrganization !== this.props.searchOnlyOwnedByOrganization ||
            prevProps.searchOnlyFavoritedByUser !== this.props.searchOnlyFavoritedByUser ||
            !eqSet(prevState.searchKeywords, this.state.searchKeywords)
        ) {
            // Hide the filters if the tab changes in the content picker.
            this.setState({
                filterHidden: true,
            });
            if (!this.props.disableFilterSearch) {
                this.checkFilterInSearch().then(() => this.refreshSearchResults());
            }
        }
    }

    public render() {
        // Prevent loading glitch if placeholderview passed:
        if (this.props.placeholderView){
            // If UI was loaded once and is not loading new result and no there are no item -> return placeholder view
            if (
                this.state.haveLoadedOnce &&
                !this.state.loadingNewResults &&
                this.state.cards.length === 0 &&
                !this.state.contentExists
            ) {
                return this.props.placeholderView;
            }
        }

        const renderCardItem = (cardProps: CardProps, index: number) => {
            const selected = cardProps ? cardProps.metadata ? this.props.selectedItems.has(cardProps.metadata.id) : false : false;
            return (
                <li key={index}>
                    {
                        <Card {...cardProps}
                            accessory={
                                this.props.displayAddButton ?
                                    <CardAddButtonAccessory
                                        onClick={() => {this.onCardSelect(cardProps.metadata); }}
                                        selected={selected}
                                    /> : null
                            }
                            showMenuButton={true}
                            mobileViewMode={this.props.cardsStyle === 'portrait'}
                            isPublicContentOnly={this.props.isPublicContentOnly}
                            isUserLoggedIn={this.props.isLoggedin}
                            userPermissions={this.props.userPermissions}
                            selectedStyle={selected}
                            onDelete={() => this.onCardDelete(cardProps.metadata ? cardProps.metadata.id : undefined)}
                            highlightedKeywords={this.getNormalizedSearchKeywords()}
                            showSkeleton={this.state.loadingNewResults}
                            showCompletion={this.props.showCompletion}
                            isKebabMenuVisible={this.props.isKebabMenuVisible}
                            showLibraryActions={this.props.showLibraryActions}
                            onClickViewItem={() => this.props.onClickViewItem?.(cardProps.metadata)}
                            onClickLaunchItem={() => this.props.onClickLaunchItem?.(cardProps.metadata)}
                        />
                    }
                </li>
            );
        };

        const cardItems = this.state.cards.map((cardProps, index) => renderCardItem(cardProps, index));

        // Only render recommended orgs if the variable is populated
        const renderRecommended = this.state.recommendedItems.length !== 0;
        let recommendedItemsContent;
        if (renderRecommended) {
            recommendedItemsContent = this.state.recommendedItems.map((cardProps, index) => renderCardItem(cardProps, index));
        }

        return <ResponsiveFilteredSearch
            allowFiltering={true}
            isModalView={this.props.isModalView}
            appliedFilterIds={this.state.appliedFilterIds}
            availableSearchFilters={this.state.availableSearchFilters}
            contentFilters={this.state.contentFilters}
            currentPage={this.state.currentPage}
            onFiltersChanged={this.onFiltersChanged}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
            onSortChange={this.onSortChange}
            maxPagesDisplayed={60}
            loadMore={this.loadMore}
            paginationSize={this.state.paginationSize}
            resultCount={this.state.resultCount}
            searchKeywords={this.state.searchKeywords}
            showSearchForTaxonomies={[LibrarySearchFilter.SubjectArea, LibrarySearchFilter.Partner, LibrarySearchFilter.Tag]}
            sortOrder={this.state.sortOrder}
            sortOrderOptions={this.props.sortOrderOptions}
            loadingNewResults={this.state.loadingNewResults}
            onlyShowLoaderWhenLoading={this.props.placeholderView !== null && !this.state.haveLoadedOnce}
            showFilterModal={this.props.showFilterModal}
        >
            <ul className={'cards-list list-unstyled' + (this.props.cardsStyle === 'portrait' ? ' cards-list-portrait' : '')}>
                {this.props.showCreateContentButton === true && (
                    <li>
                        <CardFauxAdd
                            onClick={this.props.onCreateContent}
                            message={messages.libraryCreateContentButtonLabel}
                            showSkeleton={this.state.loadingNewResults}
                        />
                    </li>
                )}
                {cardItems}
                { renderRecommended &&
                    <RecommendedItemsContainer>
                        {recommendedItemsContent}
                    </RecommendedItemsContainer>
                }
            </ul>
        </ResponsiveFilteredSearch>;
    }

    @bind private onCardDelete(id?: string) {
        if (id) {
            // We know it's been successfully deleted from the backend, so locally
            // update the state to remove the card instantly. Also note that due to
            // ES caching (probably), if we immediately refresh search results, the
            // deleted card is still there.
            this.setState({
                cards: this.state.cards.filter((card) => card.metadata ? card.metadata.id !== id : {}),
            });
        }
    }

    /**
     * Split the search term into words and exclude common stop words across
     * multiple languages.
     */
    private getNormalizedSearchKeywords(useQuoteRegex = false): ReadonlySet<string> {
        const keywords: Set<string> = new Set();

        // Used to avoid split word inside quotes
        // 'a:0 b:1 moo:"foo bar" c:2'.split(quoteRegex)
        // => [a:0, b:1, moo:"foo bar", c:2]
        const quoteRegex = / +(?=(?:(?:[^"]*"){2})*[^"]*$)/g;

        (this.state.searchKeywords as ReadonlySet<string>).forEach(keyword => {
            let splitRegex: string | RegExp = ' ';
            if (useQuoteRegex) {
                splitRegex = quoteRegex;
            }
            keyword.split(splitRegex).forEach(word => {
                if (!stopWords.has(word)) {
                    // sanitizing to escape html entites e.g. `&` -> `&amp;` so
                    // not just the `&` is matched later on when highlighting
                    keywords.add(sanitizeUnsafeHTML(word));
                }
            });
        });

        return keywords;
    }

    private calculatePageCount() {
        return Math.max(1, Math.ceil(this.state.resultCount / this.state.paginationSize));
    }

    @bind private onParamsChanged() {
        if (this.props.onSearchParamsChanged) {
            const params = new URLSearchParams();
            this.state.appliedFilterIds.forEach((tagID)  => { params.append('t', tagID); });
            this.state.searchKeywords.forEach((kw)  => { params.append('q', kw); });
            params.set('page', this.state.currentPage.toString());
            params.set('size', this.state.paginationSize.toString());
            params.set('order', this.state.sortOrder.toString());
            this.props.onSearchParamsChanged(params);
            AnalyticsApi.create({
                data: {
                    eventType: AnalyticsEventEventTypeEnum.AssetSearchPerformed,
                    data: {
                        url: window.location.href,
                        pathname: window.location.pathname,
                    },
                },
            });
        }
    }

    @bind private onCardSelect(item?: ItemMetadata) {
        if (item) {
            if (this.props.displayAddButton && this.props.multipleSelect && this.props.onSelectionChanged) {
                if (this.props.selectedItems.has(item.id)) {
                    this.props.onSelectionChanged(update(this.props.selectedItems, {$remove: [item.id]}));
                } else {
                    this.props.onSelectionChanged(update(this.props.selectedItems, {$add: [item.id]}));
                }
            } else if (this.props.displayAddButton && this.props.onItemAdd) {
                this.props.onItemAdd(item);
            }
        }
    }

    @bind private onSizeChange(paginationSize: number) {
        this.setState({
            paginationSize,
        }, this.onParamsChanged);
    }

    @bind private onSortChange(sortOrder: string) {
        this.setState({
            sortOrder: sortOrder as SearchRequestOrderingEnum,
            currentPage: 1,
        }, this.onParamsChanged);
    }

    @bind private loadMore() {
        this.setState({
            showMore: true,
            currentPage: this.state.currentPage + 1
        });
    }

    @bind private onPageChange(newPage: number) {
        if (newPage === this.state.currentPage) { return; }

        this.setState({currentPage: newPage}, this.onParamsChanged);

        // record this page change event using the analytics API:
        let eventType: AnalyticsEventEventTypeEnum;
        if (newPage === this.state.currentPage + 1) {
            eventType = AnalyticsEventEventTypeEnum.AssetSearchNextPageVisited;
        } else if (newPage === this.state.currentPage - 1) {
            eventType = AnalyticsEventEventTypeEnum.AssetSearchPreviousPageVisited;
        } else {
            eventType = AnalyticsEventEventTypeEnum.AssetSearchPageNumberVisited;
        }
        AnalyticsApi.create({data: {
            eventType, data: {url: window.location.href, pathname: window.location.pathname},
        }});
    }

    /** The user has changed the filters or search keywords */
    @bind private onFiltersChanged(newFilters: ReadonlySet<string>, searchKeywords: ReadonlySet<string>): void {
        this.setState({
            currentPage: 1,
            appliedFilterIds: newFilters,
            cards: [],
            searchKeywords,
        }, this.onParamsChanged);
    }

    /**
     * Refresh the search results, as well as the list of filter options
     */
    private async refreshSearchResults() {
        const filters = Array.from(getAppliedSearchFilters(this.state.appliedFilterIds)) as AppliedFilter[];
        let mode: SearchRequestModeEnum;
        const modeParameters: SearchModeParameters = {};
        if (this.props.searchOnlyOwnedByUser) {
            mode = SearchRequestModeEnum.Owned;
        } else if (this.props.searchOnlyOwnedByOrganization) {
            mode = SearchRequestModeEnum.OrganizationOwned;
            modeParameters.organization = this.props.searchOnlyOwnedByOrganization;
        } else if (this.props.searchOnlyInUserOrg) {
            mode = SearchRequestModeEnum.OwnOrgs;
        } else if (this.props.searchOnlyFavoritedByUser) {
            mode = SearchRequestModeEnum.Favorites;
        } else if (this.props.searchUserAccessibleItems || filters.some(f => f.filterOn === AppliedFilterFilterOnEnum.References)) {
            mode = SearchRequestModeEnum.AllUserAccessible;
        }
        else {
            mode = SearchRequestModeEnum.Public;
        }

        const searchRequest: SearchRequest = {
            mode,
            modeParameters,
            filters,
            exclude: [],
            keywords: Array.from(this.state.searchKeywords).join(' '),
            currentPage: this.state.currentPage,
            paginationSize: this.state.paginationSize,
            ordering: this.state.sortOrder,
        };
        if (this.props.onlyThisTypes) {
            const allItems = Object.values(ItemType);
            const exclude = [] as any;
            for (const item of allItems) {
                if (!this.props.onlyThisTypes.includes(item)) {
                    exclude.push(item);
                }
            }
            searchRequest.exclude = [{
                filterOn: LibrarySearchFilter.ItemType,
                filterValues: exclude,
            }];
        }
        if (this.props.excludeTypes) {
            searchRequest.exclude = [{
                filterOn: LibrarySearchFilter.ItemType,
                filterValues: this.props.excludeTypes,
            }];
        }

        const thisRefreshCount = this.state.refreshCount + 1; // Used to track which response matches which request
        this.setState({
            refreshCount: this.state.refreshCount + 1,
            loadingNewResults: true,
        });

        try {
            const responseData = await SearchApi.librarySearch({data: searchRequest});

            // Now, the filters or pagination may have changed while the results were loading.
            // If so, we need to just discard these results and wait for the new ones.
            if (thisRefreshCount !== this.state.refreshCount) {
                return;
            }

            let newCards: CardProps[] = responseData.results.map(this.cardPropsForResult);
            if (this.state.showMore) {
                newCards = [...this.state.cards].concat(newCards);
            }

            // User may select different language to filter results
            // If user doesn't have content for this language - it will return empty list
            // Avoid rendering empty placehoder
            if (thisRefreshCount === 1) {
                this.setState({
                    contentExists: responseData.count > 0,
                });
            }

            const newState: Partial<LibraryContentListState> = {
                cards: newCards,
                resultCount: responseData.count,
                availableSearchFilters: buildFilterOptionsFromSearchData(responseData.aggregations),
                contentFilters: buildContentFilters(responseData.aggregations),
                loadingNewResults: false,
                haveLoadedOnce: true,
                recommendedItems: [],
            };

            // If no responses are found, fetch 12 most popular organizations
            // and display them as recommended items.
            if (responseData.count === 0) {
                let recommendationResponseData;
                try {
                    recommendationResponseData = await SearchApi.librarySearch({data: {
                        ordering: SearchRequestOrderingEnum.MostFavorited,
                        paginationSize: 12,
                        exclude: [{filterOn: AppliedFilterFilterOnEnum.ItemType , filterValues: ['link']}],
                    }});
                    newState.recommendedItems = recommendationResponseData.results;
                } catch (err) {
                    // Skip this exception silently
                }
            }

            this.setState(newState as LibraryContentListState, () => {
                // NOW we can sanitize the page number, because we have the
                // paginationSize and the resultCount set.
                const lastPage = this.calculatePageCount();
                if (this.state.currentPage > lastPage) {
                    this.onPageChange(lastPage);
                }
            });
        } catch (err) {
            this.setState({
                cards: [],
                resultCount: 0,
                loadingNewResults: false,
            });
        }

        /// Scroll only if you are in medium and large screens
        if (!UI_IS_SM.matches) {
            // Scroll after async call to refresh search results. We cant't call this
            // from onPageChange() or componentDidUpdate() since there is nothing to
            // scroll until after the search results have been refreshed.
            if (this.props.scrollToRef && this.props.scrollToRef.current) {
                // Attempt to scroll so the scrollToRef element is visible at the
                // "end" of the scroll region. If the element is above the variable
                // portion, this will have the effect of scrolling to the very top.
                this.props.scrollToRef.current.scrollIntoView(
                    { block: 'end', behavior: 'smooth' }
                );
            }
            else if (this.props.scrollToTop) {
                window.scrollTo(0, 0);
            }
        }
    }

    /**
     * Convert the search results from ItemResponse to CardProps format
     * Basically this just means adding the detailUrl to each one.
     */
    @bind private cardPropsForResult(result: ItemResponse): CardProps {
        const openDetailUrlInNewTab = this.props.openCardDetailUrlsInNewTab;
        return {
            metadata: result.metadata,
            detailUrl: detailUrlForEntity(result.metadata),
            openDetailUrlInNewTab,
            userAttributes: result.userAttributes,
        };
    }

    @bind private onFilterInSearch(keywords: string[], filters: string[]) {
        AnalyticsApi.create({
            data: {
                eventType: AnalyticsEventEventTypeEnum.AssetSearchPerformed,
                data: {
                    url: window.location.href,
                    pathname: window.location.pathname,
                    keywords: keywords.join(','),
                    filters: filters.join(','),
                },
            },
        });
    }

    /*
     * This functions makes a clean search to get all aggregations
     * It returns the filter list from that aggregations
    */
    @bind private async loadAllFilterList() {
        const searchRequest: SearchRequest = {
            mode: SearchRequestModeEnum.Public,
            modeParameters: {},
            filters: [],
            exclude: [],
            keywords: '',
            currentPage: 1,
            paginationSize: this.state.paginationSize,
            ordering: this.state.sortOrder,
        };
        const responseData = await SearchApi.librarySearch({data: searchRequest});
        const allSearchFilters = buildFilterOptionsFromSearchData(responseData.aggregations);
        this.setState({
            allSearchFilters,
        });
        return allSearchFilters;
    }

    /*
     * Verifies if there are filters on the search keywords
     * Converts that keywords on filters
     *
     * To add new filter categories, modify 'enabledFilters'
     * with the new category and its prefix.
    */
    @bind private async checkFilterInSearch() {
        /// Enabled filters with the prefix to search (Ex. 'type:videos')
        const enabledFilters = new Map<TaxonomyCategory, string>([
            [TaxonomyCategory.ContentType, 'type'],
        ]);
        const keywords = Array.from(this.getNormalizedSearchKeywords(true));

        /// We build a regex with the prefix to verify if exist filters on keywords
        let regexString = '';
        enabledFilters.forEach((prefix) => {
            /// Ex. (type:["'](.*?)["'])|(type:(\w+))
            regexString += `(${prefix}:["'](.*?)["'])|(${prefix}:(\\w+))`;
        });
        const regex = new RegExp(regexString);

        /// Verifies if there is a filter in the keywords
        if (!keywords.some((keyword) => regex.test(keyword))) {
            return;
        }

        /// We need to get all search filters
        let allSearchFilters = this.state.allSearchFilters;
        if (allSearchFilters === undefined) {
            allSearchFilters = await this.loadAllFilterList();
        }
        const filtersList = allSearchFilters.filter((f) => {
            return enabledFilters.has(f.category);
        });

        const filterKeywords: Set<string> = new Set();
        const filterMap: any = {};

        for (const filters of filtersList) {
            const prefix = enabledFilters.get(filters.category);
            filters.tags.forEach((filter) => {
                const temporalKeywordList = [];

                /// We get the human read part of the id (ex: assement)
                const idKey = [prefix, filter.id.split(':')[1]].join(':');
                filterKeywords.add(idKey);
                filterMap[idKey] = filter.id;
                temporalKeywordList.push(filter.name.replace(' (Beta)', '').toLowerCase());

                // Translated name of the type
                const translatedName = nameForTag(filter, filters.category);
                temporalKeywordList.push(translatedName.toLocaleLowerCase());

                // Translated name in plural
                const translatedNamePlural = nameForTag(filter, filters.category, true);
                temporalKeywordList.push(translatedNamePlural.toLocaleLowerCase());

                /// We map all words with the respective filter id
                for (let keyword of temporalKeywordList) {
                    let newKeyword;
                    /// Keyword without quotes (only simple words)
                    if (keyword.split(' ').length === 1) {
                        newKeyword = [prefix, keyword].join(':');
                        filterKeywords.add(newKeyword);
                        filterMap[newKeyword] = filter.id;
                    }

                    /// Keyword with quotes (simple & composite wrods)
                    keyword = '"' + keyword + '"';
                    newKeyword = [prefix, keyword].join(':');
                    filterKeywords.add(newKeyword);
                    filterMap[newKeyword] = filter.id;
                }
            });
        }

        const newKeywords: Set<string> = new Set();
        const newAppliedFilters: Set<string> = new Set();

        /// We verify the keywords and we convert it to filters
        for (let keyword of keywords) {
            keyword = keyword.toLowerCase();

            if (filterKeywords.has(keyword)) {
                newAppliedFilters.add(filterMap[keyword]);
                continue;
            }

            newKeywords.add(keyword);
        }

        this.setState({
            appliedFilterIds: new Set([...this.state.appliedFilterIds, ...newAppliedFilters]),
            searchKeywords: new Set(newKeywords),
        }, () => this.onFilterInSearch(keywords, Array.from(newAppliedFilters)));
    }
}

export const LibraryContentList = connect<ReduxStateProps, RootState>(
    (state: RootState) => ({
        userPermissions: getUserPermissions(state),
        isLoggedin: getLoggedInStatus(state),
    }),
)(LibraryContentListInternal);
