import { ROUTES } from 'global/constants';
import { RootState } from 'global/state';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { StandardPageLayout } from 'ui/components';
import { WrappedMessage } from 'utils';
import messages from './displayMessages';

// tslint:disable:max-line-length

export class NonUSPrivacyDisclosure extends React.PureComponent<{}, {}> {

    public render() {
        return (
            <StandardPageLayout
                pageTitle={messages.pageTitle}
                headerFeature={
                    <div>
                        <h2 className='legal-header-title'>
                            <WrappedMessage message={messages.pageTitle}/></h2>
                        <p className='legal-header-sub-title'>
                            <WrappedMessage message={messages.pageSubTitle}/></p>
                    </div>
                }
            >
                <div className='legal-page'>
                    <div className='legal-content'>
                        <h2>Introduction</h2>
                        <p>
                            These privacy disclosures (these “<strong>Disclosures</strong>”)
                            provide information on collection and use of data about individuals located in a Relevant
                            Country (as defined below).
                            <br/>
                            In these Disclosures,
                            <br/>

                            <ul>
                                <li>
                                    “<strong>Personal Data</strong>” means information relating to an identified or identifiable
                                    individual; an identifiable individual is one who can be identified, directly or
                                    indirectly, by use of any identifier or characteristic specific to that individual.
                                </li>
                                <li>
                                    “<strong>Relevant Law</strong>” means any non-U.S. jurisdiction’s personal data protection law that
                                    applies to the processing of Personal Data by a Harvard Entity.
                                </li>
                                <li>
                                    “<strong>Relevant Country</strong>” means a country which has enacted a Relevant Law.
                                </li>
                                <li>
                                    “<strong>In-scope Processing</strong>” means the collection, use, handling, processing or sharing of
                                    Personal Data by a Harvard Entity (i.e. LabXchange) when those activities are within the scope of any Relevant Law.
                                </li>
                                <li>
                                    “<strong>Harvard Entity</strong>” means a Harvard University school, center, or other Harvard unit or
                                    Harvard-controlled entity.
                                </li>
                            </ul>
                        </p>
                        <p>
                            These Disclosures apply only to In-scope Processing. Please note that, depending on the
                            situation, some of the Harvard activities described below may in the given case not
                            constitute In-scope Processing as that term is used in these Disclosures.
                        </p>
                        <p>
                            Further, these Disclosures apply only to the Harvard Entities that link to these Disclosures
                            or expressly adopt them in writing. In these Disclosures the words “we,” “us” or “our” refer
                            to LabXchange (“LXC”). In some cases, a Harvard Entity or other Harvard unit may provide
                            other information about its In-scope Processing in its own supplemental or separate
                            disclosures.
                        </p>
                        <p>
                            These Disclosures concern In-scope Processing by any means, including electronic means (such
                            as websites and mobile applications).
                        </p>

                        <h2>A. How We Collect and Use Personal Data</h2>
                        <p>We use the Personal Data that we collect as described in more detail below. The ways in which
                            we collect and use your data vary depending on the relationship between you and us. The
                            following sections of these Disclosures describe in more detail how and why we collect and
                            use Personal Data in various circumstances that may involve In-scope Processing.</p>
                        <h2>1. Personal Data We Collect</h2>
                        <h3>Websites and Mobile Applications</h3>
                        <p>
                            As is true of most digital platforms, we obtain certain data automatically when you use one
                            of our websites or mobile applications, such as your IP address, browser type and device
                            type. Certain web-forms also collect Personal Data you provide, for example when you enter
                            data into form fields. If we also process data through our websites or mobile applications
                            for one of the activities described further below, those descriptions will provide
                            additional information about how those data are collected and used. We and our third-party
                            vendors use this Personal Data for the primary purposes of conducting analytics, improving
                            our websites, responding to your requests and providing you with relevant information including program offerings that we believe may be of interest.
                        </p>
                        <h3>What Personal Data Do We Collect? </h3>
                        <p>
                            The following are examples of the information we collect: <br/>
                            <ul>
                                <li>
                                    When you register for an LXC User Account using a third party site, such as Google
                                    or Facebook, we collect certain pieces of your personal information from them. This
                                    includes your full name, email address, and country or region of residence. In
                                    addition we collect your date of birth from you. You may choose to provide
                                    additional Personally Identifiable Information when you create your LXC profile.
                                </li>
                                <li>
                                    We also may obtain information about you when you contact our technical support
                                    team, send us email or receive email from us, participate in a user survey, or
                                    otherwise communicate with us through the Services.
                                </li>
                                <li>
                                    When you use our Services, we may collect information about which Resources,
                                    Services, portions or pages of a Service were visited, the order in which they were
                                    visited, when they were visited, and which hyperlinks and other user interface
                                    controls were used.
                                </li>
                                <li>
                                    We also may collect a variety of other information, including IP address, operating
                                    system, unique IDs of cookies, unique IDs sent from mobile devices, network carriers
                                    or data providers, device types, browser types, version and language, Internet
                                    Service Provider (ISP) information, referring and exit websites and applications,
                                    attributes of computer or device usage, date/time stamps, click-through URLs,
                                    information contained in HTTP headers or other internet transfer protocol signals,
                                    and clickstream data and other similar data.
                                </li>
                                <li>
                                    We may be able to determine your geographic location (or the location of your device
                                    or ISP) with such data. You may be able to change the settings on your computer or
                                    mobile device to prevent it from providing us with any location information.
                                </li>
                                <li>
                                    We may also collect information about you, including Personally Identifiable
                                    Information, from third parties. This may include, for example:
                                    <ul>
                                        <li>
                                            Information provided by your employer or an organization with which you are
                                            affiliated, such as when they authorize you to use the Services on their
                                            behalf.
                                        </li>
                                        <li>
                                            Information provided by other users, such as when an educator generates
                                            information about you using the LXC class functionality (a “Class”) or when
                                            a mentor generates information about you.
                                        </li>
                                        <li>
                                            Information provided by our partners and service providers. For example,
                                            from time to time, we may offer a special program or activity in partnership
                                            with a third party. If you participate in these special programs, we may
                                            collect information from you or from the partner to facilitate those
                                            programs.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    We and third parties use web analytics and other automated tools to obtain some of
                                    the information we collect. These tools include cookies, pixel tags, scripts, log
                                    files, web beacons, and other similar technologies, which are further described
                                    at <NavLink to={ROUTES.General.COOKIES}>https://www.labxchange.org/cookies</NavLink>. Some
                                    of the cookies and tracking technologies on our Services are managed by us
                                    (first-party cookies), while others are managed by
                                    third parties that we do not control (third-party cookies). For more information
                                    about data collected by third parties, please see “Use of certain third-party
                                    applications, websites and social networks” below.
                                </li>
                                <li>
                                    Most browsers and mobile devices offer instructions on how to disable cookies
                                    (typically in “<strong>Preferences</strong>” or “<strong>Settings</strong>”). If you disable our cookies, some
                                    functions and conveniences of our Services may not work properly or at all. We will
                                    attempt to honor your privacy preferences, but we may not be able to honor all of
                                    them, including, for example, Do Not Track signals sent by your browser.
                                </li>
                            </ul>
                        </p>
                        <h3>What is the Purpose of Processing? </h3>
                        <p>
                            We process your Personal Data collected on our websites and mobile applications: <br/>
                            <ul>
                                <li>
                                    To facilitate our online interactions with you, for example, by responding to your
                                    requests;
                                </li>
                                <li>
                                    To operate, maintain and improve our websites and mobile applications, for example,
                                    by conducting analytics to improve and manage our websites and mobile applications,
                                    analyze user trends, and create custom audience lists;
                                </li>
                                <li>
                                    To collect data about you and your activities online over time and across different
                                    websites and social media channels for marketing purposes, including delivering
                                    relevant marketing communications to you; or
                                </li>
                                <li>
                                    To engage in other activities described under “Additional Uses of Personal Data”
                                    below.
                                </li>
                            </ul>
                        </p>
                        <h3> What is Our Legal Basis of Processing? </h3>
                        <p>
                            We process your Personal Data for the purposes described above consistent with the legal
                            bases recognized by the Relevant Law in each Relevant Country, which may include, for
                            instance: enabling you to register for our Services; to provide our Services in accordance
                            with our contract with you (i.e. our Terms of Service); to facilitate transactions requested
                            by you and meet our contractual obligations (for example, registering you for events); to
                            pursue our legitimate interests (for example, website analytics); as necessary for
                            compliance with a legal obligation (for example, responding to a subpoena); or on the basis
                            of your consent, where applicable.
                        </p>
                        <h2>
                            2. Personal Data We Obtain from Third Party Sources
                        </h2>
                        <p>
                            As part of our In-scope Processing, we may obtain certain Personal Data about you from third
                            party sources, which we may use for the purposes and in the ways described in “How We
                            Collect and Use Personal Data” above and in “Additional Uses of Personal Data” below. In
                            some cases, we may obtain your consent for additional uses.
                        </p>
                        <h3>Partners and Service Providers</h3>
                        <p>
                            We use partners and service providers such as: edX, Facebook/Meta, Google, Amazon Web
                            Service, etc. that provide analytics and platform support and services for us. Some of these
                            partners have access to Personal Data about you that we may not otherwise have (for example,
                            when you sign up directly with that provider) and may share some or all these data with us.
                        </p>
                        <h3>Single Sign-On</h3>
                        <p>
                            Some of our online offerings or research activities may allow you to register and login
                            through a third-party platform. When you login to our offering or activity through a
                            third-party platform, you allow us to access and collect any Personal Data from your
                            third-party platform account permitted under the settings and privacy statement of that
                            platform.
                        </p>
                        <h3>Supplemental Personal Data</h3>
                        <p>
                            We may receive Personal Data from third-party sources, such as public or private databases
                            (for example, compilations of email or postal addresses) and publications (for example, news
                            reports), or companies or institutions that may sponsor or facilitate your participation in
                            one of our programs, which we may also add to existing Personal Data.
                        </p>
                        <h2>3. Additional Uses of Personal Data</h2>
                        <p>
                            In addition to the uses described above, including under “How We Collect and Use
                            Information” and “Personal Data We Obtain from Third Party Sources,” we may use your
                            Personal Data for the following purposes. These additional uses may under certain
                            circumstances be based on your consent, or may be necessary to fulfill our contractual
                            commitments to you, for legal compliance, or to pursue our legitimate interest in the
                            following activities: <br/>
                            <ul>
                                <li>
                                    Conducting our operations and administering and developing our educational
                                    offerings;
                                </li>
                                <li>
                                    Responding to your requests for research assistance;
                                </li>
                                <li>
                                    Processing and responding to your requests or inquiries of any other kind;
                                </li>
                                <li>
                                    Providing you with newsletters, articles, service alerts or announcements, event
                                    invitations, volunteer opportunities, and other information that we believe may be
                                    of interest to you;
                                </li>
                                <li>
                                    Conducting research, surveys and similar inquiries to help us understand trends and
                                    needs of our users;
                                </li>
                                <li>
                                    Performing marketing, promotions and advertising, either directly or through
                                    third-parties. These activities may include interest-based advertising, targeted
                                    advertising and online behavioral advertising in order to increase the likelihood
                                    that the content will be of interest to you;
                                </li>
                                <li>
                                    Preventing, investigating, taking action regarding or providing notice of fraud,
                                    unlawful or criminal activity, other misconduct, security or technical issues, or
                                    unauthorized access to or use of Personal Data, our website or data systems; or
                                </li>
                                <li>
                                    Responding to subpoenas, court orders, or other legal processes; fulfilling and
                                    enforcing our agreements and legal rights; protecting the health, safety, rights or
                                    property of you, us or others; and meeting legal obligations.
                                </li>
                            </ul>
                        </p>
                        <h2>4. Data Retention</h2>
                        <p>
                            We will retain your Personal Data for as long as is necessary for the purposes set out in
                            these Disclosures, in accordance with Relevant Law and the legal bases for acquiring the
                            data.
                            <br/>
                            For example, we may generally retain Personal Data as follows:
                            <br/>
                            <ul>
                                <li>
                                    For as long as may be required under applicable law;
                                </li>
                                <li>
                                    As needed to resolve disputes or protect our legal rights;
                                </li>
                                <li>
                                    Where processing is based on your consent, for the period of time necessary to carry
                                    out
                                    the processing activities to which you consented;
                                </li>
                                <li>
                                    Where processing is based on contract, for the duration of the contract plus some
                                    additional limited period of time that is necessary to comply with law or that
                                    represents
                                    the statute of limitations for legal claims that could arise from the contractual
                                    relationship;
                                </li>
                                <li>
                                    Where processing is based on the public interest, for the period of time that
                                    continues to
                                    serve that underlying interest; or
                                </li>
                                <li>
                                    Where processing is based on our legitimate interests, for a reasonable period of
                                    time
                                    based on the particular interest, taking into account the fundamental interests and
                                    the rights and freedoms of the data subjects. In some cases, where Personal Data was
                                    primarily processed and retained on the basis of consent, contract, the public
                                    interest, or other bases described in these Disclosures, we may continue thereafter
                                    to retain the data based on a legitimate interest.
                                </li>
                            </ul>
                            Consistent with the foregoing guidance, some data may be retained indefinitely.
                        </p>
                        <h2>B. How We Share and Disclose Personal Data</h2>
                        <p>
                            We share your Personal Data with third parties in the ways described in these Disclosures,
                            including the “How We Collect and Use Personal Data” section above, as well as between
                            Harvard Entities. Additionally, we may share information as described below:
                        </p>
                        <h3>Service Providers</h3>
                        <p>
                            We share your Personal Data with third-party service providers that complete transactions or
                            perform services on our behalf or for your benefit, such as:
                            <ul>
                                <li>
                                    Customer relationship management services;
                                </li>
                                <li>
                                    Marketing and analytics;
                                </li>
                                <li>
                                    Providing course platforms or tools that enable or enhance our offerings;
                                </li>
                                <li>
                                    Content evaluations and assessments;
                                </li>
                                <li>
                                    Registration verification;
                                </li>
                                <li>
                                    Research insights and analytics;
                                </li>
                                <li>
                                    Research collaboration;
                                </li>
                                <li>
                                    Educational operations and collaborations;
                                </li>
                                <li>
                                    Performing workforce administration and operations;
                                </li>
                                <li>
                                    System maintenance and security;
                                </li>
                                <li>
                                    Facilitating other transactions with you; and
                                </li>
                                <li>
                                    Assisting with our legal compliance.
                                </li>
                            </ul>
                        </p>
                        <h3>Social Media Platforms</h3>
                        <p>
                            We may also use services provided by third parties (such as social media platforms) to serve
                            targeted ads or sponsored content on third-party platforms.
                        </p>
                        <h3>
                            Legal Process, Safety and Terms Enforcement
                        </h3>
                        <p>
                            We may disclose your Personal Data to legal or government regulatory authorities as required
                            by law. We may also disclose your Personal Data to third parties in connection with claims,
                            disputes or litigation, when otherwise required by law, or if we determine that disclosure
                            is necessary to protect the health, safety, rights or property of you, us or others, or to
                            enforce our legal rights or contractual commitments that you have made.
                        </p>

                        <h2>C. International Data Transfers</h2>
                        <p>
                            Much of our Personal Data processing takes place in the United States, though sometimes we
                            or third parties with whom we share data may process data in other countries. The data
                            protection laws in the United States and other countries may provide less protection than
                            such laws in your Relevant Country. In the event we transfer your Personal Data outside your
                            Relevant Country as part of our In-scope Processing, we rely where required on appropriate
                            or suitable safeguards or specific legal provisions permitting such transfers under the
                            Relevant Law.
                        </p>
                        <p>
                            When transferring Personal Data from a country in the European Economic Area (EEA) or from
                            the United Kingdom (UK) to a country outside the EEA and the UK, we may base such transfers
                            on contracts containing legally authorized data protection clauses referred to as Standard
                            Contractual Clauses. You may request a copy of the Standard Contractual Clauses relevant to
                            any such transfer of your Personal Data by contacting us as set forth in the “Contact Us”
                            section below.
                        </p>
                        <h2>D. Cookies and Similar Technologies</h2>
                        <p>
                            Whenever you visit or use our websites or online services, we may deploy cookies and related
                            technologies (“<strong>Cookies</strong>”) to collect certain personal information about you. We use the
                            information in order to enhance and personalize the experience, to help provide our
                            services, to analyze website and service usage, and to help improve the websites and our
                            related services. To find out more about cookies, visit <a target='_blank' rel='noopener noreferrer' href={'https://www.internetcookies.org/'}>
                            https://www.internetcookies.org/ </a>.
                        </p>
                        <p>Third parties may also use Cookies embedded in our sites to collect information about your
                            online activities over time and across different websites you visit. This information may be
                            used by third parties to provide advertising tailored to your interests on other websites,
                            apps and services you visit.
                        </p>
                        <p>
                            You can review your Internet browser settings, typically under the sections “Help” or
                            “Internet Options,” to exercise choices you have for certain Cookies, or you can opt out of
                            the collection and use of some Cookies through tools like the Network Advertising Initiative
                            opt-out page. If you disable or delete certain Cookies in your settings, you may not be able
                            to use features of our websites.
                        </p>
                        <p>
                            We may use third party analytics such as Google Analytics or similar analytics services. For
                            information on how Google processes and collects information using Google Analytics, please
                            see <a target='_blank' rel='noopener noreferrer' href={'https://www.google.com/policies/privacy/partners/'}>www.google.com/policies/privacy/partners/</a>, and for how to opt out,
                            please see <a target='_blank' rel='noopener noreferrer' href={'https://tools.google.com/dlpage/gaoptout'}>https://tools.google.com/dlpage/gaoptout </a>.
                        </p>
                        <p>The opt-outs described above may not work for all browsers or devices.</p>
                        <p>
                            If you have any questions regarding our use of cookies and other similar technologies,
                            please
                            contact us as set forth in the “Contact Us” section below.
                        </p>
                        <h2>E. Rights You May Have</h2>
                        <p>
                            To the extent required by Relevant Law, upon your reasonable and good faith request, we will
                            inform you whether we hold any of your Personal Data as part of our In-scope Processing.
                            With respect to your Personal Data collected and used in our In-scope Processing, under the
                            Relevant Law you may also be able to:
                            <ul>
                                <li>
                                    obtain a copy of your Personal Data in an easily accessible format;
                                </li>
                                <li>
                                    request that we correct or update any of your Personal Data that is inaccurate;
                                </li>
                                <li>
                                    restrict or limit the ways in which we use your Personal Data;
                                </li>
                                <li>
                                    object to the processing of your Personal Data;
                                </li>
                                <li>
                                    request the deletion of your Personal Data; and
                                </li>
                                <li>
                                    request that we transmit your Personal Data to another party.
                                </li>
                            </ul>
                        </p>
                        <p>
                            To submit a request, please send an email message to EEADisclosuresInquiry@harvard.edu.
                            Because we want to avoid taking action regarding your Personal Data at the direction of
                            someone other than you, we may need to ask you for information verifying your identity. We
                            will respond to your request within a reasonable timeframe.
                        </p>
                        <p>
                            If our In-scope Processing as to your Personal Data is solely based on your consent, in
                            certain cases you may also have the right under a Relevant Law to withdraw your consent to
                            our processing. If you withdraw your consent to the use or sharing of your Personal Data for
                            the purposes set out in these Disclosures, or otherwise limit our use of your Personal Data
                            or request its deletion, we may no longer be able to provide you some or all of the related
                            services.
                        </p>
                        <p>
                            Please note that, in certain cases, we may continue to process your Personal Data after you
                            have withdrawn consent or requested that we delete your Personal Data, if we have a legal
                            basis to do so. For example, we may need to retain certain data to comply with an
                            independent legal obligation, for achieving the lawful purposes for which we obtained the
                            data, or for such reasons as keeping our services and operations safe and secure or
                            safeguarding our rights or the rights or safety of others.
                        </p>
                        <p>
                            If you have any complaints regarding our privacy practices, you may be able to make a
                            complaint to your national data protection authority, supervisory authority, or other legal
                            authority.
                        </p>
                        <h2>F. User Generated Content</h2>
                        <p>
                            Text, images, simulations, video, audio, audiovisual, graphical, interactive, and other
                            kinds of content and files, along with information about the content that will aid its use
                            and discovery on the Site (collectively, “<strong>Resources</strong>”), are available from various sources,
                            including Users, for use in the Services. The Services also include comments, messages
                            between users and within groups, responses to assessments, notes, likes, feedback, and other
                            kinds of content posted or provided by Users in connection with the Services (collectively,
                            “<strong>Comments</strong>” and, together with Resources created or provided by Users, “<strong>User Content</strong>”).
                        </p>
                        <p>
                            Please remember that any Personal Data you submit or post as user-generated content in these
                            circumstances could in some cases be seen by others or become public. You should exercise
                            caution when deciding to disclose your personal, financial, or other information in such
                            submissions or posts. We cannot prevent others from using that information in a manner that
                            may violate these Disclosures, the law, or your personal privacy and safety. We are not
                            responsible for the results of such postings.
                        </p>
                        <p>
                            For more information on User Generated Content, specifically License Grants,
                            Representations, and Warranties, please see our {' '}
                            <NavLink to={ROUTES.General.TOS}>Terms of Service.</NavLink>
                        </p>
                        <h2>G. Updates to the Disclosures</h2>
                        <p>
                            We may update these Disclosures from time to time without prior notice by posting revised
                            Disclosures. You can determine when these Disclosures were last revised by checking the Last
                            Updated date at the beginning of these Disclosures.
                        </p>
                        <h2>H. Representatives</h2>
                        <p>
                            <h5 style={{textDecoration: 'underline'}}>Representatives in the European Union:</h5>
                            <ul>
                                <li>
                                    Name: Harvard Global Research Support Centre Ireland DAC
                                </li>
                                <li>
                                    Email: GDPRrepresentative@harvard.edu
                                </li>
                                <li>
                                    Address: Harvard Global Research Support Centre Ireland DAC <br/>
                                    Attention: GDPR Representative <br />10 Earlsfort Terrace <br/>
                                    Dublin 2 <br/>
                                    D02 T380 <br/>
                                    Ireland <br/>
                                </li>
                            </ul>
                            <h5 style={{textDecoration: 'underline'}}>Representative in the UK:</h5>
                            <ul>
                                <li>
                                    Name: Harvard Global UK
                                </li>
                                <li>
                                    Email: GDPRrepresentative@harvard.edu
                                </li>
                                <li>
                                    Address: Harvard Global UK <br/>
                                    Attention: GDPR Representative <br/>
                                    71 Queen Victoria Street <br/>
                                    London, United Kingdom EC4V 4BE <br/>
                                </li>
                            </ul>
                        </p>
                        <h2>I. Contact Us </h2>
                        <p>
                            If you have any questions, comments, requests or concerns about these Disclosures or other
                            privacy-related matters, you may contact us in the following ways:
                            <ul>
                                <li>
                                    Email: EEADisclosuresInquiry@harvard.edu
                                </li>
                                <li>
                                    Address: Harvard University <br/>
                                    Attention: International Privacy Disclosures Coordinator<br/> Suite 370 <br/>
                                    1033 Massachusetts Avenue <br/>
                                    Cambridge, MA 02138 USA <br/>
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </StandardPageLayout>
        );
    }
}

export const NonUSPrivacyDisclosurePage = connect<{}, RootState>(
    (state: RootState) => ({}),
)(NonUSPrivacyDisclosure);
