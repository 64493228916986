// tslint:disable
/**
 * LXBackend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountDeletionRequest,
    AccountDeletionRequestFromJSON,
    AccountDeletionRequestToJSON,
    AccountDetails,
    AccountDetailsFromJSON,
    AccountDetailsToJSON,
    EmbargoStatus,
    EmbargoStatusFromJSON,
    EmbargoStatusToJSON,
    LoginStatus,
    LoginStatusFromJSON,
    LoginStatusToJSON,
    Profile,
    ProfileFromJSON,
    ProfileToJSON,
    ProfileLanguage,
    ProfileLanguageFromJSON,
    ProfileLanguageToJSON,
} from '../models';

export interface AccountDeletionCancelDeletionRequest {
    id: string;
    data: AccountDeletionRequest;
}

export interface AccountDeletionConfirmDeletionRequest {
    id: string;
    data: AccountDeletionRequest;
}

export interface AccountDeletionCreateRequest {
    data: AccountDeletionRequest;
}

export interface ChangeLanguageRequest {
    data: ProfileLanguage;
}

export interface EmailExistsRequest {
    email?: string;
}

export interface ImageFromEmailRequest {
    email?: string;
}

export interface ProfilesImageCreateRequest {
    id: string;
    image?: Blob;
}

export interface ProfilesImageDeleteRequest {
    id: string;
}

export interface ProfilesPartialUpdateRequest {
    id: string;
    data: any;
}

export interface ProfilesReadRequest {
    id: string;
}

export interface ResetPasswordRequest {
    email?: string;
}

/**
 * no description
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * API endpoint to accept cookie agreement
     */
    async acceptCookieAgreementRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/details/accept_cookie_agreement/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * API endpoint to accept cookie agreement
     */
    async acceptCookieAgreement(): Promise<any> {
        const response = await this.acceptCookieAgreementRaw();
        return await response.value();
    }

    /**
     * Cancels the user account deletion request.
     */
    async accountDeletionCancelDeletionRaw(requestParameters: AccountDeletionCancelDeletionRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountDeletionCancelDeletion.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling accountDeletionCancelDeletion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/account-deletion/{id}/cancel_deletion`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDeletionRequestToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Cancels the user account deletion request.
     */
    async accountDeletionCancelDeletion(requestParameters: AccountDeletionCancelDeletionRequest): Promise<any> {
        const response = await this.accountDeletionCancelDeletionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Confirms the user account deletion request.
     */
    async accountDeletionConfirmDeletionRaw(requestParameters: AccountDeletionConfirmDeletionRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountDeletionConfirmDeletion.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling accountDeletionConfirmDeletion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/account-deletion/{id}/confirm_deletion`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDeletionRequestToJSON(requestParameters.data),
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Confirms the user account deletion request.
     */
    async accountDeletionConfirmDeletion(requestParameters: AccountDeletionConfirmDeletionRequest): Promise<any> {
        const response = await this.accountDeletionConfirmDeletionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Viewset that handles account deletion requests.
     */
    async accountDeletionCreateRaw(requestParameters: AccountDeletionCreateRequest): Promise<runtime.ApiResponse<AccountDeletionRequest>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling accountDeletionCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/account-deletion`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountDeletionRequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDeletionRequestFromJSON(jsonValue));
    }

    /**
     * Viewset that handles account deletion requests.
     */
    async accountDeletionCreate(requestParameters: AccountDeletionCreateRequest): Promise<AccountDeletionRequest> {
        const response = await this.accountDeletionCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the currently authenticated user's account details, or raises PermissionDenied.
     */
    async accountDetailRaw(): Promise<runtime.ApiResponse<AccountDetails>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/details/account/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDetailsFromJSON(jsonValue));
    }

    /**
     * Returns the currently authenticated user's account details, or raises PermissionDenied.
     */
    async accountDetail(): Promise<AccountDetails> {
        const response = await this.accountDetailRaw();
        return await response.value();
    }

    /**
     * Change language in authenticated user profile. Update user session with new language.
     */
    async changeLanguageRaw(requestParameters: ChangeLanguageRequest): Promise<runtime.ApiResponse<ProfileLanguage>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling changeLanguage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/details/change_language/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileLanguageToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileLanguageFromJSON(jsonValue));
    }

    /**
     * Change language in authenticated user profile. Update user session with new language.
     */
    async changeLanguage(requestParameters: ChangeLanguageRequest): Promise<ProfileLanguage> {
        const response = await this.changeLanguageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sends a confirmation email to the user's email address after deletion, and logs out.
     * Deletes all the data for the currently-logged-in user.
     */
    async deleteAccountRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/delete_account/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Sends a confirmation email to the user's email address after deletion, and logs out.
     * Deletes all the data for the currently-logged-in user.
     */
    async deleteAccount(): Promise<any> {
        const response = await this.deleteAccountRaw();
        return await response.value();
    }

    /**
     * Verify if the email exists on the database
     */
    async emailExistsRaw(requestParameters: EmailExistsRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/email_exists/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Verify if the email exists on the database
     */
    async emailExists(requestParameters: EmailExistsRequest): Promise<any> {
        const response = await this.emailExistsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check whether or not the user is located in an embargoed country/region.
     */
    async embargoStatusRaw(): Promise<runtime.ApiResponse<EmbargoStatus>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/embargo_status/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmbargoStatusFromJSON(jsonValue));
    }

    /**
     * Check whether or not the user is located in an embargoed country/region.
     */
    async embargoStatus(): Promise<EmbargoStatus> {
        const response = await this.embargoStatusRaw();
        return await response.value();
    }

    /**
     * Gets profile image of an account using an email
     */
    async imageFromEmailRaw(requestParameters: ImageFromEmailRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/image_from_email/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Gets profile image of an account using an email
     */
    async imageFromEmail(requestParameters: ImageFromEmailRequest): Promise<any> {
        const response = await this.imageFromEmailRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async loginCreateRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/login/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     */
    async loginCreate(): Promise<any> {
        const response = await this.loginCreateRaw();
        return await response.value();
    }

    /**
     * Check whether or not the user is currently logged in. If they are, return their username and name.
     */
    async loginStatusRaw(): Promise<runtime.ApiResponse<LoginStatus>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/login_status/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginStatusFromJSON(jsonValue));
    }

    /**
     * Check whether or not the user is currently logged in. If they are, return their username and name.
     */
    async loginStatus(): Promise<LoginStatus> {
        const response = await this.loginStatusRaw();
        return await response.value();
    }

    /**
     * Send a POST to /api/v1/users/profiles/<username>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving/deleting user profile images.
     */
    async profilesImageCreateRaw(requestParameters: ProfilesImageCreateRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profilesImageCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const formData = new FormData();
        if (requestParameters.image !== undefined) {
            formData.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/users/profiles/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Send a POST to /api/v1/users/profiles/<username>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving/deleting user profile images.
     */
    async profilesImageCreate(requestParameters: ProfilesImageCreateRequest): Promise<any> {
        const response = await this.profilesImageCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send a POST to /api/v1/users/profiles/<username>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving/deleting user profile images.
     */
    async profilesImageDeleteRaw(requestParameters: ProfilesImageDeleteRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profilesImageDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/profiles/{id}/image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Send a POST to /api/v1/users/profiles/<username>/image/ with the file in the image field to add or update it. Send a DELETE to remove the image.
     * Endpoint for saving/deleting user profile images.
     */
    async profilesImageDelete(requestParameters: ProfilesImageDeleteRequest): Promise<any> {
        const response = await this.profilesImageDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a user's profile, and return the result.
     */
    async profilesPartialUpdateRaw(requestParameters: ProfilesPartialUpdateRequest): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profilesPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling profilesPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/profiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.data as any,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Update a user's profile, and return the result.
     */
    async profilesPartialUpdate(requestParameters: ProfilesPartialUpdateRequest): Promise<any> {
        const response = await this.profilesPartialUpdateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a single user profile
     */
    async profilesReadRaw(requestParameters: ProfilesReadRequest): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling profilesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/profiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     * Get a single user profile
     */
    async profilesRead(requestParameters: ProfilesReadRequest): Promise<Profile> {
        const response = await this.profilesReadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Resend a new email confirmation link to the logged user
     */
    async resendEmailConfirmationRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/resend_email_confirmation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Resend a new email confirmation link to the logged user
     */
    async resendEmailConfirmation(): Promise<any> {
        const response = await this.resendEmailConfirmationRaw();
        return await response.value();
    }

    /**
     * Sends the reset password email. We call the allauth logic inside the form.
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/reset_password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     * Sends the reset password email. We call the allauth logic inside the form.
     */
    async resetPassword(requestParameters: ResetPasswordRequest): Promise<any> {
        const response = await this.resetPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async signUpCreateRaw(): Promise<runtime.ApiResponse<any>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("LabXChange API", ["read", "write"]);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/users/sign_up/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response);
    }

    /**
     */
    async signUpCreate(): Promise<any> {
        const response = await this.signUpCreateRaw();
        return await response.value();
    }

}
