import * as React from 'react';

import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { SanitizeConfigOptions, sanitizeUnsafeHTML } from 'elements/utils/sanitization';
import { showErrorMessage, Icon } from 'ui/components';
import {
    Feedback, OptionResponse, ViewData, Hints, Submit, noMoreSubmissions, SpecificFeedback,
} from './question-block-shared';
import { XBlocksApi } from 'global/api';
import { intl } from 'i18n';
import { RichText } from 'elements';

interface Props {
    itemId: string;
    data: ViewData<OptionResponse>;
    onUpdateData: (data: ViewData<OptionResponse>) => void;
}

interface State {
    submitting: boolean;
}

export class QuestionOptionResponseBlock extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            submitting: false,
        };
    }

    private async onSubmit() {
        this.setState({ submitting: true });

        const selectedIndex = this.getSelected();

        let data: ViewData<OptionResponse>;
        try {
            const response = await XBlocksApi.submitQuestionAnswer({
                id: this.props.itemId,
                data: {
                    index: selectedIndex,
                },
            });
            // parse because GenericObjectSerializer
            data = JSON.parse(response);
        } catch (err) {
            showErrorMessage(
                <WrappedMessage message={messages.errorSubmittingAnswer} />,
                {exception: err}
            );
            this.setState({ submitting: false });
            return;
        }

        this.props.onUpdateData(data);
        this.setState({ submitting: false });
    }

    private getSelected(): number {
        return this.props.data.questionData.options.findIndex((x) => x.checked);
    }

    public render() {
        const data = this.props.data;

        return (
            <div className='question-content'>
                {this.renderQuestion()}
                <Feedback max={data.maxAttempts} used={data.studentAttempts} correct={data.correct} />
                <Hints hints={data.hints} />
                <Submit
                    hidden={noMoreSubmissions(data)}
                    disabled={this.state.submitting || this.getSelected() < 0}
                    maxAttempts={data.maxAttempts}
                    usedAttempts={data.studentAttempts}
                    onSubmit={() => this.onSubmit()}
                />
            </div>
        );
    }

    private renderQuestion(): React.ReactNode {
        const qData = this.props.data.questionData;
        const id = `${this.props.itemId}-legend`;
        return (
            <fieldset className='question-question' aria-describedby={id}>
                <legend id={id}>
                    <RichText
                        innerHtml={qData.question}
                        sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                        renderMath={true}
                        renderCode={true}
                    />
                </legend>
                {qData.display === 'radio' ? this.renderRadioOptions() : this.renderDropdownOptions()}
            </fieldset>
        );
    }

    private onOptionSelect(i: number) {
        this.props.onUpdateData({
            ...this.props.data,
            questionData: {
                ...this.props.data.questionData,
                options: this.props.data.questionData.options.map((option, idx) => (
                    {
                        ...option,
                        checked: idx === i,
                    }
                )),
            },
        });
    }

    private renderRadioOptions(): React.ReactNode {
        const data = this.props.data;
        return (
            data.questionData.options.map((option, i) => {
                const id = `${this.props.itemId}-option-${i}`;
                const isAnswer = (data.questionData.studentAnswer.index ?? -1) === i;

                let extraClass;
                let icon;
                if (isAnswer && data.correct === true) {
                    extraClass = 'correct';
                    icon = <Icon fill='white' zoom='28px' name='check' />;
                } else if (isAnswer && data.correct === false) {
                    extraClass = 'incorrect';
                    icon = <Icon fill='white' zoom='28px' name='x' />;
                } else {
                    icon = String.fromCharCode((i % 26) + 65);
                    extraClass = 'unchecked';
                }

                return (
                    <>
                        <div className={`field ${extraClass}`}>
                            <input
                                id={id}
                                type='radio'
                                value={i}
                                name={id}
                                checked={option.checked}
                                onChange={() => this.onOptionSelect(i)}
                                disabled={noMoreSubmissions(data)}
                            />
                            <label htmlFor={id}>
                                <span className='i'>{icon}</span>
                                <span>
                                    <RichText
                                        innerHtml={option.content}
                                        sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
                                        renderMath={true}
                                        renderCode={true}
                                    />
                                </span>
                            </label>
                        </div>
                        <SpecificFeedback correct={data.correct} comment={option.comment} />
                    </>
                );
            })
        );
    }

    private renderDropdownOptions(): React.ReactNode {
        const data = this.props.data;

        const ansIndex = data.questionData.studentAnswer.index ?? -1;
        let feedback = null;
        if (ansIndex > -1 && data.questionData.options[ansIndex].comment) {
            const comment = data.questionData.options[ansIndex].comment;
            if (data.correct === true) {
                feedback = (
                    <div className='feedback'>
                        <WrappedMessage message={messages.correctFeedback} values={{ comment }} />
                    </div>
                );
            }
            else if (data.correct === false) {
                feedback = (
                    <div className='feedback'>
                        <WrappedMessage message={messages.incorrectFeedback} values={{ comment }} />
                    </div>
                );
            }
        }

        let extraClass = '';
        let icon = null;
        if (data.correct === true && this.getSelected() === ansIndex) {
            extraClass = 'correct';
            icon = <Icon fill='white' zoom='24px' name='check' />;
        }
        else if (data.correct === false && this.getSelected() === ansIndex) {
            extraClass = 'incorrect';
            icon = <Icon fill='white' zoom='24px' name='x' />;
        }


        return (
            <>
                <div className='option-input-group'>
                    <select
                        className={`form-control lx-select ${extraClass}`}
                        value={this.getSelected()}
                        onBlur={(e) => this.onOptionSelect(parseInt(e.currentTarget.value, 10))}
                        onChange={(e) => this.onOptionSelect(parseInt(e.currentTarget.value, 10))}
                        disabled={noMoreSubmissions(data)}
                    >
                        <option value={-1}>
                            {intl.formatMessage(messages.optionResponseQuestionPlaceholder)}
                        </option>
                        {this.props.data.questionData.options.map((option, i) => {
                            const safeText = sanitizeUnsafeHTML(option.content, SanitizeConfigOptions.StripHTML);
                            return (
                                <option value={i}>{safeText}</option>
                            );
                        })}
                    </select>
                    <div className={`input-icon ${extraClass}`}>{icon}</div>
                </div>
                {feedback}
            </>
        );
    }
}
