import * as React from 'react';

import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { WrappedMessage } from 'utils';
import messages from '../../displayMessages';
import { Card, EmbeddedBlock } from 'library/components';
import {
    CaseStudyEditorState,
    CaseStudyAsset,
    CaseStudySection,
    TeachingGuideEditorState,
} from 'library/components/BlockEditor/models';
import { detailUrlForEntity } from 'library/utils';
import { ItemResponse } from 'labxchange-client';
import { RichText } from 'elements/components/RichText/RichText';

interface Props {
    editorState: CaseStudyEditorState|TeachingGuideEditorState;
}

export class CaseStudyLikeContentView extends React.PureComponent<Props, {}> {

    public render() {
        const data = this.props.editorState;

        let attachments: ItemResponse[] = [];
        for (const section of data.sections) {
            for (const child of section.children) {
                if (child.type === 'block') {
                    attachments.push(child.item);
                }
            }
        }
        attachments = attachments.concat(data.attachments);

        return (
            <>
                <div className='lx-block case-study-block' dir='auto'>
                    {this.props.editorState.sections.map(
                        (section, index) => <Section key={`section-${index}`} section={section} />
                    )}
                </div>
                <Attachments items={attachments} />
            </>
        );
    }
}


interface AttachmentsProps {
    items: ItemResponse[];
}

const Attachments: React.FC<AttachmentsProps> = ({ items }) => {
    if (items.length === 0) {
        return null;
    }

    return (
        <div className='case-study-attachments'>
            <h2><WrappedMessage message={messages.caseStudyAttachmentsSectionTitle} /></h2>
            <div className='case-study-content-cards'>
                {items.map((x, index) => (
                    <Card
                        key={index}
                        mobileViewMode={true}
                        metadata={x.metadata}
                        showMenuButton={false}
                        hideFavoriteButton={true}
                        userAttributes={x.userAttributes}
                        openDetailUrlInNewTab={true}
                        detailUrl={detailUrlForEntity(x.metadata)}
                    />
                ))}
            </div>
        </div>
    );
};

interface ChildProps {
    child: CaseStudyAsset;
}

const Child: React.FC<ChildProps> = ({ child }) => {
    if (child.type === 'inline') {
        return <RichText
            innerHtml={child.inlinehtml}
            sanitizeConfig={SanitizeConfigOptions.UnsafeHTMLAllowedStyles}
            renderMath={true}
            renderCode={true}
        />;
    } else if (child.embed) {
        return (
            <EmbeddedBlock
                itemId={child.item.metadata.id}
            />
        );
    } else {
        return (
            <Card
                metadata={child.item.metadata}
                showMenuButton={false}
                userAttributes={child.item.userAttributes}
                detailUrl={detailUrlForEntity(child.item.metadata)}
                openDetailUrlInNewTab={true}
            />
        );
    }
};


interface SectionProps {
    section: CaseStudySection;
}

const Section: React.FC<SectionProps> = ({ section }) => (
    <div className='section'>
        <h2 className='section-title' dir='auto'>{section.title}</h2>
        {section.children.map((child, index) => <Child key={`child-${index}`} child={child} />)}
    </div>
);
