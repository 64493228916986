import * as React from 'react';
import bind from 'bind-decorator';
import { LocationDescriptor } from 'history';
import { ItemType } from 'items/models';
import { getYoutubeParams, getYouTubeIdFromUrl } from 'items/youTubeApi';
import { getVimeoParams, getVimeoIdFromUrl } from 'items/vimeoApi';
import { Author } from 'labxchange-client';
import { ROUTES } from 'global/constants';
import { intl } from 'i18n';
import { Button } from 'ui/components';
import uiMessages from 'ui/components/displayMessages';
import { showErrorMessage } from 'ui/components/GlobalMessageReporter/dispatch';
import { WrappedMessage } from 'utils';

import messages from '../../displayMessages';

interface VideoImportModalProps {
    onCloseAndRedirect: (location: LocationDescriptor) => void;
    itemType: ItemType;
    author?: Author;
    onlyAcceptYoutube?: boolean;
}

interface VideoImportModalState {
    url: string;
    requestInProgress: boolean;
    urlFieldInvalid: boolean;
}

export class VideoImportModal extends React.PureComponent<VideoImportModalProps, VideoImportModalState> {

    constructor(props: VideoImportModalProps) {
        super(props);
        this.state = {
            url: '',
            requestInProgress: false,
            urlFieldInvalid: false,
        };
    }

    public render() {
        const guidelinesLink = (
            <a href='https://labxchange.zendesk.com/hc/en-us/articles/360047136313'
              target='_blank' rel='noopener noreferrer'>
                <WrappedMessage message={messages.videoGuidelinesLinkLabel} />
            </a>
        );
        const instructionsMessage = this.props.onlyAcceptYoutube
            ? messages.videoImportOnlyYoutubeInstructions
            : messages.videoImportInstructions;

        return (
            <div className='video-import-modal'>
                <div className='inner-wrapper'>
                    <div>
                        <img src='/assets/images/youtube-logo-light.svg' alt='YouTube Logo'/>
                        <img src='/assets/images/vimeo-logo.svg' alt='Vimeo Logo'/>
                    </div>
                    <p>
                        <WrappedMessage message={instructionsMessage} values={{guidelinesLink}} />
                    </p>
                    <label htmlFor='video-url-field'>
                        <WrappedMessage message={messages.videoImportFieldLabel} />
                    </label>
                    <div className='input-with-button'>
                        <input
                            id='video-url-field'
                            className={`${this.state.urlFieldInvalid ? 'lx-input-error': ''}`}
                            placeholder={intl.formatMessage(messages.videoImportFieldPlaceholder)}
                            onChange={this.onChange}
                        />
                        <Button btnStyle='primary'
                            label={messages.videoImportButtonLabel}
                            onClick={this.onClick}
                            disabled={this.isButtonDisabled()}
                        />
                    </div>
                    <div className='spinner' data-active={this.state.requestInProgress}/>
                </div>
            </div>
        );
    }

    @bind private onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const url = event.target.value;
        this.setState({url, urlFieldInvalid: false});
    }

    @bind private async onClick() {
        this.setState({urlFieldInvalid: false, requestInProgress: true});

        const youTubeVideoId = getYouTubeIdFromUrl(this.state.url);
        if (youTubeVideoId) {
            // If this is a YouTube URL, we can get more details from the YouTube API.
            try {
                const params = await getYoutubeParams(youTubeVideoId, this.props.author);

                this.props.onCloseAndRedirect({
                    pathname: ROUTES.Library.ITEM_NEW_SLUG(this.props.itemType, ''),
                    state: params,
                });
            } catch (err) {
                showErrorMessage(<WrappedMessage message={messages.youTubeVideoImportFailureMessage} />, {
                    exception: err,
                    confirmText: uiMessages.uiOk,
                });
                this.setState({requestInProgress: false});
            }
        }
        else {
            /// Verifies if only accepts youtube videos
            if (this.props.onlyAcceptYoutube) {
                this.setState({urlFieldInvalid: true, requestInProgress: false});
                return;
            }

            const vimeoVideoId = getVimeoIdFromUrl(this.state.url);
            if (vimeoVideoId) {
                // If this is a Vimeo URL, we can get more details from the Vimeo API.
                const params = await getVimeoParams(this.state.url, vimeoVideoId, this.props.author);
                this.props.onCloseAndRedirect({
                    pathname: ROUTES.Library.ITEM_NEW_SLUG(this.props.itemType, ''),
                    state: params,
                });
            }
            else {
                // If this is not a YouTube or Vimeo URL, we assume it's a link to a HLS video.
                this.props.onCloseAndRedirect({
                    pathname: ROUTES.Library.ITEM_NEW_SLUG(this.props.itemType, ''),
                    state: {
                        blockEditorDefaults: {
                            html5Sources: [this.state.url],
                        },
                    },
                });
            }
        }
    }

    private isButtonDisabled() {
        return this.state.requestInProgress || !this.state.url;
    }

}
