import * as React from 'react';
import { Banner } from 'labxchange-client';
import { Icon } from 'ui/components';
import { RichText } from 'elements/components/RichText';
import { SanitizeConfigOptions } from 'elements/utils/sanitization';
import { PolicyAndTermsUpdatesBannerId } from '../../actions';

interface BannerItemProps {
    banner: Readonly<Banner>;
    dismiss: (banner: Banner) => void;
    className?: string;
}

export class BannerItem extends React.Component<BannerItemProps> {
    public render() {
        const {banner, className} = this.props;

        return (
            <div className={`banner-item ${className}`}>
                <div className='banner-content'>
                    <h4>
                        <RichText
                            innerHtml={banner.content}
                            sanitizeConfig={SanitizeConfigOptions.UnsafeBannerHTML}
                        />
                    </h4>
                </div>
                {!banner.disableDismiss &&
                  <button
                      className='unstyled'
                      onClick={() => this.dismiss(banner)}
                  >
                      <span className='close-icon'>
                          <Icon name='x' zoom='14' />
                      </span>
                  </button>
                }
                {banner.id === PolicyAndTermsUpdatesBannerId &&
                  <button
                    className='accept-terms'
                    onClick={() => this.dismiss(banner)}
                  >
                    Accept
                  </button>
                }
            </div>
        );
    }

    private dismiss = (banner?: Banner) => {
        if (banner?.id !== undefined) {
            this.props.dismiss(banner);
        }
    }
}
